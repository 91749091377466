import React, { useEffect, useState } from 'react'
import Loading from 'components/Loading/Loading'
import LoadingBars from 'components/Loading/LoadingBars';
import Button from "components/CustomButtons/Button";

import { makeStyles } from "@material-ui/core/styles";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { NavigateNext, ExpandMore, CheckCircle, Error, Cancel } from '@material-ui/icons';
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  reservationsBetweenSelector,
  resourcesForCreatingEventSelector,
  roomsForCampusSelector,
  getReservationsBetween,
  getResourcesForCreatingEvent,
  getRoomsForCampus,
  militaryTimeTo12HTime,
  standardDateFormatToUserPreferedLayout
} from 'state/events';
import { TextField } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import update from 'immutability-helper'
import RoomReservationSelection from 'components/RoomReservationSelection/RoomReservationSelection';
import ResourceReservationSelection from 'components/ResourceReservationSelection/ResourceReservationSelection';

const useStyles = makeStyles(() => ({
  ...styles,
  ...dashboardStyles,
  formSection: {
    marginBottom: "1rem",
  },
}));

const EventEntryValidationComp = ({ eventEntry, updateEventEntry, buildings, campusID, bigEvent, uneditedEventEntry, eventEntryError, updateEventEntryError, eventRanges, setAcknowledgeWarnings, updateEventEntryLoading, eventID }) => {

  const classes = useStyles()

  const formatDateString = (event = eventEntry) => {
    const startDate = standardDateFormatToUserPreferedLayout(event.startDate)
    const endDate = standardDateFormatToUserPreferedLayout(event.endDate)
    const startTime = militaryTimeTo12HTime(event.startTime)
    const endTime = militaryTimeTo12HTime(event.endTime)
    if (startDate === endDate) {
      return `${startDate} ${startTime} - ${endTime}`
    }
    return `${startDate} ${startTime} - ${endDate} ${endTime}`
  }

  const [eventEntryLocal, setEventEntryLocal] = useState(eventEntry)

  const [toggleDropdown, setToggleDropdown] = useState(false)
  const emptyErrors = {
    startDate: { hasError: false, message: '' },
    endDate: { hasError: false, message: '' },
    startTime: { hasError: false, message: '' },
    endTime: { hasError: false, message: '' },
  }
  const [errors, setErrors] = useState(emptyErrors)

  const [entryEdited, setEntryEdited] = useState(false)

  const rooms = useRecoilValue(roomsForCampusSelector({
    campusID: campusID,
    start: eventEntry.start,
    end: eventEntry.end
  }))

  const reservations = useRecoilValue(reservationsBetweenSelector({
    campusID: campusID,
    start: eventEntry.start,
    end: eventEntry.end,
    excludeEventID: eventID
  }))

  const resources = useRecoilValue(resourcesForCreatingEventSelector({
    campusID: campusID,
    start: eventEntry.start,
    end: eventEntry.end,
    excludeEventID: eventID
  }))

  const roomsFormated = rooms.map(r => {

    let room = {
      ...r,
      occupancy: parseInt(r.occupancy)
    }

    let number = r.number.split('-')
    room.number = number[number.length - 1]

    let existingReservationsForRoom = reservations.filter(res => res.forID === room.id && res.forType === 'room')
    room.reservationConflicts = existingReservationsForRoom.filter(i => !i.bigEvent).length
    room.bigEventConflict = existingReservationsForRoom.find(i => i.bigEvent) ? true : false


    if (!r.openHours) {
      let building = buildings.find(b => b.id === r.buildingID)
      if (building) {
        if (building.openHours) {
          room.openHours = building.openHours
        } else {
          let campus = campuses.find(c => c.id === r.campusID)
          if (campus) {
            room.openHours = campus.openHours
          }
        }
      }
    }

    let roomSearch = `${r.name} room ${r.number} occupancy ${r.occupancy} ${r.tags.join(', ')}`
    room.search = roomSearch.toLowerCase()

    return room
  })


  const formatedResources = resources.map(r => {
    let resource = {
      ...r,
      available: r.quantity ? r.quantity - r.reservedGeneral - r.reservedBigEvent : 0,
      total: r.quantity ? bigEvent ? r.quantity : r.quantity - r.reservedBigEvent : 0
    }

    let rSearch = `${r.name} ${r.location} ${r.tags.join(', ')}`
    resource.search = rSearch.toLowerCase()

    return resource
  })

  const setAnySubmissionErrors = (entry = eventEntry) => {
    let error = {
      warning: false,
      error: false,
      messages: []
    }
    setAcknowledgeWarnings(false)
    updateEventEntryLoading(false)
    if (entry.status !== 'cancel') {
      if (entryEdited) {
        error.warning = true
        error.messages.push(`Warning: Unsaved changes.`)
      }
      entry.reservations.forEach(res => {
        if (res.forType === 'room') {
          let room = roomsFormated.find(r => r.id === res.forID)
          if (!bigEvent) {
            if (room.bigEventConflict) {
              error.error = true
              error.messages.push(`Error: ${room.name} is being used by a special event and cannot be used.`)
            }
            if (room.reservationConflicts) {
              error.warning = true
              error.messages.push(`Warning: ${room.name} is being used by another event.`)
            }
            if (room.openHourConflict) {
              error.warning = true
              error.messages.push(`Warning: ${room.name} is being used outside of its open hours and will require approval before it appears on the calendar.`)
            }
          } else {
            if (room.bigEventConflict) {
              error.warning = true
              error.messages.push(`Warning: ${room.name} is being used by a special event.`)
            }
            if (room.reservationConflicts) {
              error.warning = true
              error.messages.push(`Warning: ${room.name} is being used by another event.`)
            }
            if (room.openHourConflict) {
              error.warning = true
              error.messages.push(`Warning: ${room.name} is being used outside of its open hours.`)
            }
          }
        } else {
          let resource = formatedResources.find(r => r.id === res.forID)
          if (!bigEvent) {
            if (resource.type === 'general') {
              if (res.quantity > resource.available) {
                error.warning = true
                error.messages.push(`Warning: More ${res.name}s are being requested than available.`)
              }
            }
          }
        }
      })
    }
    updateEventEntryError({ $set: error })
  }

  const findIndexOfReservation = (forType, forID) => {
    return eventEntryLocal.reservations.findIndex(r => forType === r.forType && forID === r.forID)
  }

  const addRemoveRoom = (room) => {
    let reservation = {
      forType: 'room',
      forID: room.id,
      buildingID: room.buildingID
    }
    let index = findIndexOfReservation('room', room.id)
    if (index === -1) {
      handleUpdate({ reservations: { $push: [reservation] } })
    } else {
      handleUpdate({ reservations: { $splice: [[index, 1]] } })
    }
  }

  const updateResourceReservation = (updateData) => {
    handleUpdate({ reservations: updateData })
  }

  const handleUpdate = (updateData) => {
    setEntryEdited(true)
    setEventEntryLocal(prev => {
      return update(prev, updateData)
    })
  }

  const validateForm = () => {
    let displayRecurring = standardDateFormatToUserPreferedLayout(eventRanges.recurring)

    let valid = true
    setErrors(emptyErrors)
    if (!eventEntryLocal.startDate) {
      setErrors(prevState => {
        return update(prevState, {
          startDate: {
            $set: {
              hasError: true,
              message: `*Required`
            }
          }
        })
      })
      valid = false
    } else if (eventEntryLocal.startDate > eventRanges.recurring && !eventEntryLocal.bigEvent) {
      setErrors(prevState => {
        return update(prevState, {
          startDate: {
            $set: {
              hasError: true,
              message: `*Cannot exceed ${displayRecurring}`
            }
          }
        })
      })
      valid = false
    } else if (eventEntryLocal.startDate > eventEntryLocal.endDate) {
      setErrors(prevState => {
        return update(prevState, {
          startDate: {
            $set: {
              hasError: true,
              message: `*Event can't start after it ends`
            }
          }
        })
      })
      valid = false
    }

    if (!eventEntryLocal.startTime) {
      setErrors(prevState => {
        return update(prevState, {
          startTime: {
            $set: {
              hasError: true,
              message: `*Required`
            }
          }
        })
      })
      valid = false
    } else if (eventEntryLocal.startDate === eventEntryLocal.endDate && eventEntryLocal.startTime >= eventEntryLocal.endTime) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          startTime: {
            $set: {
              hasError: true,
              message: `*Event can't start before it ends`
            }
          }
        })
      })
    }

    if (!eventEntryLocal.endDate) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          endDate: {
            $set: {
              hasError: true,
              message: `*Required`
            }
          }
        })
      })
    } else if (eventEntryLocal.endDate > eventRanges.recurring && !eventEntryLocal.bigEvent) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          endDate: {
            $set: {
              hasError: true,
              message: `*Cannot exceed ${displayRecurring}`
            }
          }
        })
      })
    } else if (eventEntryLocal.startDate > eventEntryLocal.endDate) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          endDate: {
            $set: {
              hasError: true,
              message: `*Event can't end before it starts`
            }
          }
        })
      })
    }

    if (!eventEntryLocal.endTime) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          endTime: {
            $set: {
              hasError: true,
              message: `*Required`
            }
          }
        })
      })
    } else if (eventEntryLocal.startDate === eventEntryLocal.endDate && eventEntryLocal.startTime >= eventEntryLocal.endTime) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          endTime: {
            $set: {
              hasError: true,
              message: `*Event can't end before it starts`
            }
          }
        })
      })
    }
    return valid
  }

  const submitChanged = () => {
    if (eventEntryLocal.status !== 'cancel') {
      if (validateForm()) {
        let updateData = {
          ...eventEntryLocal,
          edited: false
        }
        if (eventEntryLocal.startDate !== uneditedEventEntry.startDate) {
          updateData.edited = true
          updateData.start = `${eventEntryLocal.startDate} ${eventEntryLocal.startTime}`
        }
        if (eventEntryLocal.startTime !== uneditedEventEntry.startTime) {
          updateData.edited = true
          updateData.start = `${eventEntryLocal.startDate} ${eventEntryLocal.startTime}`
        }
        if (eventEntryLocal.endDate !== uneditedEventEntry.endDate) {
          updateData.edited = true
          updateData.end = `${eventEntryLocal.endDate} ${eventEntryLocal.endTime}`
        }
        if (eventEntryLocal.endTime !== uneditedEventEntry.endTime) {
          updateData.edited = true
          updateData.end = `${eventEntryLocal.endDate} ${eventEntryLocal.endTime}`
        }
        if (eventEntryLocal.status !== 'active') {
          updateData.edited = true
        } else if (eventEntryLocal.reservations.length !== uneditedEventEntry.reservations.length) {
          updateData.edited = true
        } else {
          let findUnique = eventEntryLocal.reservations.filter(r => uneditedEventEntry.reservations.findIndex(res => r.forID === res.forID && r.forType === res.forType) === -1)
          if (findUnique.length > 0) {
            updateData.edited = true
          }
        }

        updateEventEntry({ $set: updateData })
        setEventEntryLocal(updateData)
        setEntryEdited(false)
      }
    } else {
      let updateData = {
        ...eventEntry,
        edited: true,
        status: 'cancel'
      }
      updateEventEntry({ $set: updateData })
      setEventEntryLocal(updateData)
      setEntryEdited(false)
    }
  }

  useEffect(() => {
    setAnySubmissionErrors()
  }, [rooms, resources, reservations, entryEdited])

  return (
    <div>
      <Button
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: '5px 5px 5px 10px',
          color: '#393939',
          backgroundColor: '#fff',
          border: '1px solid #3695d7',
        }}
        onClick={() => setToggleDropdown(!toggleDropdown)}
      >
        <span style={{ marginRight: '10px', marginBottom: '-3px', fontSize: '16px' }}>{formatDateString()}</span>
        <div style={{ display: 'flex', alignItems: 'center' }}>

          {eventEntry.status !== 'cancel' &&
            (<>
              {(entryEdited) && (<span style={{ margin: '2px 5px 0px 5px', padding: '0px', textTransform: 'none' }}>Unsaved Changes</span>)}
              {(eventEntry.edited && !entryEdited) && (<span style={{ margin: '2px 5px 0px 5px', padding: '0px', textTransform: 'none' }}>Edited</span>)}
              {!(eventEntryError.warning || eventEntry.error || entryEdited) && (<CheckCircle style={{ margin: '0px', height: '20px', color: '#3695d7' }} />)}
              {(eventEntryError.warning || entryEdited) && (<Error style={{ margin: '0px', height: '20px', color: '#ff9800' }} />)}
              {(eventEntryError.error) && (<Cancel style={{ margin: '0px', height: '20px', color: '#f44336' }} />)}
            </>)
          }
          {(eventEntry.status === 'cancel') && (
            <span style={{ textTransform: 'none', marginTop: '2px' }}>Canceled</span>
          )}
          {toggleDropdown ? <ExpandMore style={{ margin: '0px', height: '20px' }} /> : <NavigateNext style={{ margin: '0px', height: '20px' }} />}
        </div>
      </Button>
      <div style={{
        // minHeight: toggleDropdown ? `10px` : '0px',
        // maxHeight: toggleDropdown ? `300px` : '0px',
        // opacity: toggleDropdown ? 1 : 0,
        // transition: 'all 2000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
      }}>
        {(toggleDropdown && (
          <div
            style={{
              borderRadius: "4px",
              background: "#fff",
              padding: "5px 10px",
              border: "1px solid #c4c4c4",
              alignItems: 'center',
              flexWrap: 'wrap'
            }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
              <div style={{ margin: '5px 5px 10px 5px', flex: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '11px' }}>
                  <div style={{ marginTop: '-11px', flex: 1 }}>
                    <div style={{ margin: '-6px 0px 0px 9px' }}>
                      <span style={{
                        position: 'relative',
                        margin: '0px',
                        padding: '0px 5px',
                        color: '#757575',
                        backgroundColor: '#fff',
                        zIndex: 2,
                        fontSize: '12px'
                      }}>
                        Cancel Event *
                      </span>
                    </div>
                    <div
                      style={{
                        position: 'relative',
                        zIndex: 1,
                        display: 'flex',
                        flexWrap: 'wrap',
                        borderRadius: "4px",
                        background: "#fff",
                        padding: "1px 12px",
                        border: "1px solid #c4c4c4",
                        alignItems: 'center',
                        marginTop: '-11px',
                        flexWrap: 'wrap',
                        marginRight: '5px'
                      }}
                    >

                      <FormControlLabel
                        control={
                          <Radio
                            checked={eventEntryLocal.status === 'cancel'}
                            name="cancelEvent"
                            value="yes"
                            onChange={() => handleUpdate({ status: { $set: 'cancel' } })}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        style={{ color: '#797979' }}
                        label="Yes"
                      />

                      <FormControlLabel
                        control={
                          <Radio
                            checked={eventEntryLocal.status !== 'cancel'}
                            name="cancelEvent"
                            value="no"
                            onChange={() => handleUpdate({ status: { $set: 'active' } })}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        style={{ color: '#797979' }}
                        label="No"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ height: '0px', flex: 1, minWidth: '170px', margin: '0px 5px' }} />
            </div>
            {(eventEntryLocal.status !== 'cancel') && (
              <>
                <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                  <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                      style={{ flex: 1, margin: '10px 5px', minWidth: '170px' }}
                      type="date"
                      variant="outlined"
                      label='Start Date'
                      required
                      helperText={errors.startDate.message}
                      error={errors.startDate.hasError}
                      value={eventEntryLocal.startDate}
                      onChange={(e) => {
                        handleUpdate({
                          startDate: { $set: e.target.value },
                        })
                        if (!eventEntryLocal.endDate) {
                          handleUpdate({ endDate: { $set: e.target.value } })
                        } else if (eventEntryLocal.endDate < e.target.value) {
                          handleUpdate({ endDate: { $set: e.target.value } })
                        }
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      style={{ flex: 1, margin: '10px 5px', minWidth: '140px' }}
                      type="time"
                      variant="outlined"
                      label='Start Time'
                      required
                      helperText={errors.startTime.message}
                      error={errors.startTime.hasError}
                      value={eventEntryLocal.startTime}
                      onChange={(e) => {
                        handleUpdate({ startTime: { $set: e.target.value } })
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                  <span style={{ margin: '10px', fontSize: '16px' }}> to </span>

                  <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                      style={{ flex: 1, margin: '10px 5px', minWidth: '170px' }}
                      type="date"
                      variant="outlined"
                      label='End Date'
                      required
                      helperText={errors.endDate.message}
                      error={errors.endDate.hasError}
                      value={eventEntryLocal.endDate}
                      onChange={(e) => {
                        handleUpdate({ endDate: { $set: e.target.value } })
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      style={{ flex: 1, margin: '10px 5px', minWidth: '140px' }}
                      type="time"
                      variant="outlined"
                      label='End Time'
                      required
                      helperText={errors.endTime.message}
                      error={errors.endTime.hasError}
                      value={eventEntryLocal.endTime}
                      onChange={(e) => {
                        handleUpdate({ endTime: { $set: e.target.value } })
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
                <RoomReservationSelection
                  buildings={buildings}
                  rooms={roomsFormated}
                  reservations={eventEntryLocal.reservations}
                  addRemoveRoom={addRemoveRoom}
                  dropdownDefault={false}
                />
                <ResourceReservationSelection
                  resources={formatedResources}
                  reservations={eventEntryLocal.reservations}
                  updateResource={updateResourceReservation}
                  bigEvent={bigEvent}
                  dropdownDefault={false}
                />
              </>
            )}
            <div style={{ margin: '10px 0px 5px 0px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              {eventEntry.edited && (
                <Button
                  style={{ margin: '0px 0px 0px 5px' }}
                  color='danger'
                  title='Revert to Original Values'
                  onClick={(e) => {
                    e.preventDefault()
                    updateEventEntry({ $set: uneditedEventEntry })
                    setEventEntryLocal(uneditedEventEntry)

                    setAnySubmissionErrors(uneditedEventEntry)
                  }}
                >
                  Revert
                </Button>
              )}
              {entryEdited && (
                <>
                  <Button
                    style={{ margin: '0px 0px 0px 7px' }}
                    color='warning'
                    title='Reset Current Changes'
                    onClick={(e) => {
                      e.preventDefault()
                      setEventEntryLocal(eventEntry)
                      setEntryEdited(false)
                      setErrors(emptyErrors)
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{ margin: '0px 0px 0px 5px' }}
                    color='primary'
                    title='Update'
                    onClick={(e) => {
                      e.preventDefault()
                      submitChanged()
                    }}
                  >
                    Update
                  </Button>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      {(eventEntryError.warning || eventEntryError.error) && (
        <div style={{
          borderRadius: "4px",
          background: "#fff",
          padding: "2px 7px",
          border: "1px solid #c4c4c4",
          alignItems: 'center',
          flexWrap: 'wrap'
        }}>
          {eventEntryError.messages.map((msg, idx) => {
            let message = msg.split(': ')
            return (
              <div
                key={idx}
                style={{
                  fontSize: '12px',
                  margin: '4px 0px',
                  padding: '0px',
                  display: 'flex',
                  alignItems: 'start',
                  lineHeight: '12px'
                }}>
                <span style={{ color: message[0] === 'Error' ? '#f44336' : '#ff9800', marginRight: '3px', fontWeight: 'bold' }}>{message[0]}:</span>
                <span>{message[1]}</span>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

const EventEntryValidationLoading = ({ eventEntry, updateEventEntryLoading }) => {
  const formatDateString = () => {
    const startDate = standardDateFormatToUserPreferedLayout(eventEntry.startDate)
    const endDate = standardDateFormatToUserPreferedLayout(eventEntry.endDate)
    const startTime = militaryTimeTo12HTime(eventEntry.startTime)
    const endTime = militaryTimeTo12HTime(eventEntry.endTime)
    if (startDate === endDate) {
      return `${startDate} ${startTime} - ${endTime}`
    }
    return `${startDate} ${startTime} - ${endDate} ${endTime}`
  }
  useEffect(() => {
    updateEventEntryLoading(true)
  }, [])
  return (
    <div>
      <Button
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: '5px 5px 5px 10px',
          color: '#393939',
          backgroundColor: '#fff',
          border: '1px solid #3695d7',
        }}
      >
        <span style={{ marginRight: '10px', marginBottom: '-3px', fontSize: '16px' }}>{formatDateString()}</span>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '5px' }}>
            <LoadingBars />
          </div>
          <NavigateNext style={{ margin: '0px' }} />
        </div>
      </Button>
    </div>
  )
}

const EventEntryValidation = (props) => {
  return (
    <React.Suspense fallback={<EventEntryValidationLoading {...props} />}>
      <EventEntryValidationComp {...props} />
    </React.Suspense>
  )
}

export default EventEntryValidation