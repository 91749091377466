import Loading from 'components/Loading/Loading'
import React from 'react'
import { decryptData } from 'assets/jss/EncriptDecrypt/EncryptDecrypt'
import { useRecoilValue } from 'recoil'
import { eventToEdit, adminEventToEdit } from 'state/events'
import GoBack from "components/GoBack/GoBack"
import CreateEditEvent from './CreateEditEvent'

const EditEventComp = (props) => {

  const id = props.match.params.id
  const userType = props.match.params.userType
  const search = props.location.search?.split("?")[1] || null
  const searchData = search ? decryptData(search) : { returnLocation: '/portal/events/teamEvents' }

  const event = useRecoilValue(userType === 'admin' ? adminEventToEdit(id) : eventToEdit(id))

  const eventForEditing = searchData.duplicate ? {
    ...event,
    id: null
  } : event


  return (
    <React.Fragment>
      <div style={{ maxWidth: '1100px' }}>
        <GoBack returnLocation={searchData.returnLocation} disableRound={true} />
        {event ?
          <CreateEditEvent event={eventForEditing} />
          :
          <p>You shouldn't be here</p>}

      </div>
    </React.Fragment>
  )
}

const EditEvent = (props) => {
  return (
    <React.Suspense fallback={<Loading />}>
      <EditEventComp {...props} />
    </React.Suspense>
  )
}

export default EditEvent