import Loading from 'components/Loading/Loading'
import React from 'react'
import { decryptData, encryptData } from 'assets/jss/EncriptDecrypt/EncryptDecrypt'
import { useRecoilValue } from 'recoil'
import { viewEventEntry } from 'state/events'
import GoBack from "components/GoBack/GoBack"
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import RoomReservationDisplay from 'components/Events/RoomReservationDisplay'
import ResourceReservationDisplay from 'components/Events/ResourceReservationDisplay'
import Button from "components/CustomButtons/Button";

import {
  DateRange,
  Business,
  Error,
  Today,
  Edit,
  EventBusy,
  Visibility,
  FilterNone,
  Person,
  Event,
  NotificationsOff,
  NotificationsActive,
  NotificationImportant,
  Autorenew,
  LocalOffer,
  Info,
  FilterList,
  Close,
  Group,
  Create,
  Flag,
  Description,
  Done
} from '@material-ui/icons'
import ConflictingEventEntriesDisplay from 'components/Events/ConflictingEventEntriesDisplay'
import { adminApproveEventEntry } from 'state/events'
import { adminRejectEventEntry } from 'state/events'

const ViewEventEntryComp = (props) => {
  const id = props.match.params.id
  const userType = props.match.params.userType
  const search = props.location.search?.split("?")[1] || null
  const searchData = search ? decryptData(search) : {}

  const eventEntry = useRecoilValue(viewEventEntry(id))

  const styles = {
    topbarButtons: { height: '25px', width: '25px' },
    infoItem: { display: 'flex', alignItems: 'center', marginBottom: '5px', flexWrap: 'wrap', fontWeight: 400 },
    iconStyle: { margin: '0px', height: '20px', color: '#aaa' },
    eventItems: { display: 'flex', cursor: 'pointer', flex: 1, minWidth: '200px', fontWeight: 400 }
  }
  return (
    <React.Fragment>
      <div style={{ maxWidth: '1000px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>

          <GoBack returnLocation={searchData.returnLocation} disableRound={true} />
          {(eventEntry) && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
              {(userType === 'admin' && eventEntry.status === 'pending') && (
                <>
                  <Button
                    style={{ margin: '0px', height: '42px' }}
                    color="success"
                    title='Approve'
                    justIcon
                    onClick={async () => {
                      if (confirm(`Approve Event Entry?`)) {
                        await adminApproveEventEntry({ id: eventEntry.eventEntryID })
                        if (searchData.returnLocation) {
                          location.href = searchData.returnLocation
                        } else {
                          location.reload()
                        }
                      }
                    }}
                  >
                    <Done />
                  </Button>
                  <Button
                    style={{ margin: '0px', height: '42px' }}
                    color="danger"
                    title='Reject'
                    justIcon
                    onClick={async () => {
                      if (confirm(`Reject Event Entry?`)) {
                        await adminRejectEventEntry({ id: eventEntry.eventEntryID })
                        if (searchData.returnLocation) {
                          location.href = searchData.returnLocation
                        } else {
                          location.reload()
                        }
                      }
                    }}
                  >
                    <Close />
                  </Button>
                </>
              )}
              {(userType === 'admin' || userType === 'user') && (
                <>
                  {(eventEntry.conflictReservations.length > 0 && eventEntry.status === 'active') && (
                    <Button
                      style={{ margin: '0px', height: '42px' }}
                      justIcon
                      color="warning"
                      title='Resolve Conflicts'
                      onClick={() => {
                        let search = encryptData({
                          returnLocation: window.location.href,
                        })
                        window.location.href = `/portal/events/user/handleConflicts/${eventEntry.eventID}?${search}`
                      }}
                    >
                      <Error />
                    </Button>
                  )}
                  <Button
                    style={{ margin: '0px', height: '42px' }}
                    color="primary"
                    title='Edit'
                    justIcon
                    onClick={() => {
                      window.location.href = `/portal/events/${userType}/edit/${eventEntry.eventID}?${search}`
                    }}
                  >
                    <Edit />
                  </Button>
                </>
              )}
            </div>
          )}

        </div>
        {eventEntry ?
          <>
            <Card style={{ margin: '10px 0px 0px' }}>
              <CardBody>
                <div
                  style={{
                    width: '100%',
                  }}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p style={{ margin: '0px 0px 10px 4px', fontWeight: 'bold', fontSize: '20px' }}>
                        {eventEntry.name}
                      </p>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <div
                        style={styles.eventItems}
                        title='Created By'
                      >
                        <Person style={styles.iconStyle} />
                        <span>{eventEntry.createdByName}</span>
                      </div>

                      <div
                        style={styles.eventItems}
                        title='Created'
                      >
                        <Create style={styles.iconStyle} />
                        <span>{eventEntry.createdReadable}</span>
                      </div>

                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <div
                        style={styles.eventItems}
                        title='Department'
                      >
                        <Group style={styles.iconStyle} />
                        <span>{eventEntry.createdByDepartmentName}</span>
                      </div>

                      <div
                        style={styles.eventItems}
                        title='Campus'
                      >
                        <Business style={styles.iconStyle} />
                        <span>{eventEntry.campus.name}</span>
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >

                      <div
                        style={styles.eventItems}
                        title='Event Type'
                      >
                        <Info style={styles.iconStyle} />
                        <span>{eventEntry.bigEvent ? 'Special Event' : 'Regular Event'}</span>
                      </div>

                      <div
                        style={styles.eventItems}
                        title='Tag'
                      >
                        <LocalOffer style={styles.iconStyle} />
                        <span>{eventEntry.tag}</span>
                      </div>

                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <div
                        style={styles.eventItems}
                        title='When'

                      >
                        <Event style={styles.iconStyle} />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <span>{eventEntry.when}</span>
                          <span>
                            Repeats: {eventEntry.recurringInfo}
                          </span>
                        </div>
                      </div>
                      <div
                        style={styles.eventItems}
                        title='Status'
                      >
                        <Flag style={styles.iconStyle} />
                        <span>{eventEntry.status}</span>
                      </div>
                    </div>

                    {(eventEntry.description) && (
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                        }}
                      >
                        <div
                          style={styles.eventItems}
                          title='Description'
                        >
                          <Description style={styles.iconStyle} />
                          <span>{eventEntry.description}</span>

                        </div>
                      </div>
                    )}

                  </div>

                </div>
              </CardBody>
            </Card>

            {(eventEntry.conflictReservations.length > 0 && userType === 'admin' && eventEntry.bigEvent) && (
              <div style={{ display: 'flex', marginTop: '10px', fontWeight: 400, }}>
                <Error
                  style={{ margin: '0px', height: '20px', color: '#ff9800', cursor: 'pointer' }}
                />
                <span>Special events will have all conflicts auto resolved in it's favor upon approval.</span>
              </div>
            )}

            {(eventEntry.rooms.length > 0) && (
              <div style={{ margin: '10px 0px 0px' }}>
                <RoomReservationDisplay
                  buildingsRaw={eventEntry.buildings}
                  roomsRaw={eventEntry.rooms}
                  reservationsRaw={eventEntry.conflictReservations}
                  dropdownDefault={true}
                  reservationsEventEntryPreviews={eventEntry.conflictReservationEventEntries}
                  bigEvent={eventEntry.bigEvent}
                />
              </div>
            )}
            {(eventEntry.resources.length > 0) && (
              <div style={{ margin: '10px 0px 0px' }}>
                <ResourceReservationDisplay
                  resourcesRaw={eventEntry.resources}
                  myReservationsRaw={eventEntry.reservations}
                  reservationsConflictsRaw={eventEntry.conflictReservations}
                  dropdownDefault={true}
                  reservationsEventEntryPreviews={eventEntry.conflictReservationEventEntries}
                  bigEvent={eventEntry.bigEvent}

                />
              </div>
            )}

            {(eventEntry.conflictReservationEventEntries.length > 0) && (
              <div style={{ margin: '10px 0px 0px' }}>
                <ConflictingEventEntriesDisplay
                  eventEntries={eventEntry.conflictReservationEventEntries}
                  conflictsRaw={eventEntry.conflictReservations}
                  dropdownDefault={true}
                  userType={userType}
                />
              </div>
            )}
          </>
          :
          <p>You shouldn't be here</p>}

      </div>
    </React.Fragment>
  )
}

const ViewEventEntry = (props) => {
  return (
    <React.Suspense fallback={<Loading />}>
      <ViewEventEntryComp {...props} />
    </React.Suspense>
  )
}

export default ViewEventEntry