import React, { useState } from 'react'
import Loading from 'components/Loading/Loading'
import LoadingBars from 'components/Loading/LoadingBars';
import Button from "components/CustomButtons/Button";
import { NavigateNext, ExpandMore, CheckCircle, Error, Cancel } from '@material-ui/icons';
import { useRecoilState, useRecoilValue } from 'recoil'
import { roomsForCampusSelector } from 'state/events';
import { reservationsBetweenSelector } from 'state/events';
import RoomReservationSelection from 'components/RoomReservationSelection/RoomReservationSelection';

const EventEntryValidationComp = ({ eventEntry, updateEventEntry, buildings, campusID }) => {

  const formatDateString = () => {
    const start = eventEntry.startDisplay.split(' ')
    const end = eventEntry.endDisplay.split(' ')
    if (start[0] === end[0]) {
      return `${start[0]} ${start[1]} ${start[2]} - ${end[1]} ${end[2]}`
    }
    return `${eventEntry.startDisplay} - ${eventEntry.endDisplay}`
  }

  const [toggleDropdown, setToggleDropdown] = useState(false)

  const rooms = useRecoilValue(roomsForCampusSelector({
    campusID: campusID,
    start: eventEntry.start,
    end: eventEntry.end
  }))

  const reservations = useRecoilValue(reservationsBetweenSelector({
    campusID: campusID,
    start: eventEntry.start,
    end: eventEntry.end
  }))

  const roomsFormated = rooms.map(r => {

    let room = {
      ...r,
      occupancy: parseInt(r.occupancy)
    }

    let number = r.number.split('-')
    room.number = number[number.length - 1]

    let existingReservationsForRoom = reservations.filter(res => res.forID === room.id && res.forType === 'room')
    room.reservationConflicts = existingReservationsForRoom.length
    room.bigEventConflict = existingReservationsForRoom.indexOf(i => i.bigEvent) === -1 ? false : true


    if (!r.openHours) {
      let building = buildings.find(b => b.id === r.buildingID)
      if (building) {
        if (building.openHours) {
          room.openHours = building.openHours
        } else {
          let campus = campuses.find(c => c.id === r.campusID)
          if (campus) {
            room.openHours = campus.openHours
          }
        }
      }
    }

    let roomSearch = `${r.name} room ${r.number} occupancy ${r.occupancy} ${r.tags.join(', ')}`
    room.search = roomSearch.toLowerCase()

    return room
  })

  const findIndexOfReservation = (forType, forID) => {
    return eventEntry.reservations.findIndex(r => forType === r.forType && forID === r.forID)
  }

  const addRemoveRoom = (room) => {
    let reservation = {
      forType: 'room',
      forID: room.id,
      buildingID: room.buildingID
    }
    let index = findIndexOfReservation('room', room.id)
    if (index === -1) {
      updateEventEntry({ reservations: { $push: [reservation] } })
    } else {
      updateEventEntry({ reservations: { $splice: [[index, 1]] } })
    }
  }

  return (
    <div>
      <Button
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: '5px 5px 5px 10px',
          color: '#393939',
          backgroundColor: '#fff',
          border: '1px solid #3695d7',
        }}
        onClick={() => setToggleDropdown(!toggleDropdown)}
      >
        <span style={{ marginRight: '10px', marginBottom: '-3px', fontSize: '16px' }}>{formatDateString()}</span>
        <div style={{ display: 'flex' }}>

          <CheckCircle style={{ margin: '0px', height: '20px', color: '#3695d7' }} />
          <Error style={{ margin: '0px', height: '20px', color: '#ff9800' }} />
          <Cancel style={{ margin: '0px', height: '20px', color: '#f44336' }} />
          {toggleDropdown ? <ExpandMore style={{ margin: '0px', height: '20px' }} /> : <NavigateNext style={{ margin: '0px', height: '20px' }} />}
        </div>
      </Button>
      <div style={{
        // minHeight: toggleDropdown ? `10px` : '0px',
        // maxHeight: toggleDropdown ? `300px` : '0px',
        // opacity: toggleDropdown ? 1 : 0,
        // transition: 'all 2000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
      }}>
        {(toggleDropdown && (
          <div
            style={{
              borderRadius: "4px",
              background: "#fff",
              padding: "5px 10px",
              border: "1px solid #c4c4c4",
              alignItems: 'center',
              flexWrap: 'wrap'
            }}>
            <RoomReservationSelection
              buildings={buildings}
              rooms={roomsFormated}
              reservations={eventEntry.reservations}
              addRemoveRoom={addRemoveRoom}
              dropdownDefault={false}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const EventEntryValidationLoading = ({ eventEntry }) => {
  const formatDateString = () => {
    const start = eventEntry.startDisplay.split(' ')
    const end = eventEntry.endDisplay.split(' ')
    if (start[0] === end[0]) {
      return `${start[0]} ${start[1]} ${start[2]} - ${end[1]} ${end[2]}`
    }
    return `${eventEntry.startDisplay} - ${eventEntry.endDisplay}`
  }
  return (
    <div>
      <Button
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: '5px 5px 5px 10px',
          color: '#393939',
          backgroundColor: '#fff',
          border: '1px solid #3695d7',
        }}
      >
        <span style={{ marginRight: '10px', marginBottom: '-3px', fontSize: '16px' }}>{formatDateString()}</span>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '5px' }}>
            <LoadingBars />
          </div>
          <NavigateNext style={{ margin: '0px' }} />
        </div>
      </Button>
    </div>
  )
}

const EventEntryValidation = (props) => {
  return (
    <React.Suspense fallback={<EventEntryValidationLoading {...props} />}>
      <EventEntryValidationComp {...props} />
    </React.Suspense>
  )
}

export default EventEntryValidation