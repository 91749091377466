import React, { useState } from 'react'

import Button from "components/CustomButtons/Button";

import update from 'immutability-helper'
import {
  NavigateNext,
  ExpandMore,
} from '@material-ui/icons';

const ViewRoomGeneric = ({ rooms, buildings, dropdownDefault = true }) => {
  const [roomToggle, setRoomToggle] = useState(dropdownDefault)
  const [buildingToggles, setBuildingToggles] = useState(Array(buildings.length).fill(true))
  const buildingsForDisplay = buildings.map(b => {
    return {
      building: b,
      floors: b.floors.map(floor => {
        return {
          floor: floor,
          rooms: rooms.filter(r => r.buildingID === b.id && r.floor === floor)
        }
      }),
      rooms: rooms.filter(r => r.buildingID === b.id),
    }
  })
  return (
    <>
      <Button
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '5px 5px 5px 10px' }}
        color='info'
        onClick={() => setRoomToggle(!roomToggle)}
      >
        <span style={{ marginRight: '10px', marginBottom: '-3px' }}>Room Reservations</span>
        {roomToggle ? <ExpandMore style={{ margin: '0px' }} /> : <NavigateNext style={{ margin: '0px' }} />}
      </Button>
      <div style={{
        opacity: roomToggle ? 1 : 0,
        transition: 'all 2000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
      }}>
        {(roomToggle) && (
          <div
            style={{
              borderRadius: "4px",
              background: "#fff",
              padding: "5px 10px",
              border: "1px solid #c4c4c4",
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            {buildingsForDisplay.map((b, index) => {
              return (
                <div key={b.building.id}>
                  {b.rooms.length > 0 && (<>
                    <Button
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '5px 5px 5px 10px',
                        color: '#393939',
                        backgroundColor: '#fff',
                        border: '1px solid #3695d7',
                        margin: '5px 0px'
                      }}
                      onClick={() => setBuildingToggles(prev => {
                        return update(prev, {
                          [index]: { $set: !buildingToggles[index] }
                        })
                      })}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '-3px', marginRight: '10px', flexWrap: 'wrap' }}>
                        <span style={{ marginRight: '5px', fontSize: '16px', fontWeight: 'bold' }}>{b.building.name}</span>
                      </div>
                      {buildingToggles[index] ? <ExpandMore style={{ margin: '0px' }} /> : <NavigateNext style={{ margin: '0px' }} />}
                    </Button>
                    <div
                      style={{
                        borderRadius: "4px",
                        background: "#eee",
                        padding: buildingToggles[index] ? "5px" : '0px',
                        border: buildingToggles[index] ? "1px solid #c4c4c4" : '',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        opacity: buildingToggles[index] ? 1 : 0,
                        transition: 'opacity 2000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
                        width: '100%',
                        margin: '5px 0px',
                      }}
                    >
                      {(buildingToggles[index]) && (
                        <>

                          {b.floors.map((floor, index) => {
                            return (
                              <div key={floor.floor}>
                                {floor.rooms.length > 0 && (
                                  <div style={{ margin: index !== 0 ? '10px 5px 0px 5px' : '0px 5px' }}>
                                    <p style={{ fontSize: '12px', fontWeight: 'bold', margin: '0px', color: '#393939' }}>Floor {floor.floor}</p>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0px -5px' }}>
                                      {floor.rooms.map(room => {
                                        return (
                                          <RoomDisplay
                                            key={room.id}
                                            room={room}
                                          />
                                        )
                                      })}
                                    </div>
                                  </div>
                                )}</div>
                            )
                          })}
                        </>)}
                    </div>
                  </>)}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </>
  )
}

const RoomDisplay = ({ room }) => {
  return (
    <div
      style={{
        margin: '5px',
        padding: '5px 7px 5px 10px',
        backgroundColor: '#fff',
        border: '1px solid #3695d7',
        color: '#393939',
        textTransform: 'none',
        borderRadius: '6px',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 400,
        lineHeight: 1.42857143,
        // cursor: 'pointer',
      }}
    >
      <div style={{ display: 'flex', flex: 1 }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          flex: 1,
          marginRight: '10px'
        }}>
          <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{room.name}</span>
          <span style={{ fontSize: '14px' }}>Room: {room.number} - Occupancy: {room.occupancy}</span>
          <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '220px' }}>
            {room.tags.map((tag, index) => {
              return (
                <span
                  key={index}
                  style={{ paddingRight: index < room.tags.length - 1 ? '3px' : '0px' }}
                >{tag}
                  {index < room.tags.length - 1 ? ', ' : ''}
                </span>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewRoomGeneric