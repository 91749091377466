import Loading from 'components/Loading/Loading'
import React, { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import CustomTabs from "components/CustomTabs/CustomTabs";
import {
  myTeamsEvents,
  getMyTeamsEvents
} from 'state/events';
import { userAtom } from 'state/auth';
import ListEvents from './EventTabs/ListEvents';
import { teamEventEntriesRequiringApproval } from 'state/events';
import ListEventEntries from './EventTabs/ListEventEntries';

const MyEventsComp = (props) => {
  const [teamEvents, setTeamEvents] = useRecoilState(myTeamsEvents)
  const [teamEventEntries, setTeamEventEntries] = useRecoilState(teamEventEntriesRequiringApproval)
  const user = useRecoilValue(userAtom)
  const userEvents = teamEvents.filter(e => e.createdBy === user.googleID)

  const needsRenewal = teamEvents.filter(e => e.needsRenewal)
  const hasConflicts = teamEvents.filter(e => e.numConflicts > 0)
  const entriesRejected = teamEventEntries.filter(e => e.status === 'rejected')
  const entriesPending = teamEventEntries.filter(e => e.status === 'pending')

  const updateEvents = async () => {
    let e = await getMyTeamsEvents()
    setTeamEvents(e)
    return
  }

  const tab = +(props.location.search?.split("=")[1] || 0);
  const tabs = [
    {
      tabName: "My Events",
      tabContent: <ListEvents events={userEvents.filter(e => e.numUpcomingEventEntries > 0)} updateEvents={updateEvents} userType='user' />,
    },
    {
      tabName: "Team Events",
      tabContent: <ListEvents events={teamEvents.filter(e => e.numUpcomingEventEntries > 0)} updateEvents={updateEvents} userType='user' />,
    },
    {
      tabName: `Needs Renewal (${needsRenewal.length})`,
      tabContent: <ListEvents events={needsRenewal} updateEvents={updateEvents} userType='user' />,
    },
    {
      tabName: `Has Conflicts (${hasConflicts.length})`,
      tabContent: <ListEvents events={hasConflicts} updateEvents={updateEvents} userType='user' />,
    },
    {
      tabName: `Pending (${entriesPending.length})`,
      tabContent: <ListEventEntries
        events={entriesPending}
        buttons={{}}
        userType='user'
      />,
    },
    {
      tabName: `Rejected (${entriesRejected.length})`,
      tabContent: <ListEventEntries
        events={entriesRejected}
        buttons={{}}
        userType='user'
      />,
    },
    {
      tabName: "Ended Events",
      tabContent: <ListEvents events={teamEvents.filter(e => e.numUpcomingEventEntries == 0)} updateEvents={updateEvents} userType='user' />,
    },
  ];

  return (
    <React.Fragment>
      <div style={{ display: 'flex', maxWidth: '1100px' }}>
        <CustomTabs headerColor="info" tabs={tabs} value={tab} />
      </div>
    </React.Fragment>
  )
}

const MyEventLoading = (props) => {

  const tab = +(props.location.search?.split("=")[1] || 0);
  const tabs = [
    {
      tabName: "My Events",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Team Events",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Needs Renewal",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Has Conflicts",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Pending",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Rejected",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Ended Events",
      tabContent: <Loading color="blue" />,
    },
  ];

  return (
    <React.Fragment>
      <div style={{ display: 'flex', maxWidth: '1100px' }}>
        <CustomTabs headerColor="info" tabs={tabs} value={tab} />
      </div>
    </React.Fragment>
  )
}

const MyEvents = (props) => {
  return (
    <React.Suspense fallback={<MyEventLoading {...props} />}>
      <MyEventsComp {...props} />
    </React.Suspense>
  )
}

export default MyEvents