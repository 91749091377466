import React, { useEffect, useState } from "react";
import CustomTabs from "components/CustomTabs/CustomTabs";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { useRecoilValue, useRecoilState } from "recoil";
import Loading from "components/Loading/Loading";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import update from "immutability-helper";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { Add, Close, Edit, Folder, DateRange, Delete, FolderOpen, LocalOffer, Cancel, HighlightOff, AddBox, Height } from "@material-ui/icons";
import {
  resourceAtom,
  campusAtom,
  getResources,
  createResource,
  updateResource,
  deleteResource
} from "state/events";
import Tags from "components/Tags/Tags";
import HelperText from "components/HelperText/HelperText";
import CardText from "components/Card/CardText";

const ResourceManager = ({ campuses, resources, setResources }) => {

  const [toggleForm, setToggleForm] = useState(false)
  // const [resources, setResources] = useRecoilState(resourceAtom)
  // const campuses = useRecoilValue(campusAtom)

  const emptyResource = {
    name: '',
    quantity: '',
    location: '',
    tags: [],
    campus: null,
    type: 'general',
    typeData: {}
  }

  const [resource, setResource] = useState(emptyResource)
  const [submitting, setSubmitting] = useState(false)

  const emptyError = {
    hasError: false,
    message: ''
  }


  const getEmptyErrors = (r = resource) => {
    let emptyErrors = {
      name: emptyError,
      quantity: emptyError,
      location: emptyError,
      tags: emptyError,
      campus: emptyError,
      type: emptyError,
    }
    if (r.type === 'general') {
      emptyErrors.typeData = {}
    }

    return emptyErrors
  }

  const [errors, setErrors] = useState(getEmptyErrors(emptyResource))

  const formattedResources = resources.map(r => {
    let campus = campuses.find(c => c.id === r.campusID)
    return {
      ...r,
      campusName: campus?.name || '',
      tagsIcon: (
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          {(r.tags.length > 0) && (
            <div title={r.tags.join('\n')} style={{ cursor: 'pointer', color: '#3695d7', display: 'flex', alignItems: 'center' }}>
              <LocalOffer style={{ fontSize: '22px' }} />
            </div>
          )}
        </div>
      ),
      actions: (
        <React.Fragment>
          <Button
            style={{ margin: '2px' }}
            justIcon
            color="primary"
            onClick={() => {
              let resource = {
                ...r,
                campus: campus,
              }
              setResource(resource)
              setErrors(getEmptyErrors(resource))
              setToggleForm(true)
            }}
            title='Edit'
          >
            <Edit />
          </Button>

          <Button
            style={{ margin: '2px' }}
            justIcon
            color="danger"
            onClick={async () => {
              if (confirm(`Delete Resource ${r.name}`)) {
                await deleteResource({ id: r._id })
                let temp = await getResources()
                setResources(temp)
              }
            }}
            title='Delete'
          >
            <Delete />
          </Button>
        </React.Fragment>
      )
    }
  })

  const cols = [
    {
      Header: "Name",
      accessor: "name",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "tagsIcon",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '22px', padding: '0', fontSize: '12px' },
      headerStyle: { maxWidth: '22px', padding: '0', margin: '0px' }
    },
    {
      Header: "Type",
      accessor: "type",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      disableFilters: false,
      disableSortBy: true,
      style: { maxWidth: '100px' },
      headerStyle: { maxWidth: '100px' }
    },
    {
      Header: "Campus",
      accessor: "campusName",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Location",
      accessor: "location",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '54px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }
    },
  ]

  const setTag = (v) => {
    setResource((prevState) => {
      return update(prevState, {
        tags: { $push: [v] }
      })
    })
  }
  const setTags = (v) => {
    setResource((prevState) => {
      return update(prevState, {
        tags: { $set: v }
      })
    })
  }
  const isFormValid = () => {
    setErrors(getEmptyErrors())
    let valid = true

    if (resource.name.trim() === '') {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          name: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    }

    if (!resource.quantity) {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          quantity: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    } else {
      if (resource.quantity < 0) {
        valid = false
        setErrors((prev) => {
          return update(prev, {
            quantity: {
              $merge: {
                hasError: true,
                message: '*Cannot be negative'
              }
            }
          })
        })
      }
    }

    if (!resource.campus) {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          campus: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    }

    if (resource.location.trim() === '') {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          location: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    }

    if (!resource.type) {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          type: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    }

    return valid

  }

  const submitForm = async () => {
    if (isFormValid()) {
      setSubmitting(true)

      let r = {
        name: resource.name,
        quantity: resource.quantity,
        tags: resource.tags,
        campusID: resource.campus.id,
        location: resource.location,
        type: resource.type,
        typeData: resource.typeData
      }

      if (resource._id) {
        r.id = resource._id
        await updateResource(r)
      } else {
        await createResource(r)
      }
      let temp = await getResources()
      setResources(temp)
      setResource(emptyResource)
      setErrors(getEmptyErrors(emptyResource))
      setSubmitting(false)
    }
  }

  return (
    <React.Fragment>
      {/* <Card style={{ display: 'flex', maxWidth: '1100px' }}>
        <CardHeader color='primary' text>
          <CardText color='primary' style={{ fontSize: '16px', fontWeight: '400', padding: '10px' }}>
            Resource Manager
          </CardText>
        </CardHeader>
        <CardBody> */}
      <div style={{ textAlign: 'right' }}>
        {!toggleForm && (
          <Button
            color='info'
            onClick={(e) => {
              e.preventDefault()
              setToggleForm(true)
            }}
            justIcon
            title='Create New'
          >
            <Add />
          </Button>
        )}
        {toggleForm && (
          <Button
            color='danger'
            onClick={(e) => {
              e.preventDefault()
              setResource(emptyResource)
              setErrors(getEmptyErrors(emptyResource))
              setToggleForm(false)
            }}
            justIcon
            title='Close'
          >
            <Close />
          </Button>
        )}
      </div>
      <div style={{
        maxHeight: toggleForm ? '1000px' : '0px',
        minHeight: toggleForm ? '265px' : '0px',
        opacity: toggleForm ? 1 : 0,
        transition: 'all 2000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
        position: 'relative',
        zIndex: 1,
        // overflow: 'auto'
      }}>
        {toggleForm && (
          <>
            {submitting ?
              <Loading color='blue' />
              :
              <div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <TextField
                    style={{ margin: '5px', flex: 1, minWidth: '225px' }}
                    label='Name'
                    required
                    fullWidth
                    variant='outlined'
                    error={errors.name.hasError}
                    helperText={errors.name.message}
                    value={resource.name}
                    onChange={(e) => setResource(prev => {
                      return update(prev, {
                        name: { $set: e.target.value }
                      })
                    })}
                  />
                  <TextField
                    style={{ margin: '5px', flex: 1, minWidth: '225px' }}
                    label='Quantity'
                    fullWidth
                    required
                    type="number"
                    variant='outlined'
                    error={errors.quantity.hasError}
                    helperText={errors.quantity.message}
                    value={resource.quantity}
                    onChange={(e) => {
                      let val = e.target.value
                      if (val) {
                        val = Math.round(val)
                        if (val < 0) {
                          val = 0
                        }
                      }
                      setResource(prev => {
                        return update(prev, {
                          quantity: { $set: val }
                        })
                      })
                    }
                    }
                  />
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div style={{ margin: '5px', flex: 1, minWidth: '225px' }}>
                    <Autocomplete
                      disablePortal
                      options={campuses}
                      value={resource.campus}
                      ListboxProps={
                        {
                          style: {
                            maxHeight: '290px',
                          }
                        }
                      }
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option) => resource.campus.id === option.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Campus"
                          variant="outlined"
                          error={errors.campus.hasError}
                          helperText={errors.campus.message}
                        />
                      )}
                      fullWidth
                      onChange={(e, v) => {
                        setResource(prev => {
                          return update(prev, {
                            $merge: { campus: v }
                          })
                        })
                      }}
                      autoSelect={true}
                      autoComplete={true}
                      autoHighlight={true}
                      disableClearable
                    />
                  </div>
                  <TextField
                    style={{ margin: '5px', flex: 1, minWidth: '225px' }}
                    label='Location'
                    fullWidth
                    required
                    variant='outlined'
                    value={resource.location}
                    error={errors.location.hasError}
                    helperText={errors.location.message}
                    onChange={(e) => setResource(prev => {
                      return update(prev, {
                        location: { $set: e.target.value }
                      })
                    })}
                  />
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div style={{ margin: '5px', flex: 1, minWidth: '225px' }}>
                    <Autocomplete
                      disablePortal
                      options={['general']}
                      value={resource.type}
                      ListboxProps={
                        {
                          style: {
                            maxHeight: '290px',
                          }
                        }
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Type"
                          variant="outlined"
                          error={errors.type.hasError}
                          helperText={errors.type.message}
                        />
                      )}
                      fullWidth
                      onChange={(e, v) => {
                        setResource(prev => {
                          return update(prev, {
                            $merge: { type: v }
                          })
                        })
                      }}
                      autoSelect={true}
                      autoComplete={true}
                      autoHighlight={true}
                      disableClearable
                    />
                  </div>
                  <div style={{ margin: '5px', flex: 1, minWidth: '225px' }}>
                    <Tags
                      tags={resource.tags}
                      setTag={setTag}
                      setTags={setTags}
                      variant={'outlined'}
                      label={'Tags'}
                      error={errors.tags.hasError}
                      helperText={errors.tags.message}
                    />
                  </div>
                </div>
                <div style={{ margin: '5px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                  <Button
                    style={{ margin: '10px 0px' }}
                    color='primary'
                    title='Submit'
                    onClick={(e) => {
                      e.preventDefault()
                      submitForm()
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            }
          </>
        )}
      </div>
      <div>
        <ReactTable
          columns={cols}
          data={formattedResources}
          minWidthTable={'600px'}
        />
      </div>

      {/* </CardBody>
      </Card> */}
    </React.Fragment>
  )
}

export default ResourceManager