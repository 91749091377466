import Loading from 'components/Loading/Loading'
import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'

import { makeStyles } from "@material-ui/core/styles";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// Card
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardText from "components/Card/CardText";

import { TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import update from 'immutability-helper'
import {
  getDatesForEventEntries,
  campusAtom,
  buildingsAtom,
  eventCalendarScheduleOutRangesAtom,
  renewEvent
} from 'state/events'
import HelperText from 'components/HelperText/HelperText';
import EventEntryValidation from 'components/EventEntryValidation/EventEntryValidation';
import { decryptData } from 'assets/jss/EncriptDecrypt/EncryptDecrypt';
import { eventToEdit } from 'state/events'
import GoBack from "components/GoBack/GoBack"

const useStyles = makeStyles(() => ({
  ...styles,
  ...dashboardStyles,
  formSection: {
    marginBottom: "1rem",
  },
}));

const RenewEventComp = (props) => {

  const classes = useStyles()

  const id = props.match.params.id
  const userType = props.match.params.userType
  const search = props.location.search?.split("?")[1] || null
  const searchData = search ? decryptData(search) : {}
  const returnLoc = searchData.returnLocation

  const eventOriginal = useRecoilValue(eventToEdit(id))
  const campuses = useRecoilValue(campusAtom)
  const buildings = useRecoilValue(buildingsAtom)
  const eventRanges = useRecoilValue(eventCalendarScheduleOutRangesAtom)

  const formattedEvent = {
    ...eventOriginal,
    campus: campuses.find(c => c.id === eventOriginal.campusID),
  }

  const startEndRecurringRefs = formattedEvent.eventEntriesData.map(e => e.startEndRecurringRef)

  const [event, setEvent] = useState(formattedEvent)

  const [dateTimeChanged, setDateTimeChanged] = useState(true)
  const [uneditedEventEntries, setUneditedEventEntries] = useState([])
  const [eventEntryErrors, setEventEntryErrors] = useState([])
  const [eventEntryLoading, setEventEntryLoading] = useState([])

  const eventHasWarning = eventEntryErrors.some(e => e.warning)
  const eventHasError = eventEntryErrors.some(e => e.error)
  const eventNotLoading = eventEntryLoading.every(e => !e)

  const [acknowledgeWarnings, setAcknowledgeWarnings] = useState(false)

  const selectableBuildings = event.campus ? buildings.filter(b => b.campusID === event.campus.id) : []

  const emptyErrors = {
    repeatEnds: { hasError: false, message: '' },
    renewReminder: { hasError: false, message: '' },
  }

  const [errors, setErrors] = useState(emptyErrors)
  const [loading, setLoading] = useState(false)

  const formatDateString = (date) => {
    const displayStart = date.split('-')
    return `${displayStart[1]}/${displayStart[2]}/${displayStart[0]}`
  }

  const validateInitialForm = () => {
    let displayRecurring = formatDateString(eventRanges.recurring)

    setErrors(emptyErrors)
    let valid = true

    if (!event.repeatEnds) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          repeatEnds: {
            $set: {
              hasError: true,
              message: `*Required`
            }
          }
        })
      })
    } else if (event.repeatEnds > eventRanges.recurring && !event.bigEvent) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          repeatEnds: {
            $set: {
              hasError: true,
              message: `*Cannot exceed ${displayRecurring}`
            }
          }
        })
      })
    } else if (event.repeatEnds < event.endDate) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          repeatEnds: {
            $set: {
              hasError: true,
              message: `*Must be greater than End Date`
            }
          }
        })
      })
    }

    if (event.renewReminder !== true && event.renewReminder !== false && event.repeatEnds !== '' && event.repeatEnds > eventRanges.start) {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          renewReminder: {
            $set: {
              hasError: true,
              message: `*Required`
            }
          }
        })
      })
    }

    return valid
  }

  const updateEventDataDateTimeChange = (updateData) => {
    setDateTimeChanged(true)
    setEvent(prev => {
      return update(prev, updateData)
    })
  }

  const updateEventData = (updateData) => {
    setEvent(prev => {
      return update(prev, updateData)
    })
  }

  const submitInitialForm = async (eventData = event) => {
    if (validateInitialForm()) {
      let start = `${eventData.startDate} ${eventData.startTime}`
      let end = `${eventData.endDate} ${eventData.endTime}`

      let timeslots = await getDatesForEventEntries({
        start: start,
        end: end,
        recurringType: eventData.recurringType,
        repeatOn: eventData.repeatOn,
        repeatEvery: eventData.repeatEvery,
        repeatEveryInterval: eventData.repeatEveryInterval,
        repeatEnds: eventData.repeatEnds
      })
      timeslots = timeslots.filter(t => startEndRecurringRefs.indexOf(t.startEndRecurringRef) === -1).map(ts => {
        const [startDate, startTime] = ts.start.split(' ')
        const [endDate, endTime] = ts.end.split(' ')
        return {
          ...ts,
          startDate: startDate,
          startTime: startTime,
          endDate: endDate,
          endTime: endTime
        }
      })
      if (timeslots.length === 0) {
        setErrors(prevState => {
          return update(prevState, {
            repeatEnds: {
              $set: {
                hasError: true,
                message: `*end date doesn't result in any new events`
              }
            }
          })
        })
      } else {
        setEvent(prev => {
          return update(prev, {
            eventEntriesData: { $set: timeslots }
          })
        })

        let eventEntryErrorsTemp = []
        let eventEntries = timeslots.map(e => {
          eventEntryErrorsTemp.push({ warning: false, error: false, messages: [] })
          return {
            ...e,
            reservations: event.reservations
          }
        })
        setEventEntryErrors(eventEntryErrorsTemp)
        setEventEntryLoading(eventEntryErrorsTemp.map(() => true))
        updateEventData({ eventEntriesData: { $set: eventEntries } })
        setUneditedEventEntries(eventEntries)
        setAcknowledgeWarnings(false)
        setDateTimeChanged(false)
      }
    }
  }

  const submitForm = async () => {
    setLoading(true)
    let newEvent = {
      id: event.id,
      renewReminder: event.renewReminder === null ? false : event.renewReminder,
      repeatEnds: event.repeatEnds,
      eventEntriesData: event.eventEntriesData.map(entry => {
        return {
          status: entry.status,
          start: `${entry.startDate} ${entry.startTime}`,
          end: `${entry.endDate} ${entry.endTime}`,
          edited: entry.edited,
          reservations: entry.reservations,
          startEndRecurringRef: entry.startEndRecurringRef
        }
      })
    }
    await renewEvent(newEvent)
    if (returnLoc) {
      window.location.href = returnLoc
    } else {
      window.location.href = '/portal/events/teamEvents'
    }
  }

  return (
    <React.Fragment>
      <div style={{ maxWidth: '1100px' }}>
        <GoBack returnLocation={searchData.returnLocation} disableRound={true} />
        <Card style={{ maxWidth: '1000px', marginBottom: '10px' }}>
          <CardHeader color='info' text>
            <CardText color='info' style={{ fontSize: '16px', fontWeight: '400', padding: '10px' }}>
              Renew Event
            </CardText>
          </CardHeader>
          <CardBody style={{ padding: '10px' }}>
            {loading ?
              <Loading color='blue' />
              :
              <div>
                {(event.recurringType !== 'Does Not Repeat' && event.repeatEnds !== '' && event.repeatEnds > eventRanges.start) && (
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>

                    <TextField
                      style={{ flex: 1, margin: '10px 5px', minWidth: '170px' }}
                      type="date"
                      variant="outlined"
                      label='Repeat Ends'
                      required
                      helperText={errors.repeatEnds.message}
                      error={errors.repeatEnds.hasError}
                      value={event.repeatEnds}
                      onChange={(e) => updateEventDataDateTimeChange({ repeatEnds: { $set: e.target.value } })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <div style={{ margin: '10px 5px', flex: 1 }}>
                      <div style={{ marginTop: '-11px' }}>
                        <div style={{ margin: '-6px 0px 0px 9px' }}>
                          <span style={{
                            position: 'relative',
                            margin: '0px',
                            padding: '0px 5px',
                            color: errors.renewReminder.hasError ? '#f44336' : '#757575',
                            backgroundColor: '#fff',
                            zIndex: 2,
                            fontSize: '12px'
                          }}>
                            Renewal Reminder *
                          </span>
                        </div>
                        <div
                          style={{
                            position: 'relative',
                            zIndex: 1,
                            display: 'flex',
                            flexWrap: 'wrap',
                            borderRadius: "4px",
                            background: "#fff",
                            padding: "1px 12px",
                            border: errors.renewReminder.hasError ? "1px solid #f44336" : "1px solid #c4c4c4",
                            alignItems: 'center',
                            marginTop: '-11px',
                            flexWrap: 'wrap'
                          }}
                        >

                          <FormControlLabel
                            control={
                              <Radio
                                checked={event.renewReminder === true}
                                name="renewReminder"
                                value="yes"
                                onChange={() => setEvent(prev => {
                                  return update(prev, {
                                    renewReminder: { $set: true }
                                  })
                                })}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                            }
                            style={{ color: '#797979' }}
                            label="Yes"
                          />

                          <FormControlLabel
                            control={
                              <Radio
                                checked={event.renewReminder === false}
                                name="renewReminder"
                                value="no"
                                onChange={() => setEvent(prev => {
                                  return update(prev, {
                                    renewReminder: { $set: false }
                                  })
                                })}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                            }
                            style={{ color: '#797979' }}
                            label="No"
                          />
                        </div>
                      </div>
                      <HelperText error={errors.renewReminder.hasError} helperText={errors.renewReminder.message} />
                    </div>
                  </div>
                )}
                {dateTimeChanged ?
                  <div style={{ margin: '10px 5px 0px 5px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                    <Button
                      style={{ margin: '0px' }}
                      color='primary'
                      title='Continue'
                      onClick={(e) => {
                        e.preventDefault()
                        submitInitialForm()
                      }}
                    >
                      Continue
                    </Button>
                  </div>
                  :
                  <>
                  </>
                }
              </div>
            }
          </CardBody>
        </Card>
        {(!dateTimeChanged && !loading) && (
          <div
            style={{
              maxWidth: '1000px',
              height: dateTimeChanged ? '0px' : '',
              overflow: dateTimeChanged ? 'hidden' : ''
            }}>
            <p style={{ margin: '0px' }}>New Events</p>
            {event.eventEntriesData.map((entry, index) => {
              const updateEventEntryAtIndex = (update) => {
                updateEventData({
                  eventEntriesData: { [index]: update }
                })
              }
              const updateEventEntryError = (updateData) => {
                setEventEntryErrors(prev => {
                  return update(prev, {
                    [index]: updateData
                  })
                })
              }
              const updateEventEntryLoading = (val) => {
                setEventEntryLoading(prev => {
                  return update(prev, {
                    [index]: { $set: val }
                  })
                })
              }
              return (
                <EventEntryValidation
                  key={index}
                  eventEntry={entry}
                  updateEventEntry={updateEventEntryAtIndex}
                  buildings={selectableBuildings}
                  campusID={event.campus.id}
                  bigEvent={event.bigEvent}
                  uneditedEventEntry={uneditedEventEntries[index]}
                  eventEntryError={eventEntryErrors[index]}
                  updateEventEntryError={updateEventEntryError}
                  eventRanges={eventRanges}
                  setAcknowledgeWarnings={setAcknowledgeWarnings}
                  updateEventEntryLoading={updateEventEntryLoading}
                  eventID={event.id}
                />
              )
            })}
            {(eventHasWarning && !eventHasError) && !acknowledgeWarnings && eventNotLoading && (
              <div style={{ textAlign: 'right' }}>
                <Button
                  color='warning'
                  title='Acknowledge Warnings'
                  onClick={(e) => {
                    e.preventDefault()
                    setAcknowledgeWarnings(true)
                  }}
                >
                  Acknowledge Warnings
                </Button>
              </div>
            )}
            {((eventHasWarning && acknowledgeWarnings) || !eventHasWarning) && eventNotLoading && (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                <div>
                  <Button
                    disabled={eventHasError}
                    color='primary'
                    title='Submit'
                    onClick={(e) => {
                      e.preventDefault()
                      submitForm()
                    }}
                  >
                    Submit
                  </Button>
                </div>
                {eventHasError &&
                  (<p style={{ color: '#f44336', fontSize: '12px', fontWeight: 'bold' }}>* Fix errors before submitting</p>

                  )}
              </div>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

const RenewEvent = (props) => {
  return (
    <React.Suspense fallback={<Loading color='blue' />}>
      <RenewEventComp  {...props} />
    </React.Suspense>
  )
}

export default RenewEvent