import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import update from "immutability-helper";
import { Add, Close, Edit, DateRange, Delete, LocalOffer } from "@material-ui/icons";
import {
  eventTagGroupsAtom,
  createEventTagGroup,
  updateEventTagGroup,
  deleteEventTagGroup,
  getEventTagGroups
} from "state/events";
import Tags from "components/Tags/Tags";
import HelperText from "components/HelperText/HelperText";
import { useRecoilState } from "recoil";

const EventTagGroups = () => {

  const emptyError = {
    hasError: false,
    message: ''
  }

  const emptyTag = {
    name: ''
  }

  const [eventTagGroups, setEventTagGroups] = useRecoilState(eventTagGroupsAtom)
  const [toggleForm, setToggleForm] = useState(false)
  const [eventTagGroup, setEventTagGroup] = useState(emptyTag)
  const [submitting, setSubmitting] = useState(false)
  const [nameError, setNameError] = useState(emptyError)


  const listEventTags = eventTagGroups.map(tag => {
    return {
      ...tag,
      actions: (
        <React.Fragment>
          <Button
            style={{ margin: '2px' }}
            justIcon
            color="primary"
            onClick={() => {
              setEventTagGroup(tag)
              setNameError(emptyError)
              setToggleForm(true)
            }}
            title='Edit'
          >
            <Edit />
          </Button>

          {/* may not be worth having a delete they jsut edit existing ones instead of deleting
          <Button
            style={{ margin: '2px' }}
            justIcon
            color="danger"
            onClick={async () => {
              if (confirm(`Delete Event Tag ${r.name}`)) {
                await deleteEventTagGroup({id:tag._id})

              }
            }}
            title='Delete'
          >
            <Delete />
          </Button> */}
        </React.Fragment>
      )
    }
  })

  const cols = [
    {
      Header: "Name",
      accessor: "name",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '54px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }
    },
  ]

  const isFormValid = () => {
    if (eventTagGroup.name.trim() === '') {
      setNameError({
        hasError: true,
        message: '*Required'
      })
      return false
    }

    if (!eventTagGroup._id) {
      if (eventTagGroups.find(t => t.name.toLowerCase() === eventTagGroup.name.toLowerCase())) {
        setNameError({
          hasError: true,
          message: '*Event tag already exists.'
        })
        return false
      }
    }

    setNameError(emptyError)
    return true
  }

  const submitForm = async () => {
    if (isFormValid()) {
      setSubmitting(true)
      let tag = {
        name: eventTagGroup.name
      }

      if (eventTagGroup._id) {
        tag._id = eventTagGroup._id
        await updateEventTagGroup(tag)
      } else {
        await createEventTagGroup(tag)
      }
      let tags = await getEventTagGroups()
      setEventTagGroups(tags)
      setEventTagGroup(emptyTag)
      setSubmitting(false)
    }
  }

  return (
    <React.Fragment>
      <div style={{ textAlign: 'right' }}>
        {!toggleForm && (
          <Button
            color='info'
            onClick={(e) => {
              e.preventDefault()
              setToggleForm(true)
            }}
            justIcon
            title='Create New'
          >
            <Add />
          </Button>
        )}
        {toggleForm && (
          <Button
            color='danger'
            onClick={(e) => {
              e.preventDefault()
              setEventTagGroup(emptyTag)
              setNameError(emptyError)
              setToggleForm(false)
            }}
            justIcon
            title='Close'
          >
            <Close />
          </Button>
        )}
      </div>
      <div style={{
        maxHeight: toggleForm ? `250px` : '0px',
        minHeight: toggleForm ? `130px` : '0px',
        opacity: toggleForm ? 1 : 0,
        transition: 'all 1300ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
        position: 'relative',
        zIndex: 1,
        // overflow: 'auto'
      }}>
        {toggleForm && (
          <>
            {!submitting ?
              <div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <TextField
                    style={{ margin: '5px', flex: 1 }}
                    label='Tag'
                    fullWidth
                    required
                    variant='outlined'
                    value={eventTagGroup.name}
                    error={nameError.hasError}
                    helperText={nameError.message}
                    onChange={(e) => setEventTagGroup(prev => {
                      return update(prev, {
                        name: { $set: e.target.value }
                      })
                    })}
                  />
                </div>
                <div style={{ margin: '5px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                  <Button
                    style={{ margin: '10px 0px' }}
                    color='primary'
                    title='Submit'
                    onClick={(e) => {
                      e.preventDefault()
                      submitForm()
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
              :
              <Loading color='blue' />
            }
          </>
        )}
      </div>
      <div style={{
        backgroundColor: 'white',
        position: 'relative',
        zIndex: 2
      }}>
        <ReactTable
          columns={cols}
          data={listEventTags}
          minWidthTable={'600px'}
        />
      </div>
    </React.Fragment>
  )
}

export default EventTagGroups