import React, { useState } from 'react'

import Button from "components/CustomButtons/Button";

import update from 'immutability-helper'
import {
  Info,
  NavigateNext,
  ExpandMore,
  AccessTime,
  Block,
  EventBusy,
  PriorityHigh,
  AssignmentLate,
  DateRange
} from '@material-ui/icons';

const RoomReservationDisplay = ({ buildingsRaw, roomsRaw, reservationsRaw, bigEvent, reservationsEventEntryPreviews, dropdownDefault = true }) => {

  const buildings = buildingsRaw.map(b => {
    if (b._id) {
      return {
        ...b,
        id: b._id
      }
    }
    return b
  })

  const reservations = reservationsRaw.map(r => {
    let res = {
      ...r,
    }
    if (r._id) {
      res.id = r._id
    }
    res.eventEntry = reservationsEventEntryPreviews.find(p => p.eventEntryID === res.eventEntryID)
    return res
  })

  const rooms = roomsRaw.map(r => {
    let room = {
      ...r,
    }
    if (r._id) {
      room.id = r._id
    }

    let existingReservationsForRoom = reservations.filter(res => res.forID === room.id && res.forType === 'room')
    room.reservationConflicts = existingReservationsForRoom.filter(i => !i.bigEvent)
    room.bigEventConflict = existingReservationsForRoom.filter(i => i.bigEvent)
    return room
  })

  const [buildingToggles, setBuildingToggles] = useState(Array(buildings.length).fill(true))
  const [roomToggle, setRoomToggle] = useState(dropdownDefault)

  const buildingsForDisplay = buildings.map(b => {
    return {
      building: b,
      floors: b.floorNames.map(floor => {
        return {
          floor: floor,
          rooms: rooms.filter(r => r.buildingID === b.id && r.floor === floor)
        }
      }),
      rooms: rooms.filter(r => r.buildingID === b.id),
    }
  })

  return (
    <>
      <Button
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '5px 5px 5px 10px' }}
        color='info'
        onClick={() => setRoomToggle(!roomToggle)}
      >
        <span style={{ marginRight: '10px', marginBottom: '-3px' }}>Room Reservations</span>
        {roomToggle ? <ExpandMore style={{ margin: '0px' }} /> : <NavigateNext style={{ margin: '0px' }} />}
      </Button>
      <div style={{
        opacity: roomToggle ? 1 : 0,
        transition: 'all 2000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
      }}>
        {(roomToggle) && (
          <div
            style={{
              borderRadius: "4px",
              background: "#fff",
              padding: "5px 10px",
              border: "1px solid #c4c4c4",
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            {buildingsForDisplay.map((b, index) => {
              return (
                <div key={b.building.id}>
                  {b.rooms.length > 0 && (<>
                    <Button
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '5px 5px 5px 10px',
                        color: '#393939',
                        backgroundColor: '#fff',
                        border: '1px solid #3695d7',
                        margin: '5px 0px'
                      }}
                      onClick={() => setBuildingToggles(prev => {
                        return update(prev, {
                          [index]: { $set: !buildingToggles[index] }
                        })
                      })}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '-3px', marginRight: '10px', flexWrap: 'wrap' }}>
                        <span style={{ marginRight: '5px', fontSize: '16px', fontWeight: 'bold' }}>{b.building.name}</span>
                      </div>
                      {buildingToggles[index] ? <ExpandMore style={{ margin: '0px' }} /> : <NavigateNext style={{ margin: '0px' }} />}
                    </Button>
                    <div
                      style={{
                        borderRadius: "4px",
                        background: "#eee",
                        padding: buildingToggles[index] ? "5px" : '0px',
                        border: buildingToggles[index] ? "1px solid #c4c4c4" : '',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        opacity: buildingToggles[index] ? 1 : 0,
                        transition: 'opacity 2000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
                        width: '100%',
                        margin: '5px 0px',
                      }}
                    >
                      {(buildingToggles[index]) && (
                        <>

                          {b.floors.map((floor, index) => {
                            return (
                              <div key={floor.floor}>
                                {floor.rooms.length > 0 && (
                                  <div style={{ margin: index !== 0 ? '10px 5px 0px 5px' : '0px 5px' }}>
                                    <p style={{ fontSize: '12px', fontWeight: 'bold', margin: '0px', color: '#393939' }}>Floor {floor.floor}</p>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0px -5px' }}>
                                      {floor.rooms.map(room => {
                                        return (
                                          <RoomSelectButton
                                            key={room.id}
                                            room={room}
                                          />
                                        )
                                      })}
                                    </div>
                                  </div>
                                )}</div>
                            )
                          })}
                        </>)}
                    </div>
                  </>)}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </>
  )
}

const RoomSelectButton = ({ room }) => {
  const [selected, setSelected] = useState(false)

  const normal = room.openHours.filter(i => i.type === 'normal').map(oh => {
    return {
      ...oh,
      days: oh.days.map(d => {
        if (d === 'Thursday') {
          return 'Th'
        } else {
          return d[0]
        }
      }).join(','),
      timeslots: oh.timeslots.length > 0 ? oh.timeslots.map(ts => `${ts.start}-${ts.end}`) : ['Closed']
    }
  })
  const special = room.openHours.filter(i => i.type === 'special').map(oh => {
    let start = oh.startDate.split('-')
    let end = oh.endDate.split('-')
    return {
      ...oh,
      date: oh.startDate === oh.endDate ? `${start[1]}/${start[2]}/${start[0]}` : `${start[1]}/${start[2]}/${start[0]} - ${end[1]}/${end[2]}/${end[0]}`,
      timeslots: oh.timeslots.length > 0 ? oh.timeslots.map(ts => `${ts.start}-${ts.end}`) : ['Closed']
    }
  })
  return (
    <div
      style={{
        margin: '5px',
        padding: '5px 7px 5px 10px',
        backgroundColor: '#fff',
        border: '1px solid #3695d7',
        color: '#393939',
        textTransform: 'none',
        borderRadius: '6px',
        display: 'flex',
        alignItems: selected ? 'start' : 'center',
        fontWeight: 400,
        lineHeight: 1.42857143,
        cursor: 'pointer',
        width: selected ? '100%' : '',
      }}
      onClick={() => setSelected(!selected)}
    >
      {selected ?
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          flex: 1,
          marginRight: '10px'
        }}>
          <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{room.name}</span>
          <span style={{ fontSize: '14px' }}>Room: {room.number} - Occupancy: {room.occupancy}</span>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <span style={{ marginRight: '4px' }}>Tags:</span>
            {room.tags.map((tag, index) => {
              return (
                <span
                  key={index}
                  style={{ paddingRight: index < room.tags.length - 1 ? '3px' : '0px' }}
                >{tag}
                  {index < room.tags.length - 1 ? ', ' : ''}
                </span>
              )
            })}
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', fontSize: '14px', gap: '5px' }}>
            {(room.bigEventConflict.length > 0) && (
              <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <AssignmentLate style={{ margin: '1px 0px 0px -2px', height: '15px', color: '#ff9800' }} />
                  <span>Room is being used by other special events.</span>
                </div>
                <div style={{ textAlign: 'left', border: "1px solid #c4c4c4", borderRadius: '4px', padding: '5px' }}>
                  <p style={{ margin: '0px' }}>Events:</p>
                  {room.bigEventConflict.map(conflict => {
                    return (
                      <p style={{ margin: '0px' }}
                        key={conflict.id}>
                        {conflict.eventEntry.name} - {conflict.eventEntry.createdByDepartmentName}
                      </p>
                    )

                  })}
                </div>
              </div>
            )}
            {(room.reservationConflicts.length > 0) && (
              <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <EventBusy style={{ margin: '0px', height: '15px', color: '#ff9800' }} />
                  <span>Room is being used by other events.</span>
                </div>
                <div style={{ textAlign: 'left', border: "1px solid #c4c4c4", borderRadius: '4px', padding: '5px' }}>
                  <p style={{ margin: '0px' }}>Events:</p>
                  {room.reservationConflicts.map(conflict => {
                    return (
                      <p style={{ margin: '0px' }}
                        key={conflict.id}>
                        {conflict.eventEntry.name} - {conflict.eventEntry.createdByDepartmentName}
                      </p>
                    )

                  })}
                </div>
              </div>
            )}

            {(room.openHourConflict) && (
              <div style={{ fontSize: '14px' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <AccessTime style={{ margin: '1px 0px 0px -2px', height: '15px', color: '#ff9800' }} />
                  <span>Event takes place outside of buildings open hours.</span>
                </div>
                <div style={{ textAlign: 'left', border: "1px solid #c4c4c4", borderRadius: '4px', padding: '5px' }}>
                  <span>Open Hours:</span>
                  {normal.map(oh => {
                    return (
                      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }} key={oh._id}>
                        <span style={{ marginRight: '5px' }}>{oh.days}</span>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {oh.timeslots.map((ts, idx) => {
                            return (<span key={idx}>{ts}</span>)
                          })}
                        </div>
                      </div>
                    )
                  })}
                  {special.map(oh => {
                    return (
                      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }} key={oh._id}>
                        <span style={{ marginRight: '5px' }}>{oh.date}</span>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {oh.timeslots.map((ts, idx) => {
                            return (<span key={idx}>{ts}</span>)
                          })}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>)}
          </div>
        </div>
        :
        <div style={{ display: 'flex', flex: 1 }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            flex: 1,
            marginRight: '10px'
          }}>
            <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{room.name}</span>
            <span style={{ fontSize: '14px' }}>Room: {room.number} - Occupancy: {room.occupancy}</span>
            <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '220px' }}>
              {room.tags.map((tag, index) => {
                return (
                  <span
                    key={index}
                    style={{ paddingRight: index < room.tags.length - 1 ? '3px' : '0px' }}
                  >{tag}
                    {index < room.tags.length - 1 ? ', ' : ''}
                  </span>
                )
              })}
            </div>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-Between',
            minHeight: '55px'
          }}>
            {(room.bigEventConflict.length > 0) ? <AssignmentLate style={{ margin: '0px', height: '15px', color: '#ff9800' }} /> : <div style={{ margin: '0px', height: '15px', color: '#f44336' }} />}
            {(room.reservationConflicts.length > 0) ? <EventBusy style={{ margin: '0px', height: '15px', color: '#ff9800' }} /> : <div style={{ margin: '0px', height: '15px', color: '#f44336' }} />}
            {(room.openHourConflict) ? <AccessTime style={{ margin: '0px', height: '15px', color: '#ff9800' }} /> : <div style={{ margin: '0px', height: '15px', color: '#f44336' }} />}
          </div>
        </div>
      }
    </div>
  )
}

export default RoomReservationDisplay