import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import Loading from "components/Loading/Loading";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import update from "immutability-helper";
import { Add, Close, Edit, DateRange, Delete, LocalOffer } from "@material-ui/icons";
import {
  timezonesAtom,
  getCampuses,
  createCampus,
  updateCampus,
  deleteCampus,
  setOpenHours,
  sortDaysOfWeek
} from "state/events";
import Tags from "components/Tags/Tags";
import './RoomManager.css'
import HelperText from "components/HelperText/HelperText";

const Campuses = ({ campuses, setCampuses, handleDeleteUpdate }) => {
  const timezones = useRecoilValue(timezonesAtom)

  const [toggleForm, setToggleForm] = useState(false)

  const emptyCampus = {
    name: '',
    location: '',
    tags: [],
    timezone: 'US/Mountain',
    files: [],
    abbreviation: ''
  }



  const [campus, setCampus] = useState(emptyCampus)
  const [submitting, setSubmitting] = useState(false)

  const emptyError = {
    hasError: false,
    message: ''
  }

  const emptyOpenHoursError = {
    type: emptyError,
    days: emptyError,
    startDate: emptyError,
    endDate: emptyError,
  }

  const getEmptyErrors = (c = campus) => {
    return {
      name: emptyError,
      location: emptyError,
      timezone: emptyError,
      tags: emptyError,
      abbreviation: emptyError,
    }
  }

  const [errors, setErrors] = useState(getEmptyErrors())

  const formatedCampuses = campuses.map(c => {
    let item = {
      ...c,
      tagsIcon: (
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          {(c.tags.length > 0) && (
            <div title={c.tags.join('\n')} style={{ cursor: 'pointer', color: '#3695d7', display: 'flex', alignItems: 'center' }}>
              <LocalOffer style={{ fontSize: '22px' }} />
            </div>
          )}
        </div>
      ),
      actions: (
        <React.Fragment>
          <Button
            style={{ margin: '2px' }}
            justIcon
            color="primary"
            onClick={() => {
              setCampus(c)
              setErrors(getEmptyErrors(c))
              setToggleForm(true)
            }}
            title='Edit'
          >
            <Edit />
          </Button>
          <Button
            style={{ margin: '2px' }}
            justIcon
            color="danger"
            onClick={async () => {
              if (confirm(`Delete Campus ${c.name}`)) {
                await deleteCampus({ id: c.id })
                let temp = await getCampuses()
                setCampuses(temp)
                handleDeleteUpdate()
              }
            }}
            title='Delete'
          >
            <Delete />
          </Button>
        </React.Fragment>
      )
    }
    return item
  })

  const cols = [
    {
      Header: "Name",
      accessor: "name",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "tagsIcon",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '22px', padding: '0', fontSize: '12px' },
      headerStyle: { maxWidth: '22px', padding: '0', margin: '0px' }
    },
    {
      Header: "Location",
      accessor: "location",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Timezone",
      accessor: "timezone",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '54px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }
    },
  ]

  const setTag = (v) => {
    setCampus((prevState) => {
      return update(prevState, {
        tags: { $push: [v] }
      })
    })
  }
  const setTags = (v) => {
    setCampus((prevState) => {
      return update(prevState, {
        tags: { $set: v }
      })
    })
  }

  const isFormValid = () => {
    setErrors(getEmptyErrors())
    let valid = true

    if (campus.name.trim() === '') {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          name: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    }

    // this is because room names must be unique on google
    // if (campuses.find(c => c.name.toLowerCase() === campus.name.toLowerCase() && campus.id !== c.id)) {
    //   valid = false
    //   setErrors((prev) => {
    //     return update(prev, {
    //       name: {
    //         $merge: {
    //           hasError: true,
    //           message: '*Campus name already exists'
    //         }
    //       }
    //     })
    //   })
    // }

    if (campus.location.trim() === '') {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          location: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    }

    if (campus.abbreviation.trim() === '') {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          abbreviation: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    }

    if (!campus.timezone) {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          timezone: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    }

    return valid
  }

  const submitForm = async () => {
    if (isFormValid()) {
      setSubmitting(true)
      let c = {
        name: campus.name,
        location: campus.location,
        tags: campus.tags,
        timezone: campus.timezone,
        abbreviation: campus.abbreviation
      }

      if (campus.id) {
        c.id = campus.id
        await updateCampus(c)
      } else {
        let campusID = await createCampus(c)
        c.id = campusID
      }

      let temp = await getCampuses()
      setCampuses(temp)
      setCampus(emptyCampus)
      setErrors(getEmptyErrors(emptyCampus))
      setSubmitting(false)
    }
  }

  return (
    <React.Fragment>
      <div style={{ textAlign: 'right' }}>
        {!toggleForm && (
          <Button
            color='info'
            onClick={(e) => {
              e.preventDefault()
              setToggleForm(true)
            }}
            justIcon
            title='Create New'
          >
            <Add />
          </Button>
        )}
        {toggleForm && (
          <Button
            color='danger'
            onClick={(e) => {
              e.preventDefault()
              setCampus(emptyCampus)
              setErrors(getEmptyErrors(emptyCampus))
              setToggleForm(false)
            }}
            justIcon
            title='Close'
          >
            <Close />
          </Button>
        )}
      </div>
      <div style={{
        height: toggleForm ? `284px` : '0px',
        opacity: toggleForm ? 1 : 0,
        transition: 'all 2000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
        position: 'relative',
        zIndex: 1,
        // overflow: 'auto'
      }}>
        {toggleForm && (
          <>
            {!submitting ?
              <div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <TextField
                    style={{ margin: '5px', flex: 1, minWidth: '225px' }}
                    label='Name'
                    required
                    fullWidth
                    variant='outlined'
                    error={errors.name.hasError}
                    helperText={errors.name.message}
                    value={campus.name}
                    onChange={(e) => setCampus(prev => {
                      return update(prev, {
                        name: { $set: e.target.value }
                      })
                    })}
                  />
                  <TextField
                    style={{ margin: '5px', flex: 1, minWidth: '225px' }}
                    label='Campus Abbreviation'
                    fullWidth
                    required
                    variant='outlined'
                    value={campus.abbreviation}
                    error={errors.abbreviation.hasError}
                    helperText={errors.abbreviation.message}
                    onChange={(e) => setCampus(prev => {
                      return update(prev, {
                        abbreviation: { $set: e.target.value.toUpperCase() }
                      })
                    })}
                  />
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div style={{ margin: '5px', flex: 1, minWidth: '225px' }}>
                    <Autocomplete
                      disablePortal
                      options={timezones}
                      value={campus.timezone}
                      ListboxProps={
                        {
                          style: {
                            maxHeight: '290px',
                          }
                        }
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Timezone"
                          variant="outlined"
                          error={errors.timezone.hasError}
                          helperText={errors.timezone.message}
                        />
                      )}
                      fullWidth
                      onChange={(e, v) => {
                        setCampus(prev => {
                          return update(prev, {
                            timezone: { $set: v }
                          })
                        })
                      }}
                      autoSelect={true}
                      autoComplete={true}
                      autoHighlight={true}
                      disableClearable
                    />
                  </div>
                  <TextField
                    style={{ margin: '5px', flex: 1, minWidth: '225px' }}
                    label='Location'
                    fullWidth
                    required
                    variant='outlined'
                    value={campus.location}
                    error={errors.location.hasError}
                    helperText={errors.location.message}
                    onChange={(e) => setCampus(prev => {
                      return update(prev, {
                        location: { $set: e.target.value }
                      })
                    })}
                  />
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div style={{ margin: '5px', flex: 1, minWidth: '225px' }}>
                    <Tags
                      tags={campus.tags}
                      setTag={setTag}
                      setTags={setTags}
                      variant={'outlined'}
                      label={'Tags'}
                      error={errors.tags.hasError}
                      helperText={errors.tags.message}
                    />
                  </div>
                </div>
                <div style={{ margin: '5px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                  <Button
                    style={{ margin: '20px 0px' }}
                    color='primary'
                    title='Submit'
                    onClick={(e) => {
                      e.preventDefault()
                      submitForm()
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
              :
              <Loading color='blue' />
            }
          </>
        )}
      </div>
      <div style={{
        backgroundColor: 'white',
        position: 'relative',
        zIndex: 2
      }}>
        <ReactTable
          columns={cols}
          data={formatedCampuses}
          minWidthTable={'600px'}
        />
      </div>
    </React.Fragment>
  )
}

export default Campuses