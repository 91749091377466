import React from 'react'
import Button from "components/CustomButtons/Button.js";
import HelperText from 'components/HelperText/HelperText';

const DaysOfWeekSelection = ({ days, removeDay, addDay, error, required, label, customDaysOfWeek = null, minWidth = '240px' }) => {

  const daysOfWeek = customDaysOfWeek ? customDaysOfWeek :
    [
      {
        id: 0,
        label: 'S'
      },
      {
        id: 1,
        label: 'M'
      },
      {
        id: 2,
        label: 'T'
      },
      {
        id: 3,
        label: 'W'
      },
      {
        id: 4,
        label: 'T'
      },
      {
        id: 5,
        label: 'F'
      },
      {
        id: 6,
        label: 'S'
      },
    ]



  return (
    <div style={{ marginTop: '-11px', minWidth: minWidth }}>
      <div style={{ margin: '-6px 0px 0px 9px' }}>
        <span style={{
          position: 'relative',
          margin: '0px',
          padding: '0px 5px',
          color: error.hasError ? '#f44336' : '#757575',
          backgroundColor: '#fff',
          zIndex: 2,
          fontSize: '12px'
        }}>
          {label}{required ? ' *' : ''}
        </span>
      </div>
      <div
        style={{
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          flexWrap: 'wrap',
          borderRadius: "4px",
          background: "#fff",
          padding: "6px 12px",
          border: error.hasError ? "1px solid #f44336" : "1px solid #c4c4c4",
          alignItems: 'center',
          marginTop: '-11px',
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}
      >
        {daysOfWeek.map((i) => {
          return (
            <Button
              key={i.id}
              color='primary'
              style={{
                margin: '2px',
                padding: '0px',
                fontSize: '20px',
                width: '38px',
                height: '38px',
                borderRadius: '20px',
                backgroundColor: days.indexOf(i.id) !== -1 ? '' : '#aaa'
              }}
              onClick={() => {
                let index = days.indexOf(i.id)
                if (index === -1) {
                  addDay(i.id)
                } else {
                  removeDay(index)
                }
              }}
            >
              {i.label}
            </Button>
          )
        })}
      </div>
      <HelperText error={error.hasError} helperText={error.message} />
    </div>
  )
}

export default DaysOfWeekSelection