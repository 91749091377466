import React, { useState } from "react";
import CustomTabs from "components/CustomTabs/CustomTabs";
import { useRecoilState } from "recoil";
import Loading from "components/Loading/Loading";
import EventTagGroups from "./EventCalendarManagerTabs/EventTagGroups";
import { adminEventEntriesRequiringApproval, adminGetEventEntriesRequiringApproval } from "state/events";
import ListEventEntries from "./EventTabs/ListEventEntries";
import { adminEventEntriesWithConflicts } from "state/events";

const EventCalanderManagerComp = (props) => {

  const tab = +(props.location.search?.split("=")[1] || 0);

  const [approvalEventEntries, setApprovalEventEntries] = useRecoilState(adminEventEntriesRequiringApproval)
  const [eventEntriesWithConflicts, setEventEntriesWithConflicts] = useRecoilState(adminEventEntriesWithConflicts)

  const updateEventEntries = () => {
    setApprovalEventEntries(adminGetEventEntriesRequiringApproval())
  }

  const tabs = [
    {
      tabName: "Pending",
      tabContent: <ListEventEntries
        events={approvalEventEntries.filter(e => e.status === 'pending')}
        userType="admin"
        buttons={{
          approval: true
        }}
        updateEventEntries={updateEventEntries}
      />,
    },
    {
      tabName: "Approved",
      tabContent: <ListEventEntries
        events={approvalEventEntries.filter(e => e.status === 'active')}
        userType="admin"
        buttons={{

        }}
      />,
    },
    {
      tabName: "Rejected",
      tabContent: <ListEventEntries
        events={approvalEventEntries.filter(e => e.status === 'rejected')}
        userType="admin"
        buttons={{

        }}
      />,
    },
    {
      tabName: "Conflicts",
      tabContent: <ListEventEntries
        userType="admin"
        events={eventEntriesWithConflicts}
        buttons={{

        }}
      />,
    },
    {
      tabName: "Event Tags",
      tabContent: <EventTagGroups />,
    },
    {
      tabName: "Failed Google Calls",
      tabContent: <Loading color="blue" />,
    },
  ];


  return (
    <React.Fragment>
      <div style={{ display: 'flex', maxWidth: '1100px' }}>
        <CustomTabs headerColor="info" tabs={tabs} value={tab} />
      </div>
    </React.Fragment>
  )
}

const EventCalanderManagerLoading = (props) => {

  const tab = +(props.location.search?.split("=")[1] || 0);
  const tabs = [
    {
      tabName: "Pending",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Approved",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Rejected",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Conflicts",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Event Tags",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Failed Google Calls",
      tabContent: <Loading color="blue" />,
    },
  ];
  return (
    <React.Fragment>
      <div style={{ display: 'flex', maxWidth: '1100px' }}>
        <CustomTabs headerColor="info" tabs={tabs} value={tab} />
      </div>
    </React.Fragment>
  )
}

const EventCalanderManager = (props) => {
  return (
    <React.Suspense fallback={<EventCalanderManagerLoading {...props} />}>
      <EventCalanderManagerComp {...props} />
    </React.Suspense>
  )
}

export default EventCalanderManager