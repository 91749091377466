import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import update from "immutability-helper";
import { Add, Close, Edit, DateRange, Delete, LocalOffer } from "@material-ui/icons";
import {
  createRoom,
  updateRoom,
  deleteRoom,
  getRooms,
  setOpenHours,
  sortDaysOfWeek
} from "state/events";
import Tags from "components/Tags/Tags";
import './RoomManager.css'
import HelperText from "components/HelperText/HelperText";

const Rooms = ({ campuses, buildings, rooms, setRooms }) => {

  const [toggleForm, setToggleForm] = useState(false)

  const emptyRoom = {
    name: '',
    campus: campuses.length > 0 ? campuses[0] : null,
    building: null,
    number: '',
    occupancy: '',
    tags: [],
    files: [],
    floor: '1',
  }

  const [room, setRoom] = useState(emptyRoom)
  const [submitting, setSubmitting] = useState(false)

  const selectableBuildings = room.campus ? buildings.filter(b => b.campusID === room.campus.id) : []

  const emptyError = {
    hasError: false,
    message: ''
  }

  const getEmptyErrors = (r = room) => {
    return {
      name: emptyError,
      tags: emptyError,
      number: emptyError,
      occupancy: emptyError,
      campus: emptyError,
      building: emptyError,
      floor: emptyError,
    }
  }

  const [errors, setErrors] = useState(getEmptyErrors())

  const formattedRooms = rooms.map(r => {
    let campus = campuses.find(c => c.id === r.campusID)
    let building = buildings.find(b => b.id === r.buildingID)

    let item = {
      ...r,
      campusName: campus ? campus.name : '',
      buildingName: building ? building.name : '',
      tagsIcon: (
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          {(r.tags.length > 0) && (
            <div title={r.tags.join('\n')} style={{ cursor: 'pointer', color: '#3695d7', display: 'flex', alignItems: 'center' }}>
              <LocalOffer style={{ fontSize: '22px' }} />
            </div>
          )}
        </div>
      ),
      actions: (
        <React.Fragment>
          <Button
            style={{ margin: '2px' }}
            justIcon
            color="primary"
            onClick={() => {
              let room = {
                ...r,
                campus: campus,
                building: building
              }
              setRoom(room)
              setErrors(getEmptyErrors(r))
              setToggleForm(true)
            }}
            title='Edit'
          >
            <Edit />
          </Button>

          <Button
            style={{ margin: '2px' }}
            justIcon
            color="danger"
            onClick={async () => {
              if (confirm(`Delete Room ${r.name}`)) {
                await deleteRoom({ id: r.id })
                let temp = await getRooms()
                setRooms(temp)
              }
            }}
            title='Delete'
          >
            <Delete />
          </Button>
        </React.Fragment>
      )
    }
    return item
  })

  const cols = [
    {
      Header: "Name",
      accessor: "name",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "tagsIcon",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '22px', padding: '0', fontSize: '12px' },
      headerStyle: { maxWidth: '22px', padding: '0', margin: '0px' }
    },
    {
      Header: "Number",
      accessor: "number",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Occupancy",
      accessor: "occupancy",
      disableFilters: false,
      disableSortBy: true,
      style: { maxWidth: '100px' },
      headerStyle: { maxWidth: '100px' }
    },
    {
      Header: "Campus",
      accessor: "campusName",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Building",
      accessor: "buildingName",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '54px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }
    },
  ]

  const setTag = (v) => {
    setRoom((prevState) => {
      return update(prevState, {
        tags: { $push: [v] }
      })
    })
  }
  const setTags = (v) => {
    setRoom((prevState) => {
      return update(prevState, {
        tags: { $set: v }
      })
    })
  }

  const isFormValid = () => {
    setErrors(getEmptyErrors())
    let valid = true

    if (room.name.trim() === '') {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          name: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    }

    if (room.number.trim() === '') {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          number: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    }
    // this shouldn't matter anymore
    // if (rooms.find(r => r.name.toLowerCase() === room.name.toLowerCase() && r.number === room.number && room.id !== r.id)) {
    //   valid = false
    //   setErrors((prev) => {
    //     return update(prev, {
    //       name: {
    //         $merge: {
    //           hasError: true,
    //           message: '*Room name & number already exists'
    //         }
    //       }
    //     })
    //   })
    //   setErrors((prev) => {
    //     return update(prev, {
    //       number: {
    //         $merge: {
    //           hasError: true,
    //           message: '*Room name & number already exists'
    //         }
    //       }
    //     })
    //   })
    // }

    if (!room.campus) {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          campus: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    }

    if (!room.building) {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          building: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    }

    if (!room.occupancy) {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          occupancy: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    } else {
      if (room.occupancy < 0) {
        valid = false
        setErrors((prev) => {
          return update(prev, {
            occupancy: {
              $merge: {
                hasError: true,
                message: '*Cannot be negative'
              }
            }
          })
        })
      }
    }

    return valid
  }

  const submitForm = async () => {
    if (isFormValid()) {
      setSubmitting(true)
      let r = {
        name: room.name,
        number: room.number,
        tags: room.tags,
        campusID: room.campus.id,
        buildingID: room.building.id,
        occupancy: room.occupancy,
        floor: room.floor
      }

      if (room.id) {
        r.id = room.id
        await updateRoom(r)
      } else {
        let roomID = await createRoom(r)
        r.id = roomID
      }

      let temp = await getRooms()
      setRooms(temp)
      setRoom(emptyRoom)
      setErrors(getEmptyErrors(emptyRoom))
      setSubmitting(false)
    }
  }


  return (
    <React.Fragment>
      <div style={{ textAlign: 'right' }}>
        {!toggleForm && (
          <Button
            color='info'
            onClick={(e) => {
              e.preventDefault()
              setToggleForm(true)
            }}
            justIcon
            title='Create New'
          >
            <Add />
          </Button>
        )}
        {toggleForm && (
          <Button
            color='danger'
            onClick={(e) => {
              e.preventDefault()
              setRoom(emptyRoom)
              setErrors(getEmptyErrors(emptyRoom))
              setToggleForm(false)
            }}
            justIcon
            title='Close'
          >
            <Close />
          </Button>
        )}
      </div>
      <div style={{
        height: toggleForm ? `350px` : '0px',
        opacity: toggleForm ? 1 : 0,
        transition: 'all 2000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
        position: 'relative',
        zIndex: 1,
        // overflow: 'auto'
      }}>
        {toggleForm && (
          <>
            {!submitting ?
              <div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div style={{ margin: '5px', flex: 1, minWidth: '225px' }}>
                    <Autocomplete
                      disablePortal
                      options={campuses}
                      disabled={room.id ? true : false}
                      value={room.campus}
                      ListboxProps={
                        {
                          style: {
                            maxHeight: '290px',
                          }
                        }
                      }
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option) => room.campus.id === option.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Campus"
                          variant="outlined"
                          error={errors.campus.hasError}
                          helperText={errors.campus.message}
                        />
                      )}
                      fullWidth
                      onChange={(e, v) => {
                        setRoom(prev => {
                          return update(prev, {
                            $merge: { campus: v, building: null, floor: '1' }
                          })
                        })
                      }}
                      autoSelect={true}
                      autoComplete={true}
                      autoHighlight={true}
                      disableClearable
                    />
                  </div>
                  <div style={{ margin: '5px', flex: 1, minWidth: '225px' }}>
                    <Autocomplete
                      disablePortal
                      options={selectableBuildings}
                      value={room.building}
                      disabled={room.id ? true : false}
                      ListboxProps={
                        {
                          style: {
                            maxHeight: '290px',
                          }
                        }
                      }
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option) => room.building.id === option.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Building"
                          variant="outlined"
                          error={errors.building.hasError}
                          helperText={errors.building.message}
                        />
                      )}
                      fullWidth
                      onChange={(e, v) => {
                        setRoom(prev => {
                          return update(prev, {
                            $merge: { building: v, floor: '1' }
                          })
                        })
                      }}
                      autoSelect={true}
                      autoComplete={true}
                      autoHighlight={true}
                      disableClearable
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <TextField
                    style={{ margin: '5px', flex: 1, minWidth: '225px' }}
                    label='Name'
                    required
                    fullWidth
                    variant='outlined'
                    error={errors.name.hasError}
                    helperText={errors.name.message}
                    value={room.name}
                    onChange={(e) => setRoom(prev => {
                      return update(prev, {
                        name: { $set: e.target.value }
                      })
                    })}
                  />
                  <TextField
                    style={{ margin: '5px', flex: 1, minWidth: '225px' }}
                    label='Number'
                    fullWidth
                    required
                    variant='outlined'
                    value={room.number}
                    error={errors.number.hasError}
                    helperText={errors.number.message}
                    onChange={(e) => setRoom(prev => {
                      return update(prev, {
                        number: { $set: e.target.value }
                      })
                    })}
                  />
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div style={{ margin: '5px', flex: 1, minWidth: '225px' }}>
                    {/*[...Array(building.floors).keys()].map(i => (i + 1).toString())*/}
                    <Autocomplete
                      disablePortal
                      options={room.building ? Array.from({ length: room.building.floors }, (_, i) => (i + 1).toString()) : []}
                      value={room.floor}
                      ListboxProps={
                        {
                          style: {
                            maxHeight: '290px',
                          }
                        }
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Floor"
                          variant="outlined"
                          error={errors.floor.hasError}
                          helperText={errors.floor.message}
                        />
                      )}
                      fullWidth
                      onChange={(e, v) => {
                        setRoom(prev => {
                          return update(prev, {
                            floor: { $set: v }
                          })
                        })
                      }}
                      autoSelect={true}
                      autoComplete={true}
                      autoHighlight={true}
                      disableClearable
                    />
                  </div>
                  <TextField
                    style={{ margin: '5px', flex: 1, minWidth: '225px' }}
                    label='Occupancy'
                    required
                    fullWidth
                    type="number"
                    variant='outlined'
                    error={errors.occupancy.hasError}
                    helperText={errors.occupancy.message}
                    value={room.occupancy}
                    onChange={(e) => {
                      let val = e.target.value
                      if (val) {
                        val = Math.round(val)
                        if (val < 0) {
                          val = 0
                        }
                      }
                      setRoom(prev => {
                        return update(prev, {
                          occupancy: { $set: val }
                        })
                      })
                    }
                    }
                  />
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div style={{ margin: '5px', flex: 1, minWidth: '225px' }}>
                    <Tags
                      tags={room.tags}
                      setTag={setTag}
                      setTags={setTags}
                      variant={'outlined'}
                      label={'Amenities'}
                      error={errors.tags.hasError}
                      helperText={errors.tags.message}
                    />
                  </div>
                </div>
                <div style={{ margin: '5px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                  <Button
                    style={{ margin: '20px 0px' }}
                    color='primary'
                    title='Submit'
                    onClick={(e) => {
                      e.preventDefault()
                      submitForm()
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
              :
              <Loading color='blue' />
            }
          </>
        )}
      </div>
      <div style={{
        backgroundColor: 'white',
        position: 'relative',
        zIndex: 2
      }}>
        <ReactTable
          columns={cols}
          data={formattedRooms}
          minWidthTable={'600px'}
        />
      </div>
    </React.Fragment>
  )
}

export default Rooms