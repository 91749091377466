import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import update from "immutability-helper";
import { HuePicker } from "react-color";
import {
  calendarListAtom,
  calendarSettingsFamily,
  saveCalendarSettingsSelector,
} from "state/settings";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { userAtom } from "state/auth";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import Loading from "components/Loading/Loading";

const useFormStyles = makeStyles(formStyles);

const specialStyles = makeStyles({
  calendarGroup: {
    border: "1px solid #ddd",
    marginBottom: "15px",
    borderRadius: "3px",
    background: "#fafafa",
    padding: "0",
    paddingRight: "50px",
    paddingBottom: "30px",
  },
});

const CalendarSettingsFormComponent = ({ calendarIndex, removeTabHandler }) => {
  const formClasses = useFormStyles();
  const specialClasses = specialStyles();
  const history = useHistory();
  const user = useRecoilValue(userAtom);
  const calendarList = useRecoilValue(calendarListAtom);
  const categories = [...(new Set(calendarList.map(c => c.category)))]
  const calTypes = [...(new Set(calendarList.map(c => c.type)))]
  const userCalendarSettings = useRecoilValue(calendarSettingsFamily(calendarIndex))

  const userCalendarSettingsFormatted = userCalendarSettings.calendars.map(c => {
    let newCal = {
      ...c,
      category: null,
      type: null,
      summary: c.id
    }
    const selectedCalendar = calendarList.find(calendar => calendar.id === c.id)
    if (selectedCalendar) {
      newCal.category = selectedCalendar.category
      newCal.type = selectedCalendar.type
      newCal.summary = selectedCalendar.summary
    }

    return newCal
  })

  const [calendarSettings, setCalendarSettings] = useState({
    title: userCalendarSettings.title,
    calendars: userCalendarSettingsFormatted
  })


  const getEmptyErrors = (settings = calendarSettings) => {
    return {
      title: { hasError: false, message: '' },
      calendars: settings.calendars.map(c => { return { hasError: false, message: '' } })
    }
  }

  const [errors, setErrors] = useState(getEmptyErrors(calendarSettings))


  const saveCalendarSettings = useSetRecoilState(
    saveCalendarSettingsSelector(calendarIndex)
  );

  const addCalendarHandler = (e) => {
    setErrors((prevState) => {
      return update(prevState, {
        calendars: {
          $push: [{ hasError: false, message: '' }]
        }
      })
    })
    setCalendarSettings((prevState) => {
      return update(prevState, {
        calendars: {
          $push: [
            {
              index: prevState.calendars.length,
              id: null,
              category: null,
              type: null,
              eventColor: "#ff0000",
            },
          ],
        },
      });
    });
  };

  const validateForm = () => {
    setErrors(getEmptyErrors())
    let valid = true

    if (calendarSettings.title.trim() === '') {
      valid = false
      setErrors(prevState => {
        return update(prevState, {
          title: {
            $set: {
              hasError: true,
              message: `*Required`
            }
          }
        })
      })
    }

    calendarSettings.calendars.forEach((cal, index) => {
      if (!cal.id) {
        valid = false
        setErrors(prevState => {
          return update(prevState, {
            calendars: {
              [index]: {
                $set: {
                  hasError: true,
                  message: `*Required`
                }
              }
            }
          })
        })
      }
    })
    return valid
  }

  const submitHandler = () => {
    if (validateForm()) {
      saveCalendarSettings({
        calendarIndex: 0,
        newCalendars: calendarSettings,
      });
      history.push("/portal/dashboard");
    }
  };

  const titleChangeHandler = (e) => {
    setCalendarSettings((prevState) => {
      return update(prevState, {
        $merge: { title: e.target.value },
      });
    });
  };

  const removeCalendarGroupHandler = (index) => {
    if (calendarSettings.calendars.length > 1) {
      setCalendarSettings((prevState) => {
        return update(prevState, {
          calendars: { $splice: [[index, 1]] },
        });
      });
      setErrors((prevState) => {
        return update(prevState, {
          calendars: { $splice: [[index, 1]] },
        })
      })
    } else {
      alert("You cannot remove all calendars from a tab.");
    }
  };

  const calendarSelectHandler = (updateData, index) => {
    setCalendarSettings((prevState) => {
      return update(prevState, {
        calendars: { [index]: { $merge: updateData } },
      });
    });
  };

  const eventColorChangeHandler = (color, index) => {
    setCalendarSettings((prevState) => {
      return update(prevState, {
        calendars: { [index]: { $merge: { eventColor: color.hex } } },
      });
    });
  };

  return (
    <React.Fragment>
      <form>
        <GridContainer>
          <GridItem xs={12}>
            <TextField
              style={{ margin: '15px 0px' }}
              label='Tab Name'
              required
              fullWidth
              variant='outlined'
              error={errors.title.hasError}
              helperText={errors.title.message}
              value={calendarSettings.title}
              onChange={(e) => titleChangeHandler(e)}
            />
          </GridItem>
        </GridContainer>
        {calendarSettings &&
          calendarSettings.calendars.map((calendar, index) => {
            const filteredCalendars = calendarList.filter(c => c.category === calendar.category && c.type === calendar.type)
            let selectedCalendar = filteredCalendars.find(c => c.id === calendar.id)

            return (
              <React.Fragment key={index}>
                <div className={specialClasses.calendarGroup}>
                  <GridContainer
                    style={{ justifyContent: "flex-end", marginLeft: "30px" }}
                  >
                    <GridItem>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => removeCalendarGroupHandler(index)}
                      >
                        REMOVE
                      </Button>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12}>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div
                          style={{ margin: '10px 5px', flex: 1, minWidth: '225px' }}
                        >
                          <Autocomplete
                            disableClearable
                            options={categories}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Category"
                                variant="outlined"
                              />
                            )}
                            fullWidth
                            autoSelect={true}
                            autoComplete={true}
                            autoHighlight={true}
                            value={calendar.category}
                            onChange={(e, v) => calendarSelectHandler({ category: v, id: null, type: 'Detailed' }, index)}
                          />
                        </div>
                        <div
                          style={{ margin: '10px 5px', flex: 1, minWidth: '225px' }}
                        >
                          <Autocomplete
                            disableClearable
                            options={calTypes}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Type"
                                variant="outlined"
                              />
                            )}
                            fullWidth
                            autoSelect={true}
                            autoComplete={true}
                            autoHighlight={true}
                            value={calendar.type}
                            onChange={(e, v) => calendarSelectHandler({ type: v, id: null }, index)}
                          />
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div
                          style={{ margin: '10px 5px', flex: 1, minWidth: '225px' }}
                        >
                          <Autocomplete
                            disableClearable
                            getOptionSelected={(option) => {
                              return option.id === calendar.id
                            }}
                            getOptionLabel={(option) => option.summary}
                            options={filteredCalendars}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                label="Name"
                                variant="outlined"
                                error={errors.calendars[index].hasError}
                                helperText={errors.calendars[index].message}
                              />
                            )}
                            fullWidth
                            autoSelect={true}
                            autoComplete={true}
                            autoHighlight={true}
                            value={selectedCalendar ? selectedCalendar : null}
                            onChange={(e, v) => calendarSelectHandler({ id: v.id }, index)}
                          />
                        </div>
                      </div>
                    </GridItem>

                  </GridContainer>
                  {!(calendar.category === 'Campuses' && calendar.type === 'Overview') && (
                    <GridContainer style={{ alignItems: "flex-end" }}>
                      <GridItem xs={12} sm={1}>
                        <FormLabel className={formClasses.labelHorizontal}>
                          Event Color
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={11} style={{ marginBottom: "4px" }}>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "bottom",
                          }}
                        >
                          <HuePicker
                            color={calendar.eventColor}
                            onChange={(color) =>
                              eventColorChangeHandler(color, index)
                            }
                          />
                        </div>

                        <span
                          style={{
                            backgroundColor: calendar.eventColor,
                            display: "inline-block",
                            padding: "3px",
                            borderRadius: "3px",
                            fontSize: "smaller",
                            color: "#fff",
                            marginLeft: "10px",
                          }}
                        >
                          Example Event
                        </span>
                      </GridItem>
                    </GridContainer>)}
                </div>
              </React.Fragment>
            );
          })}
        <GridContainer>
          <GridItem xs={12}>
            <Button onClick={addCalendarHandler}>Add Another Calendar</Button>
          </GridItem>
        </GridContainer>
      </form>
      <GridContainer
        style={{
          marginTop: "25px",
          paddingTop: "15px",
          borderTop: "1px solid #eee",
        }}
      >
        <GridItem xs={12} style={{ textAlign: "left" }}>
          <Button color="info" onClick={submitHandler}>
            Save Settings
          </Button>
          <Button color="danger" onClick={removeTabHandler}>
            Remove Tab
          </Button>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

const CalendarSettingsForm = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <CalendarSettingsFormComponent {...props} />
    </React.Suspense>
  );
};

export default CalendarSettingsForm;
