import React, { useState } from 'react'

import { Checkbox, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "components/CustomButtons/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { makeStyles } from "@material-ui/core/styles";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import update from 'immutability-helper'
import {
  Info,
  NavigateNext,
  ExpandMore,
  AccessTime,
  Block,
  EventBusy,
  PriorityHigh,
  AssignmentLate
} from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  ...styles,
  ...dashboardStyles,
  formSection: {
    marginBottom: "1rem",
  },
}));

const ResourceReservationSelection = ({ resources, reservations, addRemoveResource, dropdownDefault = true }) => {

  const classes = useStyles()

  const resourceTags = [...(new Set(resources.map(r => r.tags).flat(1)))]
  const resourceTypes = [...(new Set(resources.map(r => r.type).flat(1)))]
  const [resourceTypeToggles, setResourceTypeToggles] = useState(Array(resourceTypes.length).fill(false))

  const emptyResourceFilter = {
    tags: [],
    search: ''
  }

  const [toggleResourceFilter, setToggleResourceFilter] = useState(true)
  const [resourceFilter, setResourceFilter] = useState(emptyResourceFilter)
  const [resourceToggle, setResourceToggle] = useState(dropdownDefault)

  const findIndexOfReservation = (forType, forID) => {
    return reservations.findIndex(r => forType === r.forType && forID === r.forID)
  }

  const filteredResources = resources.filter(r => {
    let bool = true

    if (resourceFilter.tags.length > 0) {
      bool = bool && resourceFilter.tags.every(t => r.tags.includes(t))
    }

    bool = bool && r.search.includes(resourceFilter.search.toLowerCase())

    return bool
  })

  const resourceForDisplay = resourceTypes.map(rType => {
    return {
      type: rType,
      resources: filteredResources.filter(r => r.type === rType),
      selected: resources.filter(r => r.type === rType && findIndexOfReservation('resource', r.id) !== -1).length
    }
  })

  const updateResourceFilter = (updateData) => {
    setResourceFilter(prev => {
      return update(prev, updateData)
    })
  }

  return (
    <>
      <Button
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '5px 5px 5px 10px' }}
        color='info'
        onClick={() => setResourceToggle(!resourceToggle)}
      >
        <span style={{ marginRight: '10px', marginBottom: '-3px' }}>Resource Reservations</span>
        {resourceToggle ? <ExpandMore style={{ margin: '0px' }} /> : <NavigateNext style={{ margin: '0px' }} />}
      </Button>
      <div style={{
        // minHeight: resourceToggle ? `${32 * (buildings.length + 1)}px` : '0px',
        // maxHeight: resourceToggle ? `${(115 * (buildings.length + 1)) + 22 * (buildings.map(i => i.floors).reduce((accumulator, currentValue) => { return accumulator + currentValue }, 0)) + rooms.length * 90 + 500}px` : '0px',
        // opacity: resourceToggle ? 1 : 0,
        // transition: 'all 2000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
      }}>
        {(resourceToggle) && (
          <div
            style={{
              borderRadius: "4px",
              background: "#eee",
              padding: "5px 10px",
              border: "1px solid #c4c4c4",
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <div>
              <Button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  padding: '5px 5px 5px 10px',
                  color: '#393939',
                  backgroundColor: '#fff',
                  border: '1px solid #3695d7',
                  margin: '5px 0px'
                }}
                onClick={() => setToggleResourceFilter(!toggleResourceFilter)}
              >
                <span style={{ marginRight: '10px', marginBottom: '-3px', fontSize: '16px', fontWeight: 'bold' }}>Filters</span>
                {toggleResourceFilter ? <ExpandMore style={{ margin: '0px' }} /> : <NavigateNext style={{ margin: '0px' }} />}
              </Button>
              <div
                style={{
                  borderRadius: "4px",
                  background: "#fff",
                  padding: toggleResourceFilter ? "5px" : '0px',
                  border: toggleResourceFilter ? "1px solid #c4c4c4" : '',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  // minHeight: toggleResourceFilter ? `164px` : '0px',
                  // maxHeight: toggleResourceFilter ? `500px` : '0px',
                  // opacity: toggleResourceFilter ? 1 : 0,
                  transition: 'all 1000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
                  width: '100%',
                  margin: '5px 0px'
                }}
              >
                {(toggleResourceFilter) && (<>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                      style={{ flex: 1, margin: '10px 5px', minWidth: '200px' }}
                      label='Search'
                      fullWidth
                      variant='outlined'
                      value={resourceFilter.search}
                      onChange={(e) => updateResourceFilter({ search: { $set: e.target.value } })}
                    />
                    <div style={{ margin: '10px 5px', flex: 1, minWidth: '200px' }}>
                      <Autocomplete
                        disablePortal
                        multiple
                        options={resourceTags}
                        value={resourceFilter.tags}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tags"
                            variant="outlined"
                          />
                        )}
                        fullWidth
                        onChange={(e, v) => updateResourceFilter({ tags: { $set: v } })}
                        autoSelect={true}
                        autoComplete={true}
                        autoHighlight={true}
                      />
                    </div>
                  </div>
                </>)}
              </div>
            </div>
            {resourceForDisplay.map((type, index) => {
              return (
                <div
                  key={index}
                >
                  {type.type.length > 0 && (
                    <>
                      <Button
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                          padding: '5px 5px 5px 10px',
                          color: '#393939',
                          backgroundColor: '#fff',
                          border: '1px solid #3695d7',
                          margin: '5px 0px'
                        }}
                        onClick={() => setResourceTypeToggles(prev => {
                          return update(prev, {
                            [index]: { $set: !resourceTypeToggles[index] }
                          })
                        })}
                      >
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '-3px', marginRight: '10px' }}>
                          <span style={{ marginRight: '5px', fontSize: '16px', fontWeight: 'bold' }}>{type.type}</span>
                          <span style={{ textTransform: 'none' }}>{type.selected} Selected</span>
                        </div>
                        {resourceTypeToggles[index] ? <ExpandMore style={{ margin: '0px' }} /> : <NavigateNext style={{ margin: '0px' }} />}
                      </Button>
                      <div
                        style={{
                          borderRadius: "4px",
                          background: "#fff",
                          padding: resourceTypeToggles[index] ? "5px" : '0px',
                          border: resourceTypeToggles[index] ? "1px solid #c4c4c4" : '',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          // maxHeight: resourceTypeToggles[index] ? `${115 + 22 * b.building.floors + b.rooms.length * 90}px` : '0px',
                          // minHeight: resourceTypeToggles[index] ? `${15 + 22 + 77}px` : '0px',
                          opacity: resourceTypeToggles[index] ? 1 : 0,
                          transition: 'all 1000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
                          width: '100%',
                          margin: '5px 0px'
                        }}
                      >
                        {(resourceTypeToggles[index]) && (
                          <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0px 5px' }}>
                            {type.resources.map((r, index) => {
                              return (
                                <div
                                  style={{
                                    margin: '6px 0px 2px 0px',
                                    padding: '5px 7px 5px 10px',
                                    backgroundColor: '#fff',
                                    border: '1px solid #bbb',
                                    color: '#393939',
                                    textTransform: 'none',
                                    borderRadius: '6px',
                                    display: 'flex',
                                    flex: 1
                                  }}
                                >
                                  {r.name}
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )
            })}
          </div>
        )}

      </div>
    </>
  )
}

export default ResourceReservationSelection