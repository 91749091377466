import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import Loading from "components/Loading/Loading";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import update from "immutability-helper";
import { Add, Close, Edit, DateRange, Delete, LocalOffer } from "@material-ui/icons";
import {
  timezonesAtom,
  getCampuses,
  createCampus,
  updateCampus,
  deleteCampus,
  setOpenHours,
  sortDaysOfWeek
} from "state/events";
import Tags from "components/Tags/Tags";
import './RoomManager.css'
import HelperText from "components/HelperText/HelperText";

const Campuses = ({ campuses, setCampuses, handleDeleteUpdate }) => {
  const timezones = useRecoilValue(timezonesAtom)

  const [toggleForm, setToggleForm] = useState(false)
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]
  const emptyTimeSlot = {
    start: '',
    end: ''
  }
  const emptyOpenHour = {
    type: 'normal',
    days: [],
    startDate: '',
    endDate: '',
    name: '',
    timeslots: []
  }
  const emptyCampus = {
    name: '',
    location: '',
    tags: [],
    timezone: 'US/Mountain',
    files: [],
    openHours: [emptyOpenHour],
    abbreviation: ''
  }



  const [campus, setCampus] = useState(emptyCampus)
  const [submitting, setSubmitting] = useState(false)

  const usedDaysOfWeek = campus.openHours.map((oh) => oh.days).flat(1)

  const emptyError = {
    hasError: false,
    message: ''
  }

  const emptyOpenHoursError = {
    type: emptyError,
    days: emptyError,
    startDate: emptyError,
    endDate: emptyError,
    timeslots: []
  }

  const getEmptyErrors = (c = campus) => {
    return {
      name: emptyError,
      location: emptyError,
      timezone: emptyError,
      tags: emptyError,
      abbreviation: emptyError,
      openHours: c.openHours.map((oh) => {
        let ohErrors = {
          type: emptyError,
          days: emptyError,
          startDate: emptyError,
          endDate: emptyError,
          timeslots: oh.timeslots.map(ts => emptyError)
        }
        return ohErrors
      })
    }
  }

  const [errors, setErrors] = useState(getEmptyErrors())

  const formatedCampuses = campuses.map(c => {
    let normal = c.openHours.filter(i => i.type === 'normal').map(oh => {
      return {
        ...oh,
        days: oh.days.map(d => {
          if (d === 'Thursday') {
            return 'Th'
          } else {
            return d[0]
          }
        }).join(','),
        timeslots: oh.timeslots.length > 0 ? oh.timeslots.map(ts => `${ts.start}-${ts.end}`) : ['Closed']
      }
    })
    let special = c.openHours.filter(i => i.type === 'special').map(oh => {
      let start = oh.startDate.split('-')
      let end = oh.endDate.split('-')
      return {
        ...oh,
        date: oh.startDate === oh.endDate ? `${start[1]}/${start[2]}/${start[0]}` : `${start[1]}/${start[2]}/${start[0]} - ${end[1]}/${end[2]}/${end[0]}`,
        timeslots: oh.timeslots.length > 0 ? oh.timeslots.map(ts => `${ts.start}-${ts.end}`) : ['Closed']
      }
    })
    let specialTitle = ''
    special.forEach((oh, idx) => {
      if (idx !== 0) {
        specialTitle += '\n'
      }
      specialTitle += `${oh.date}   ${oh.timeslots.join(', ')}`
    })
    let item = {
      ...c,
      tagsIcon: (
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          {(c.tags.length > 0) && (
            <div title={c.tags.join('\n')} style={{ cursor: 'pointer', color: '#3695d7', display: 'flex', alignItems: 'center' }}>
              <LocalOffer style={{ fontSize: '22px' }} />
            </div>
          )}
        </div>
      ),
      schedule: (
        <React.Fragment>
          {(c.openHours.length > 0) && (
            <div style={{ display: 'flex' }}>
              <div>
                {normal.map(oh => {
                  return (
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }} key={oh._id}>
                      <span style={{ marginRight: '5px' }}>{oh.days}</span>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {oh.timeslots.map((ts, idx) => {
                          return (<span key={idx}>{ts}</span>)
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
              {(special.length > 0) && (
                <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', marginLeft: '5px' }}>
                  <div title={specialTitle} style={{ cursor: 'pointer', color: '#3695d7', display: 'flex', alignItems: 'center' }}>
                    <DateRange style={{ fontSize: '22px' }} />
                  </div>
                </div>
              )}
            </div>
          )}
          {(c.openHours.length === 0) && (
            <span>Closed</span>
          )}
        </React.Fragment>
      ),
      actions: (
        <React.Fragment>
          <Button
            style={{ margin: '2px' }}
            justIcon
            color="primary"
            onClick={() => {
              setCampus(c)
              setErrors(getEmptyErrors(c))
              setToggleForm(true)
            }}
            title='Edit'
          >
            <Edit />
          </Button>
          {/* <Button
            style={{ margin: '2px' }}
            justIcon
            color="primary"
            onClick={() => {
              // setCampus(c)
              // toggleForm(true)
            }}
            title='Attached Files'
          >
            {c.files ? <Folder /> : <FolderOpen />}
          </Button> */}
          <Button
            style={{ margin: '2px' }}
            justIcon
            color="danger"
            onClick={async () => {
              if (confirm(`Delete Campus ${c.name}`)) {
                await deleteCampus({ id: c.id })
                let temp = await getCampuses()
                setCampuses(temp)
                handleDeleteUpdate()
              }
            }}
            title='Delete'
          >
            <Delete />
          </Button>
        </React.Fragment>
      )
    }
    return item
  })

  const cols = [
    {
      Header: "Name",
      accessor: "name",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "tagsIcon",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '22px', padding: '0', fontSize: '12px' },
      headerStyle: { maxWidth: '22px', padding: '0', margin: '0px' }
    },
    {
      Header: "Location",
      accessor: "location",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Timezone",
      accessor: "timezone",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Open Hours",
      accessor: "schedule",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '54px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }
    },
  ]

  const setTag = (v) => {
    setCampus((prevState) => {
      return update(prevState, {
        tags: { $push: [v] }
      })
    })
  }
  const setTags = (v) => {
    setCampus((prevState) => {
      return update(prevState, {
        tags: { $set: v }
      })
    })
  }

  const isFormValid = () => {
    setErrors(getEmptyErrors())
    let valid = true

    if (campus.name.trim() === '') {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          name: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    }

    // this is because room names must be unique on google
    if (campuses.find(c => c.name.toLowerCase() === campus.name.toLowerCase() && campus.id !== c.id)) {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          name: {
            $merge: {
              hasError: true,
              message: '*Campus name already exists'
            }
          }
        })
      })
    }

    if (campus.location.trim() === '') {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          location: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    }

    if (campus.abbreviation.trim() === '') {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          abbreviation: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    }

    if (!campus.timezone) {
      valid = false
      setErrors((prev) => {
        return update(prev, {
          timezone: {
            $merge: {
              hasError: true,
              message: '*Required'
            }
          }
        })
      })
    }

    campus.openHours.forEach((oh, index) => {
      if (!oh.type) {
        valid = false
        setErrors((prev) => {
          return update(prev, {
            openHours: {
              [index]: {
                type: {
                  $merge: {
                    hasError: true,
                    message: '*Required'
                  }
                }
              }
            }
          })
        })
      } else {
        if (oh.type == 'normal') {
          if (oh.days.length === 0) {
            valid = false
            setErrors((prev) => {
              return update(prev, {
                openHours: {
                  [index]: {
                    days: {
                      $merge: {
                        hasError: true,
                        message: '*Required'
                      }
                    }
                  }
                }
              })
            })
          }
        }
        if (oh.type === 'special') {
          if (!oh.startDate) {
            valid = false
            setErrors((prev) => {
              return update(prev, {
                openHours: {
                  [index]: {
                    startDate: {
                      $merge: {
                        hasError: true,
                        message: '*Required'
                      }
                    }
                  }
                }
              })
            })
          }
          if (!oh.endDate) {
            valid = false
            setErrors((prev) => {
              return update(prev, {
                openHours: {
                  [index]: {
                    endDate: {
                      $merge: {
                        hasError: true,
                        message: '*Required'
                      }
                    }
                  }
                }
              })
            })
          }
          if (oh.startDate && oh.endDate) {
            if (oh.startDate >= oh.endDate) {
              setErrors((prev) => {
                return update(prev, {
                  openHours: {
                    [index]: {
                      endDate: {
                        $merge: {
                          hasError: true,
                          message: '*End must be greater than start'
                        }
                      }
                    }
                  }
                })
              })
            }
          }
        }
      }

      oh.timeslots.forEach((ts, tsIdx) => {
        if (oh.timeslots.find((i, iIDX) => ((i.start <= ts.start && i.end >= ts.end) || (i.start <= ts.end && i.end >= ts.end)) && iIDX !== tsIdx)) {
          valid = false
          setErrors((prev) => {
            return update(prev, {
              openHours: {
                [index]: {
                  timeslots: {
                    [tsIdx]: {
                      $merge: {
                        hasError: true,
                        message: "*Timeslots can't overlap."
                      }
                    }
                  }
                }
              }
            })
          })
        }
        if (!ts.start && !ts.end) {
          valid = false
          setErrors((prev) => {
            return update(prev, {
              openHours: {
                [index]: {
                  timeslots: {
                    [tsIdx]: {
                      $merge: {
                        hasError: true,
                        message: '*Required'
                      }
                    }
                  }
                }
              }
            })
          })
        } if (!ts.start) {
          valid = false
          setErrors((prev) => {
            return update(prev, {
              openHours: {
                [index]: {
                  timeslots: {
                    [tsIdx]: {
                      $merge: {
                        hasError: true,
                        message: '*Start cannot be empty'
                      }
                    }
                  }
                }
              }
            })
          })
        }
        if (!ts.end) {
          valid = false
          setErrors((prev) => {
            return update(prev, {
              openHours: {
                [index]: {
                  timeslots: {
                    [tsIdx]: {
                      $merge: {
                        hasError: true,
                        message: '*End cannot be empty'
                      }
                    }
                  }
                }
              }
            })
          })
        }
        if (ts.start !== '' && ts.end !== '') {
          if (ts.start >= ts.end) {
            valid = false
            setErrors((prev) => {
              return update(prev, {
                openHours: {
                  [index]: {
                    timeslots: {
                      [tsIdx]: {
                        $merge: {
                          hasError: true,
                          message: '*End must be greater than start'
                        }
                      }
                    }
                  }
                }
              })
            })
          }
        }
      })
    })
    return valid
  }

  const submitForm = async () => {
    if (isFormValid()) {
      setSubmitting(true)
      let c = {
        name: campus.name,
        location: campus.location,
        tags: campus.tags,
        timezone: campus.timezone,
        abbreviation: campus.abbreviation
      }

      if (campus.id) {
        c.id = campus.id
        await updateCampus(c)
      } else {
        let campusID = await createCampus(c)
        c.id = campusID
      }

      let openHours = campus.openHours.map(oh => {
        return {
          type: oh.type,
          days: sortDaysOfWeek(oh.days),
          startDate: oh.startDate,
          endDate: oh.endDate,
          forType: 'campus',
          forID: c.id,
          timeslots: oh.timeslots
        }
      })
      await setOpenHours({
        openHours: openHours,
        forType: 'campus',
        forID: c.id
      })
      let temp = await getCampuses()
      setCampuses(temp)
      setCampus(emptyCampus)
      setErrors(getEmptyErrors(emptyCampus))
      setSubmitting(false)
    }
  }

  const setOpenHoursType = (index, value) => {
    setCampus((prevState) => {
      return update(prevState, {
        openHours: { [index]: { type: { $set: value } } }
      })
    })
    if (value === 'normal') {
      setCampus((prevState) => {
        return update(prevState, {
          openHours: { [index]: { $merge: { startDate: '', endDate: '' } } }
        })
      })
    } else {
      setCampus((prevState) => {
        return update(prevState, {
          openHours: { [index]: { days: { $set: [] } } }
        })
      })
    }
  }

  const removeOpenHours = (index) => {
    setCampus(prev => {
      return update(prev, {
        openHours: { $splice: [[index, 1]] }
      })
    })

    setErrors(prev => {
      return update(prev, {
        openHours: { $splice: [[index, 1]] }
      })
    })
  }

  return (
    <React.Fragment>
      <div style={{ textAlign: 'right' }}>
        {!toggleForm && (
          <Button
            color='info'
            onClick={(e) => {
              e.preventDefault()
              setToggleForm(true)
            }}
            justIcon
            title='Create New'
          >
            <Add />
          </Button>
        )}
        {toggleForm && (
          <Button
            color='danger'
            onClick={(e) => {
              e.preventDefault()
              setCampus(emptyCampus)
              setErrors(getEmptyErrors(emptyCampus))
              setToggleForm(false)
            }}
            justIcon
            title='Close'
          >
            <Close />
          </Button>
        )}
      </div>
      <div style={{
        maxHeight: toggleForm ? `${(600 + (450 * campus.openHours.length))}px` : '0px',
        minHeight: toggleForm ? `${(200 + (150 * campus.openHours.length))}px` : '0px',
        opacity: toggleForm ? 1 : 0,
        transition: 'all 2000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
        position: 'relative',
        zIndex: 1,
        // overflow: 'auto'
      }}>
        {toggleForm && (
          <>
            {!submitting ?
              <div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <TextField
                    style={{ margin: '5px', flex: 1, minWidth: '225px' }}
                    label='Name'
                    required
                    fullWidth
                    variant='outlined'
                    error={errors.name.hasError}
                    helperText={errors.name.message}
                    value={campus.name}
                    onChange={(e) => setCampus(prev => {
                      return update(prev, {
                        name: { $set: e.target.value }
                      })
                    })}
                  />
                  <TextField
                    style={{ margin: '5px', flex: 1, minWidth: '225px' }}
                    label='Campus Abbreviation'
                    fullWidth
                    required
                    variant='outlined'
                    value={campus.abbreviation}
                    error={errors.abbreviation.hasError}
                    helperText={errors.abbreviation.message}
                    onChange={(e) => setCampus(prev => {
                      return update(prev, {
                        abbreviation: { $set: e.target.value.toUpperCase() }
                      })
                    })}
                  />
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div style={{ margin: '5px', flex: 1, minWidth: '225px' }}>
                    <Autocomplete
                      disablePortal
                      options={timezones}
                      value={campus.timezone}
                      ListboxProps={
                        {
                          style: {
                            maxHeight: '290px',
                          }
                        }
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Timezone"
                          variant="outlined"
                          error={errors.timezone.hasError}
                          helperText={errors.timezone.message}
                        />
                      )}
                      fullWidth
                      onChange={(e, v) => {
                        setCampus(prev => {
                          return update(prev, {
                            timezone: { $set: v }
                          })
                        })
                      }}
                      autoSelect={true}
                      autoComplete={true}
                      autoHighlight={true}
                      disableClearable
                    />
                  </div>
                  <TextField
                    style={{ margin: '5px', flex: 1, minWidth: '225px' }}
                    label='Location'
                    fullWidth
                    required
                    variant='outlined'
                    value={campus.location}
                    error={errors.location.hasError}
                    helperText={errors.location.message}
                    onChange={(e) => setCampus(prev => {
                      return update(prev, {
                        location: { $set: e.target.value }
                      })
                    })}
                  />
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div style={{ margin: '5px', flex: 1, minWidth: '225px' }}>
                    <Tags
                      tags={campus.tags}
                      setTag={setTag}
                      setTags={setTags}
                      variant={'outlined'}
                      label={'Tags'}
                      error={errors.tags.hasError}
                      helperText={errors.tags.message}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <span
                      style={{
                        margin: '0px 5px',
                        color: '#757575',
                        backgroundColor: '#fff',
                        fontSize: '12px',
                      }}
                    >
                      Open Hours
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    {campus.openHours.length > 0 ?
                      <>
                        {campus.openHours.map((item, index) => {
                          return (
                            <div style={{ display: 'flex' }} key={index}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  borderRadius: "3px",
                                  background: "#fff",
                                  padding: "10px 5px 0px 5px",
                                  border: "1px solid #c4c4c4",
                                  margin: index === 0 ? '0px 5px 5px' : '5px',
                                  flex: '1'
                                }}
                              >
                                <div style={{ margin: '0px 0px 5px 0px', flex: 1, minWidth: '225px', display: 'flex', flexDirection: 'column' }}>
                                  <div style={{ margin: '5px' }}>
                                    <Autocomplete
                                      disablePortal
                                      options={['normal', 'special']}
                                      value={item.type}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          required label="Type"
                                          variant="outlined"
                                          error={errors.openHours[index].type.hasError}
                                          helperText={errors.openHours[index].type.message}
                                        />
                                      )}
                                      fullWidth
                                      onChange={(e, v) => {
                                        setOpenHoursType(index, v)
                                      }}
                                      autoSelect={true}
                                      autoComplete={true}
                                      autoHighlight={true}
                                      disableClearable
                                    />
                                  </div>
                                  {(item.type === 'normal') && (
                                    <div style={{ margin: '5px' }}>
                                      <Autocomplete
                                        disablePortal
                                        multiple
                                        options={daysOfWeek.filter(d => (usedDaysOfWeek.filter(i => item.days.indexOf(i) === -1)).indexOf(d) === -1)}
                                        value={item.days}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            required
                                            label="Days"
                                            variant="outlined"
                                            error={errors.openHours[index].days.hasError}
                                            helperText={errors.openHours[index].days.message}
                                          />
                                        )}
                                        ListboxProps={
                                          {
                                            style: {
                                              maxHeight: '130px',
                                            }
                                          }
                                        }
                                        fullWidth
                                        onChange={(e, v) => {
                                          setCampus(prev => {
                                            return update(prev, {
                                              openHours: { [index]: { days: { $set: v } } }
                                            })
                                          })
                                        }}
                                        autoSelect={true}
                                        autoComplete={true}
                                        autoHighlight={true}
                                      // disableClearable
                                      />
                                    </div>
                                  )}
                                  {(item.type === 'special') && (
                                    <div style={{ flex: 1, minWidth: '225px' }}>
                                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <TextField
                                          variant="outlined"
                                          type="date"
                                          value={item.startDate}
                                          label='Start Date'
                                          error={errors.openHours[index].startDate.hasError}
                                          helperText={errors.openHours[index].startDate.message}
                                          required
                                          style={{ margin: '5px', flex: 1, minWidth: '160px' }}
                                          onChange={(e) => {
                                            setCampus(prev => {
                                              return update(prev, {
                                                openHours: { [index]: { startDate: { $set: e.target.value } } }
                                              })
                                            })
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                        />
                                        <TextField
                                          variant="outlined"
                                          type="date"
                                          value={item.endDate}
                                          label='End Date'
                                          required
                                          error={errors.openHours[index].endDate.hasError}
                                          helperText={errors.openHours[index].endDate.message}
                                          style={{ margin: '5px', flex: 1, minWidth: '160px' }}
                                          onChange={(e) => {
                                            setCampus(prev => {
                                              return update(prev, {
                                                openHours: { [index]: { endDate: { $set: e.target.value } } }
                                              })
                                            })
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div style={{ margin: '0px', flex: 1, minWidth: '225px' }}>
                                  <div style={{ margin: '-6px 0px 0px 15px' }}>
                                    <span style={{
                                      position: 'relative',
                                      margin: '0px',
                                      padding: '0px 5px',
                                      color: '#757575',
                                      backgroundColor: '#fff',
                                      zIndex: 2,
                                      fontSize: '12px'
                                    }}>
                                      Timeslots *
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      position: 'relative',
                                      borderRadius: "3px",
                                      background: "#fff",
                                      padding: "5px",
                                      border: "1px solid #c4c4c4",
                                      margin: '5px',
                                      marginBottom: '10px',
                                      zIndex: 1,
                                      marginTop: '-11px',
                                      display: 'flex',
                                      flexDirection: 'column'
                                    }}
                                  >
                                    {item.timeslots.length === 0 && (
                                      <div style={{
                                        display: 'flex',
                                        borderRadius: "3px",
                                        background: "#fff",
                                        padding: "5px",
                                        border: "1px solid #c4c4c4",
                                        margin: '5px',
                                        justifyContent: 'center'
                                      }}>
                                        <span>Closed</span>
                                      </div>
                                    )}
                                    {item.timeslots.map((slot, slotIndex) => {
                                      return (
                                        <div style={{ margin: '5px' }} key={slotIndex}>
                                          <div
                                            style={{
                                              display: 'flex',
                                              borderRadius: "3px",
                                              background: "#fff",
                                              padding: "0px 5px",
                                              border: errors.openHours[index].timeslots[slotIndex].hasError ? '1px solid #f44336' : "1px solid #c4c4c4",
                                            }}>
                                            <div style={{ display: 'flex', flexWrap: 'wrap', flex: 1, alignItems: 'center' }}>
                                              <TextField
                                                style={{ margin: '5px', flex: 1, minWidth: '105px' }}
                                                type="time"
                                                value={slot.start}
                                                fullWidth
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                onChange={(e) => {
                                                  setCampus(prev => {
                                                    return update(prev, {
                                                      openHours: { [index]: { timeslots: { [slotIndex]: { start: { $set: e.target.value } } } } }
                                                    })
                                                  })
                                                }}
                                              />
                                              <span style={{ margin: '5px' }}>to</span>
                                              <TextField
                                                value={slot.end}
                                                style={{ margin: '5px', flex: 1, minWidth: '105px' }}
                                                type="time"
                                                fullWidth
                                                onChange={(e) => {
                                                  setCampus(prev => {
                                                    return update(prev, {
                                                      openHours: { [index]: { timeslots: { [slotIndex]: { end: { $set: e.target.value } } } } }
                                                    })
                                                  })
                                                }}
                                              />
                                            </div>
                                            <div
                                              title="Remove Timeslot"
                                              className="closeButtonRoomManager"
                                              onClick={(e) => {
                                                e.preventDefault()
                                                setCampus(prev => {
                                                  return update(prev, {
                                                    openHours: { [index]: { timeslots: { $splice: [[slotIndex, 1]] } } }
                                                  })
                                                })

                                                setErrors(prev => {
                                                  return update(prev, {
                                                    openHours: { [index]: { timeslots: { $splice: [[slotIndex, 1]] } } }
                                                  })
                                                })
                                              }}
                                            >
                                              <Close />
                                            </div>
                                          </div>
                                          <HelperText error={errors.openHours[index].timeslots[slotIndex].hasError} helperText={errors.openHours[index].timeslots[slotIndex].message} />
                                        </div>
                                      )
                                    })}
                                    <div
                                      title='Add Timeslot'
                                      className="dashedAddButtonRoomManager"
                                      onClick={(e) => {
                                        e.preventDefault()
                                        setCampus(prev => {
                                          return update(prev, {
                                            openHours: { [index]: { timeslots: { $push: [emptyTimeSlot] } } }
                                          })
                                        })

                                        setErrors(prev => {
                                          return update(prev, {
                                            openHours: { [index]: { timeslots: { $push: [emptyError] } } }
                                          })
                                        })
                                      }}
                                    >
                                      <div style={{ display: 'flex', flexWrap: 'wrap', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                        <Add style={{ fontSize: '20px' }} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style={{
                                display: 'flex',
                                maxWidth: '42px',
                                alignItems: 'center'
                              }}>
                                <Button
                                  color='danger'
                                  style={{

                                  }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    removeOpenHours(index)
                                  }}
                                  justIcon
                                  title='Close'
                                >
                                  <Close />
                                </Button>
                              </div>
                            </div>
                          )
                        })}
                      </>
                      :
                      <></>
                    }
                    <div
                      title='Add Open Hours'
                      className="dashedAddButtonRoomManager"
                      onClick={(e) => {
                        e.preventDefault()
                        setCampus(prev => {
                          return update(prev, {
                            openHours: { $push: [emptyOpenHour] }
                          })
                        })
                        setErrors(prev => {
                          return update(prev, {
                            openHours: { $push: [emptyOpenHoursError] }
                          })
                        })
                      }}
                    >
                      <div style={{ display: 'flex', flexWrap: 'wrap', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        <Add style={{ fontSize: '20px' }} />
                      </div>
                    </div>

                  </div>

                </div>
                <div style={{ margin: '5px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                  <Button
                    style={{ margin: '20px 0px' }}
                    color='primary'
                    title='Submit'
                    onClick={(e) => {
                      e.preventDefault()
                      submitForm()
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
              :
              <Loading color='blue' />
            }
          </>
        )}
      </div>
      <div style={{
        backgroundColor: 'white',
        position: 'relative',
        zIndex: 2
      }}>
        <ReactTable
          columns={cols}
          data={formatedCampuses}
          minWidthTable={'600px'}
        />
      </div>
    </React.Fragment>
  )
}

export default Campuses