import { atom, selector, selectorFamily, atomFamily } from "recoil";
import axios from "axios";
import api from "../api.config";

const PORTAL_API = 'staff_portal_api'

export const calendarEventsByMonthAtom = atomFamily({
  key: 'calendarEventsByMonth',
  default: selectorFamily({
    id: 'calendarEventsByMonthSelector',
    get: ({ calendars, month, year }) => async () => {
      return await getCalendarEventsForMonth(calendars, month, year)
    }
  })
})

export const getCalendarEventsForMonth = async (calendars, month, year) => {
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/getCalendarsEventsByMonth`, {
      calendars: calendars,
      month: month,
      year: year
    })
    .then((res) => { return res.data });
}

export const calendarEventsForAtom = atomFamily({
  key: 'calendarEventsFor',
  default: selectorFamily({
    id: 'calendarEventsForSelector',
    get: ({ startDate, endDate, campusID, category, type, timezone }) => async () => {
      return await getcalendarEventsFor(startDate, endDate, campusID, category, type, timezone)
    }
  })
})

export const getcalendarEventsFor = async (startDate, endDate, campusID, category, type, timezone = null) => {
  if (!timezone) {
    timezone = 'US/Mountain'
  }
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/requestCalendarEventsFor`, {
      startDate: startDate,
      endDate: endDate,
      campusID: campusID,
      category: category,
      type: type,
      timezone: timezone,
    })
    .then((res) => {
      return res.data.map(e => {
        let newEvent = {
          ...e,
          start: new Date(e.start),
          end: new Date(e.end),
        }

        // all detailed event calendars should have this field but just in case
        if (e.preview) {
          newEvent.eventID = e.preview.eventID
          newEvent.tagID = e.preview.tagID
          newEvent.createdBy = e.preview.createdBy
          newEvent.createdByDepartment = e.preview.createdByDepartment
          newEvent.buildingIDs = e.preview.buildingIDs
          newEvent.hasConflicts = e.preview.conflictIDs.length > 0 ? true : false
          newEvent.resourceIDs = e.preview.resourceIDs
          newEvent.roomIDs = e.preview.roomIDs
        } else {
          newEvent.eventID = null
          newEvent.tagID = null
          newEvent.createdBy = null
          newEvent.createdByDepartment = null
          newEvent.buildingIDs = []
          newEvent.hasConflicts = false
          newEvent.resourceIDs = []
          newEvent.roomIDs = []
        }
        return newEvent
      })
    });
}

export const myCalendarSettingsForCalendarAtom = atom({
  key: "myCalendarSettingsForCalendar",
  default: selector({
    key: "myCalendarSettingsForCalendarSelector",
    get: async () => {
      return await getMyCalendarSettingsForCalendar()
    },
  }),
})

export const getMyCalendarSettingsForCalendar = async () => {
  return await axios
    .get(`${api.path}/staff_portal_api/v1/getMyCalendarSettingsForCalendar`)
    .then((res) => {
      return res.data;
    });
}