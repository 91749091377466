import GoBack from "components/GoBack/GoBack"
import Loading from 'components/Loading/Loading'
import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { makeStyles } from "@material-ui/core/styles";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// Card
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardText from "components/Card/CardText";

import { Checkbox, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "components/CustomButtons/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import InfoIcon from '@material-ui/icons/Info';

import update from 'immutability-helper'
import {
  createEvent,
  updateEvent,
  findConflictsForEventEntryReservation,
  getDatesForEventEntries,
  campusAtom,
  buildingsAtom,
  roomsAtom,
  resourceAtom,
  eventCalendarScheduleOutRangesAtom,
  getReservationsBetween,
  eventTagGroupsAtom,
  getRoomsForCampus,
  resourcesForCreatingEventSelector,
} from 'state/events'
import DaysOfWeekSelection from 'components/DaysOfWeekSelection/DaysOfWeekSelection';
import HelperText from 'components/HelperText/HelperText';
import { Info, NavigateNext, ExpandMore, AccessTime, Block, EventBusy, PriorityHigh, AssignmentLate } from '@material-ui/icons';
import ReactTooltip from 'react-tooltip';
import EventEntryValidation from 'components/EventEntryValidation/EventEntryValidation';
import RoomReservationSelection from 'components/RoomReservationSelection/RoomReservationSelection';
import { getResourcesForCreatingEvent } from 'state/events';
import ResourceReservationSelection from 'components/ResourceReservationSelection/ResourceReservationSelection';
import { decryptData } from 'assets/jss/EncriptDecrypt/EncryptDecrypt';
import { userDepartments } from 'state/auth';
import { eventWithEntiesWithConflicts } from 'state/events'
import { updateEventEntries } from "state/events";

const ConflictHandlerComp = (props) => {
  const id = props.match.params.id
  const userType = props.match.params.userType
  const search = props.location.search?.split("?")[1] || null
  const searchData = search ? decryptData(search) : {}

  const buildings = useRecoilValue(buildingsAtom)
  const eventRanges = useRecoilValue(eventCalendarScheduleOutRangesAtom)

  const eventRef = useRecoilValue(eventWithEntiesWithConflicts(id))
  const formatEvent = () => {
    if (eventRef) {
      let timeslots = eventRef.eventEntriesData.map(ts => {
        const [startDate, startTime] = ts.start.split(' ')
        const [endDate, endTime] = ts.end.split(' ')
        return {
          ...ts,
          startDate: startDate,
          startTime: startTime,
          endDate: endDate,
          endTime: endTime
        }
      })
      return {
        ...eventRef,
        eventEntriesData: timeslots
      }
    }
    return null
  }

  const [event, setEvent] = useState(eventRef ? formatEvent() : null)
  const [loading, setLoading] = useState(false)

  const [uneditedEventEntries, setUneditedEventEntries] = useState([])
  const [eventEntryErrors, setEventEntryErrors] = useState([])
  const [eventEntryLoading, setEventEntryLoading] = useState([])

  const eventHasWarning = eventEntryErrors.some(e => e.warning)
  const eventHasError = eventEntryErrors.some(e => e.error)
  const eventNotLoading = eventEntryLoading.every(e => !e)
  const edited = event.eventEntriesData.some(e => e.edited)

  const [acknowledgeWarnings, setAcknowledgeWarnings] = useState(false)

  const selectableBuildings = event.campusID ? buildings.filter(b => b.campusID === event.campusID) : []

  const confirmReservations = () => {
    let eventEntryErrorsTemp = []
    let eventEntries = event.eventEntriesData.map(e => {
      eventEntryErrorsTemp.push({ warning: false, error: false, messages: [] })
      return {
        ...e,
        reservations: event.reservations
      }
    })
    setEventEntryErrors(eventEntryErrorsTemp)
    setEventEntryLoading(eventEntryErrorsTemp.map(() => true))
    updateEventData({ eventEntriesData: { $set: eventEntries } })
    setUneditedEventEntries(eventEntries)
    setAcknowledgeWarnings(false)
  }

  const updateEventData = (updateData) => {
    setEvent(prev => {
      return update(prev, updateData)
    })
  }

  const submitForm = async () => {
    setLoading(true)
    let update = {
      id: event.id,
      eventEntriesData: []
    }
    event.eventEntriesData.forEach(e => {
      if (e.edited) {
        update.eventEntriesData.push(e)
      }
    })
    await updateEventEntries(update)
    if (search.returnLocation) {
      window.location.href = search.returnLocation
    } else {
      window.location.href = '/portal/events/teamEvents'
    }

  }

  useEffect(() => {
    if (eventRef) {
      confirmReservations()
    }
  }, [])

  return (
    <React.Fragment>
      {loading ?
        <div style={{ maxWidth: '1100px' }}>
          <Loading color='blue' />
        </div>
        :
        <div style={{ maxWidth: '1100px' }}>
          <GoBack returnLocation={searchData.returnLocation} disableRound={true} />
          {event ?
            <Card>
              <CardHeader color='info' text>
                <CardText color='info' style={{ fontSize: '16px', fontWeight: '400', padding: '10px' }}>
                  Event Conflicts
                </CardText>
              </CardHeader>
              <CardBody>
                {event.eventEntriesData.map((entry, index) => {
                  const updateEventEntryAtIndex = (update) => {
                    updateEventData({
                      eventEntriesData: { [index]: update }
                    })
                  }
                  const updateEventEntryError = (updateData) => {
                    setEventEntryErrors(prev => {
                      return update(prev, {
                        [index]: updateData
                      })
                    })
                  }
                  const updateEventEntryLoading = (val) => {
                    setEventEntryLoading(prev => {
                      return update(prev, {
                        [index]: { $set: val }
                      })
                    })
                  }
                  return (
                    <EventEntryValidation
                      key={index}
                      eventEntry={entry}
                      updateEventEntry={updateEventEntryAtIndex}
                      buildings={selectableBuildings}
                      campusID={event.campusID}
                      bigEvent={event.bigEvent}
                      uneditedEventEntry={uneditedEventEntries[index]}
                      eventEntryError={eventEntryErrors[index]}
                      updateEventEntryError={updateEventEntryError}
                      eventRanges={eventRanges}
                      setAcknowledgeWarnings={setAcknowledgeWarnings}
                      updateEventEntryLoading={updateEventEntryLoading}
                      eventID={event.id}
                    />
                  )
                })}
                {(edited) && (eventHasWarning && !eventHasError) && !acknowledgeWarnings && eventNotLoading && (
                  <div style={{ textAlign: 'right' }}>
                    <Button
                      color='warning'
                      title='Acknowledge Warnings'
                      onClick={(e) => {
                        e.preventDefault()
                        setAcknowledgeWarnings(true)
                      }}
                    >
                      Acknowledge Warnings
                    </Button>
                  </div>
                )}
                {(edited) && ((eventHasWarning && acknowledgeWarnings) || !eventHasWarning) && eventNotLoading && (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                    <div>
                      <Button
                        disabled={eventHasError}
                        color='primary'
                        title='Submit'
                        onClick={(e) => {
                          e.preventDefault()
                          submitForm()
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                    {eventHasError &&
                      (<p style={{ color: '#f44336', fontSize: '12px', fontWeight: 'bold' }}>* Fix errors before submitting</p>

                      )}
                  </div>
                )}
              </CardBody>
            </Card>
            :
            <p>You shouldn't be here</p>}
        </div>
      }
    </React.Fragment>
  )
}

const ConflictHandler = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <ConflictHandlerComp {...props} />
    </React.Suspense>
  )
}

export default ConflictHandler