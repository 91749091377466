import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import axios from "axios";
import moment from "moment";
import {
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  selectorFamily,
} from "recoil";
// import api from "../../api.config";

// import { calendarSettingsAtom } from "state/settings";

import {
  calendarEventsByMonthAtom,
  getCalendarEventsForMonth
} from "state/calendar";

// import SweetAlert from "react-bootstrap-sweetalert";
// import alertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

// import Tooltip from "@material-ui/core/Tooltip";

// import { tooltip } from "assets/jss/material-dashboard-pro-react.js";

// NEW TOOLTIP
import ReactTooltip from "react-tooltip";
// import { Button } from "@material-ui/core";

// const tooltipStyles = { tooltip };

// const useAlert = makeStyles(alertStyle);
// const useTooltipStyles = makeStyles(tooltipStyles);

const MyCalendarEvent = (props) => {
  const startDate = props.event.start;
  const endDate = props.event.end;

  return (
    <React.Fragment>
      <div
        data-tip
        data-for={`${props.event.id}`}
        style={{ backgroundColor: props.event.color }}
      >
        {props.event.title}
      </div>
      <ReactTooltip delayHide={250} effect="solid" id={`${props.event.id}`}>
        <div style={{ textAlign: "left" }}>
          <p>{props.event.title}</p>
          {!props.event.allDay && (
            <React.Fragment>
              <p>
                Start Time:
                {` ${unmilitarize(startDate.getHours())}:${twoDigitize(
                  startDate.getMinutes()
                )} ${meridianize(startDate.getHours())}`}
              </p>
              <p>
                End Time:
                {` ${unmilitarize(endDate.getHours())}:${twoDigitize(
                  endDate.getMinutes()
                )} ${meridianize(endDate.getHours())}`}
              </p>
            </React.Fragment>
          )}
        </div>
      </ReactTooltip>
    </React.Fragment>
  );
};

// const CustomToolbar = (props) => {
//   console.log(props)
//   return (
//     <p>test</p>
//   )
// }

const twoDigitize = (n) => {
  return n < 10 ? "0" + n : n;
};

const meridianize = (n) => {
  return n !== 0 && n > 12 ? "pm" : "am";
};

const unmilitarize = (n) => {
  return n > 12 ? n - 12 : n;
};

const localizer = momentLocalizer(moment);

const CalendarTabComponent = ({ calendars }) => {

  const [date, setDate] = useState(new Date())

  const thisMonth = useRecoilValue(calendarEventsByMonthAtom({
    calendars: calendars,
    month: date.getMonth() + 1,
    year: date.getFullYear()
  }))


  const events = thisMonth.map(e => {
    return {
      ...e,
      start: new Date(e.start),
      end: new Date(e.end)
    }
  })

  const updateDate = (newDate) => {
    if (date.getMonth() !== newDate.getMonth() || date.getFullYear() !== newDate.getFullYear()) {
      setDate(newDate)
    }
  }

  // const alertClasses = useAlert();

  // const [showMoreItemsModal, setShowMoreItemsModal] = useState(null);

  const eventColors = (event) => {
    return {
      style: { backgroundColor: event.color },
    };
  };

  // const submitHandler = () => {
  //   setShowMoreItemsModal(null);
  // };

  // const cancelHandler = () => {
  //   setShowMoreItemsModal(null);
  // };

  // const showMoreItemsModalHandler = (date, view) => {
  //   let dates = date.toString().split(" ");
  //   date = [dates[0], dates[1], dates[2], dates[3]].join(" ");

  //   let filteredEvents = gcalEvents.filter((item) => {
  //     return item.start.toString().includes(date) ||
  //       item.end.toString().includes(date)
  //       ? item
  //       : null;
  //   });

  //   if (filteredEvents.length) {
  //     setShowMoreItemsModal(
  //       <SweetAlert
  //         style={{ display: "block", marginTop: "-100px", color: "#000" }}
  //         title={`${date}`}
  //         onConfirm={submitHandler}
  //         onCancel={cancelHandler}
  //         confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
  //         confirmBtnText="OK"
  //       >
  //         {filteredEvents.map((item) => {
  //           return <p key={item.id}>{item.title}</p>;
  //         })}
  //       </SweetAlert>
  //     );
  //   }
  // };

  return (
    <React.Fragment>
      {/* {showMoreItemsModal} */}
      <BigCalendar
        // selectable
        popup
        localizer={localizer}
        events={events}
        defaultView="month"
        //views={["month"]}
        scrollToTime={new Date(1970, 1, 1, 6)}
        defaultDate={date}
        onSelectEvent={(event) => { }}
        onSelectSlot={(slotInfo) => { }}
        onNavigate={(e => updateDate(e))}
        eventPropGetter={eventColors}
        //onDrillDown={showMoreItemsModalHandler}
        tooltipAccessor={null}
        components={{
          month: {
            event: MyCalendarEvent,
          },
          // toolbar: CustomToolbar
        }}
      /* formats={{
        dateFormat: "dd",

        // http://jquense.github.io/react-big-calendar/examples/index.html#prop-formats
        dayRangeHeaderFormat: "YYYY",
      }} */
      />
    </React.Fragment>
  );
};

const CalendarTab = (props) => {
  return (
    <React.Suspense>
      <CalendarTabComponent {...props} />
    </React.Suspense>
  );
};

export default CalendarTab;
