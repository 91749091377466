import React from "react";
import CustomTabs from "components/CustomTabs/CustomTabs";
import { useRecoilState } from "recoil";
import Loading from "components/Loading/Loading";
import {
  campusAtom,
  buildingsAtom,
  roomsAtom,
  getBuildings,
  getRooms,
  resourceAtom,
} from "state/events";
import Campuses from "./RoomManagerTabs/Campuses";
import Buildings from "./RoomManagerTabs/Buildings";
import Rooms from "./RoomManagerTabs/Rooms";
import ResourceManager from "./RoomManagerTabs/ResourceManager";

const RoomManagerComp = (props) => {

  const tab = +(props.location.search?.split("=")[1] || 0);
  const [campuses, setCampuses] = useRecoilState(campusAtom)
  const [buildings, setBuildings] = useRecoilState(buildingsAtom)
  const [rooms, setRooms] = useRecoilState(roomsAtom)
  const [resources, setResources] = useRecoilState(resourceAtom)

  const handleDeleteUpdate = async () => {
    let b = await getBuildings()
    let r = await getRooms()
    setBuildings(b)
    setRooms(r)
  }


  const tabs = [
    {
      tabName: "Campuses",
      tabContent: <Campuses campuses={campuses} setCampuses={setCampuses} handleDeleteUpdate={handleDeleteUpdate} />,
    },
    {
      tabName: "Buildings",
      tabContent: <Buildings campuses={campuses} buildings={buildings} setBuildings={setBuildings} handleDeleteUpdate={handleDeleteUpdate} />,
    },
    {
      tabName: "Rooms",
      tabContent: <Rooms campuses={campuses} buildings={buildings} rooms={rooms} setRooms={setRooms} />,
    },
    {
      tabName: "Resources",
      tabContent: <ResourceManager campuses={campuses} resources={resources} setResources={setResources} />,
    },
  ];

  return (
    <React.Fragment>
      <div style={{ display: 'flex', maxWidth: '1100px' }}>
        <CustomTabs headerColor="info" tabs={tabs} value={tab} />
      </div>
    </React.Fragment>
  )
}

const RoomManagerLoading = (props) => {

  const tab = +(props.location.search?.split("=")[1] || 0);
  const tabs = [
    {
      tabName: "Campuses",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Buildings",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Rooms",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Resources",
      tabContent: <Loading color="blue" />,
    },
  ];
  return (
    <React.Fragment>
      <div style={{ display: 'flex', maxWidth: '1100px' }}>
        <CustomTabs headerColor="info" tabs={tabs} value={tab} />
      </div>
    </React.Fragment>
  )
}

const RoomManager = (props) => {
  return (
    <React.Suspense fallback={<RoomManagerLoading {...props} />}>
      <RoomManagerComp {...props} />
    </React.Suspense>
  )
}

export default RoomManager