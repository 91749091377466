import React, { useState } from 'react'

import Button from "components/CustomButtons/Button";

import update from 'immutability-helper'
import {
  NavigateNext,
  ExpandMore,
} from '@material-ui/icons';

const ViewResourceGeneric = ({ resources, dropdownDefault = true }) => {

  const resourceTypes = [...(new Set(resources.map(r => r.type).flat(1)))]
  const [resourceTypeToggles, setResourceTypeToggles] = useState(Array(resourceTypes.length).fill(true))
  const [resourceToggle, setResourceToggle] = useState(dropdownDefault)

  const resourceForDisplay = resourceTypes.map(rType => {
    return {
      type: rType,
      resources: resources.filter(r => r.type === rType),
    }
  })

  return (
    <>
      <Button
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '5px 5px 5px 10px' }}
        color='info'
        onClick={() => setResourceToggle(!resourceToggle)}
      >
        <span style={{ marginRight: '10px', marginBottom: '-3px' }}>Resource Reservations</span>
        {resourceToggle ? <ExpandMore style={{ margin: '0px' }} /> : <NavigateNext style={{ margin: '0px' }} />}
      </Button>
      <div style={{
        opacity: resourceToggle ? 1 : 0,
        transition: 'all 2000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
      }}>
        {(resourceToggle) && (
          <div
            style={{
              borderRadius: "4px",
              background: "#fff",
              padding: "5px 10px",
              border: "1px solid #c4c4c4",
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >

            {resourceForDisplay.map((type, index) => {
              return (
                <div
                  key={index}
                >
                  {type.type.length > 0 && (
                    <>
                      <Button
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                          padding: '5px 5px 5px 10px',
                          color: '#393939',
                          backgroundColor: '#fff',
                          border: '1px solid #3695d7',
                          margin: '5px 0px'
                        }}
                        onClick={() => setResourceTypeToggles(prev => {
                          return update(prev, {
                            [index]: { $set: !resourceTypeToggles[index] }
                          })
                        })}
                      >
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '-3px', marginRight: '10px', flexWrap: 'wrap' }}>
                          <span style={{ marginRight: '5px', fontSize: '16px', fontWeight: 'bold' }}>{type.type}</span>
                        </div>
                        {resourceTypeToggles[index] ? <ExpandMore style={{ margin: '0px' }} /> : <NavigateNext style={{ margin: '0px' }} />}
                      </Button>
                      <div
                        style={{
                          borderRadius: "4px",
                          background: "#eee",
                          padding: resourceTypeToggles[index] ? "5px" : '0px',
                          border: resourceTypeToggles[index] ? "1px solid #c4c4c4" : '',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          opacity: resourceTypeToggles[index] ? 1 : 0,
                          transition: 'opacity 2000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
                          width: '100%',
                          margin: '5px 0px'
                        }}
                      >
                        {(resourceTypeToggles[index]) && (
                          <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0px 5px 8px 5px' }}>
                            {type.resources.map((r, index) => {
                              return (
                                <DisplayResource
                                  key={r.id}
                                  resource={r}
                                />
                              )
                            })}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </>
  )
}

const DisplayResource = ({ resource }) => {
  return (
    <>
      {resource.type === 'general' && (
        <div
          style={{
            margin: '6px 0px 2px 0px',
            padding: '5px 7px 5px 10px',
            backgroundColor: '#fff',
            border: '1px solid #3695d7',
            color: '#393939',
            textTransform: 'none',
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'column',
            fontWeight: 400,
            lineHeight: 1.42857143,
            cursor: 'pointer',
            width: '100%',
            cursor: ''
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >

            <span>{resource.name}</span>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '5px',
                flexWrap: 'wrap'
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
                <span style={{ marginTop: '1px' }}>Using: {resource.reservation.quantity} / {resource.quantity}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ViewResourceGeneric