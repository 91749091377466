import React, { useState } from 'react'
import Loading from 'components/Loading/Loading'
import { useRecoilValue } from 'recoil';

import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import update from 'immutability-helper'

import ToggleItemList from 'components/ToggleItemList/ToggleItemList';

import { calendarEventsForAtom } from 'state/calendar'
import {
  campusAtom,
  buildingsAtom,
  roomsAtom,
  resourceAtom,
  eventTagGroupsAtom
} from 'state/events';

import {
  userAtom,
  allDepartments
} from 'state/auth';

import ReactTooltip from "react-tooltip";
import { encryptData } from 'assets/jss/EncriptDecrypt/EncryptDecrypt';

// Card
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardText from "components/Card/CardText";
import Button from "components/CustomButtons/Button";
import { Checkbox, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  DateRange,
  Business,
  Error,
  Today,
  Edit,
  EventBusy,
  Visibility,
  FilterNone,
  Person,
  Event,
  NotificationsOff,
  NotificationsActive,
  NotificationImportant,
  Autorenew,
  LocalOffer,
  Info,
  FilterList,
  Close,
  Group,
  Create,
  Flag,
  Description,
  Done
} from '@material-ui/icons';

const EventCalendarComp = (props) => {

  const campuses = useRecoilValue(campusAtom)
  const buildings = useRecoilValue(buildingsAtom)
  const rooms = useRecoilValue(roomsAtom)
  const resources = useRecoilValue(resourceAtom)
  const tags = useRecoilValue(eventTagGroupsAtom)
  const departments = useRecoilValue(allDepartments)

  const categorys = [
    'Events',
    'Rooms',
    'Resources'
  ]

  const emptyCalendarSelection = {
    campus: campuses.length > 0 ?
      campuses.find(c => c.name === 'Riverside') ?
        campuses.find(c => c.name === 'Riverside') : campuses[0] : null,
    category: categorys[0],
  }
  const emptyFilters = {
    buildings: [],
    rooms: [],
    tags: [],
    resources: [],
    departments: [],
    search: ''
  }

  const [filters, setFilters] = useState(emptyFilters)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [calendarSelections, setCalendarSelections] = useState(emptyCalendarSelection)

  const updateCalendarSelections = (updateData) => {
    setFilters(emptyFilters)
    setSelectedEvent(null)
    setCalendarSelections(prev => {
      return update(prev, updateData)
    })
  }

  const localizer = momentLocalizer(moment);

  const getStartDate = (date) => {
    let firstDate = new Date(date.getFullYear(), date.getMonth(), 1)
    firstDate.setDate(firstDate.getDate() - 6)
    return firstDate
  }

  const getEndDate = (date) => {
    let lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 1)
    lastDate.setDate(lastDate.getDate() + 5)
    return lastDate
  }

  const [calDate, setCalDate] = useState(new Date())
  const [toggleFilters, setToggleFilters] = useState(false)

  const updateDate = (date) => {
    if (date !== calDate) {
      setCalDate(date)
    }
  }

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const thisMonth = calendarSelections.campus ? useRecoilValue(calendarEventsForAtom({
    startDate: getStartDate(calDate),
    endDate: getEndDate(calDate),
    campusID: calendarSelections.campus.id,
    category: calendarSelections.category,
    type: 'Detailed',
    timezone: userTimezone
  }))
    :
    []

  const updateFilters = (updateData) => {
    setFilters(prev => {
      return update(prev, updateData)
    })
  }

  const events = thisMonth.filter(e => {
    if (filters.tags.length > 0) {
      if (filters.tags.indexOf(e.tagID) === -1) {
        return false
      }
    }

    if (filters.departments.length > 0) {
      if (filters.departments.indexOf(e.createdByDepartment) === -1) {
        return false
      }
    }

    if (filters.buildings.length > 0) {
      let foundBuilding = false
      e.buildingIDs.forEach(b => {
        if (filters.buildings.indexOf(b) !== -1) {
          foundBuilding = true
        }
      })
      if (!foundBuilding) {
        return false
      }
    }

    if (filters.resources.length > 0) {
      let foundResource = false
      e.resourceIDs.forEach(b => {
        if (filters.resources.indexOf(b) !== -1) {
          foundResource = true
        }
      })
      if (!foundResource) {
        return false
      }
    }

    if (filters.rooms.length > 0) {
      let foundRoom = false
      e.roomIDs.forEach(b => {
        if (filters.rooms.indexOf(b) !== -1) {
          foundRoom = true
        }
      })
      if (!foundRoom) {
        return false
      }
    }

    return true
  })

  const styles = {
    infoItem: { display: 'flex', alignItems: 'center', marginBottom: '5px', flexWrap: 'wrap', fontWeight: 400 },
    iconStyle: { margin: '0px', height: '20px', color: '#aaa' },
    eventItems: { display: 'flex', cursor: 'pointer', flex: 1, minWidth: '200px', fontWeight: 400 }
  }

  const selectableBuildings = buildings.filter(i => i.campusID === calendarSelections.campus.id)

  const selectableRooms = rooms.filter(r => r.campusID === calendarSelections.campus.id &&
    (filters.buildings.length === 0 || filters.buildings.indexOf(r.buildingID) !== -1))
  const formattedRooms = selectableRooms.map(r => {
    let room = {
      ...r
    }
    if (r.number) {
      room.formattedName = `${r.number} - ${r.name}`
    } else {
      room.formattedName = r.name
    }
    return room
  }).sort((a, b) => {
    if (a.formattedName > b.formattedName) return 1
    if (a.formattedName < b.formattedName) return -1
    return 0
  })

  const selectableResources = resources.filter(i => i.campusID === calendarSelections.campus.id)

  const formatDepartments = departments.map(d => {
    let dept = {
      ...d
    }
    if (!d.name) {
      dept.name === d.email
    }
    return dept
  })


  return (
    <React.Fragment>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            maxWidth: '1000px',
            flexWrap: 'wrap',
            gap: '10px',
            alignItems: 'end'
          }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <div>
              <Autocomplete
                style={{ minWidth: '250px' }}
                disablePortal
                options={campuses}
                value={calendarSelections.campus}
                getOptionSelected={(option) => {
                  return option.id === calendarSelections.campus.id
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Campus"
                  />
                )}
                autoSelect={true}
                autoComplete={true}
                autoHighlight={true}
                disableClearable
                onChange={(e, v) => updateCalendarSelections({ campus: { $set: v } })}
              />
            </div>
            {/* <div>
              <Autocomplete
                style={{ minWidth: '200px' }}
                disablePortal
                options={categorys}
                value={calendarSelections.category}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                  />
                )}
                autoSelect={true}
                autoComplete={true}
                autoHighlight={true}
                disableClearable
                onChange={(e, v) => updateCalendarSelections({ category: { $set: v } })}
              />
            </div> */}
          </div>
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            {toggleFilters ?
              <Button
                justIcon
                style={{ margin: '0px', height: '42px', backgroundColor: '#fff', color: '#000' }}
                onClick={() => {
                  setToggleFilters(false)
                }}
              >
                <Close />
              </Button>
              :
              <Button
                justIcon
                style={{ margin: '0px', height: '42px', backgroundColor: '#fff', color: '#000' }}
                title='Filters'
                onClick={() => {
                  setToggleFilters(true)
                }}
              >
                <FilterList />
              </Button>
            }
          </div>
        </div>
        {(toggleFilters) && (
          <Card style={{ margin: '0px', maxWidth: '1000px' }}>
            <CardBody>
              <div
                style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}
              >
                <div
                  style={{
                    flex: 1,
                    minWidth: '200px',
                    maxWidth: '300px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px'
                  }}>
                  <div>
                    <ToggleItemList
                      items={formatDepartments}
                      nameAccessor={'name'}
                      selectedItems={filters.resources}
                      idAccessor={'email'}
                      label={'Departments'}
                      updateItemList={(updateData) => updateFilters({ resources: updateData })}
                    />
                  </div>
                  <div>
                    <ToggleItemList
                      items={tags}
                      nameAccessor={'name'}
                      selectedItems={filters.tags}
                      idAccessor={'_id'}
                      label={'Tags'}
                      updateItemList={(updateData) => updateFilters({ tags: updateData })}
                    />
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    minWidth: '200px',
                    maxWidth: '300px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px'
                  }}>
                  <div>
                    <ToggleItemList
                      items={selectableBuildings}
                      nameAccessor={'name'}
                      selectedItems={filters.buildings}
                      idAccessor={'id'}
                      label={'Buildings'}
                      updateItemList={(updateData) => updateFilters({ buildings: updateData })}
                    />
                  </div>
                  <div>
                    <ToggleItemList
                      items={selectableResources}
                      nameAccessor={'name'}
                      selectedItems={filters.resources}
                      idAccessor={'_id'}
                      label={'Resources'}
                      updateItemList={(updateData) => updateFilters({ resources: updateData })}
                    />
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    minWidth: '200px',
                    maxWidth: '300px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px'

                  }}>
                  {selectableBuildings.map(b => {
                    const bRooms = formattedRooms.filter(r => r.buildingID === b.id)
                    return (
                      <>
                        {(bRooms.length > 0) && (
                          <div>
                            <ToggleItemList
                              items={bRooms}
                              nameAccessor={'formattedName'}
                              selectedItems={filters.rooms}
                              idAccessor={'id'}
                              label={b.name}
                              updateItemList={(updateData) => updateFilters({ rooms: updateData })}
                            />
                          </div>
                        )}
                      </>
                    )
                  })}
                </div>
              </div>
            </CardBody>
          </Card>
        )}
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          <Card style={{ margin: '0px', overflow: 'auto', maxWidth: '1000px' }}>
            <CardBody >
              <div style={{ minWidth: '600px' }}>
                <BigCalendar
                  popup
                  localizer={localizer}
                  events={events}
                  defaultView="month"
                  views={["month", 'day']}
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  defaultDate={calDate}
                  onSelectEvent={(event) => { setSelectedEvent(event) }}
                  onSelectSlot={(slotInfo) => { }}
                  onNavigate={(e => updateDate(e))}
                  eventPropGetter={(event) => {
                    return {
                      style: {
                        backgroundColor: '#389ada', fontSize: '12px', display: 'flex', justifyContent: 'left', padding: '2px 0px 2px', cursor: 'pointer'
                      },
                    };
                  }}
                  tooltipAccessor={null}
                />
              </div>
            </CardBody>
          </Card>
          {selectedEvent && (
            <div>
              {selectedEvent.preview && (
                <Card style={{ margin: '0px', overflow: 'auto', maxWidth: '500px' }}>
                  <CardBody style={{ padding: '10px' }}>
                    <div
                      style={{
                        width: '100%',
                      }}>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <p style={{ margin: '0px 0px 10px 4px', fontWeight: 'bold', fontSize: '22px' }}>
                            {selectedEvent.preview.name}
                          </p>
                          <Button
                            justIcon
                            style={{ margin: '0px' }}
                            color='primary'
                            title='View Event'
                            onClick={() => {
                              let search = encryptData({
                                returnLocation: window.location.href,
                              })
                              window.location.href = `/portal/events/viewer/viewEvent/${selectedEvent.preview.eventID}?${search}`
                            }}
                          >
                            <Visibility />
                          </Button>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <div
                            style={styles.eventItems}
                            title='Created By'
                          >
                            <Person style={styles.iconStyle} />
                            <span>{selectedEvent.preview.createdByName}</span>
                          </div>

                          <div
                            style={styles.eventItems}
                            title='Created'
                          >
                            <Create style={styles.iconStyle} />
                            <span>{selectedEvent.preview.createdReadable}</span>
                          </div>

                        </div>

                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <div
                            style={styles.eventItems}
                            title='Department'
                          >
                            <Group style={styles.iconStyle} />
                            <span>{selectedEvent.preview.createdByDepartmentName}</span>
                          </div>

                          <div
                            style={styles.eventItems}
                            title='Campus'
                          >
                            <Business style={styles.iconStyle} />
                            <span>{selectedEvent.preview.campus}</span>
                          </div>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >

                          <div
                            style={styles.eventItems}
                            title='Event Type'
                          >
                            <Info style={styles.iconStyle} />
                            <span>{selectedEvent.preview.bigEvent ? 'Special Event' : 'Regular Event'}</span>
                          </div>

                          <div
                            style={styles.eventItems}
                            title='Tag'
                          >
                            <LocalOffer style={styles.iconStyle} />
                            <span>{selectedEvent.preview.tag}</span>
                          </div>

                        </div>

                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <div
                            style={styles.eventItems}
                            title='When'

                          >
                            <Event style={styles.iconStyle} />
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0px 5px' }}>
                              <span>{selectedEvent.preview.when}</span>
                              <span>
                                Repeats: {selectedEvent.preview.recurringInfo}
                              </span>
                            </div>
                          </div>
                        </div>

                        {(selectedEvent.preview.description) && (
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                            }}
                          >
                            <div
                              style={styles.eventItems}
                              title='Description'
                            >
                              <Description style={styles.iconStyle} />
                              <span>{selectedEvent.preview.description}</span>

                            </div>
                          </div>
                        )}

                        {(selectedEvent.preview.reservations.length > 0) && (
                          <div
                          >

                            {(selectedEvent.preview.reservations.filter(r => r.forType === 'room').length > 0) && (
                              <div
                                style={{
                                  cursor: 'pointer',
                                  fontWeight: 400,
                                  width: '100%'
                                }}
                              >
                                <p style={{ margin: 0 }}>Rooms:</p>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    padding: '5px'
                                  }}>
                                  {(selectedEvent.preview.reservations.filter(r => r.forType === 'room').map(r => {
                                    return (
                                      <p key={r.id} style={{ margin: '0px', flex: 1, minWidth: '200px' }}>{r.forName}{(r.quantity) && <> - {r.quantity}</>}</p>
                                    )
                                  }))}
                                </div>
                              </div>
                            )}

                            {(selectedEvent.preview.reservations.filter(r => r.forType === 'resource').length > 0) && (
                              <div
                                style={{
                                  cursor: 'pointer',
                                  fontWeight: 400,
                                  width: '100%'
                                }}
                              >
                                <p style={{ margin: 0 }}>Resources:</p>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    padding: '5px'
                                  }}>
                                  {(selectedEvent.preview.reservations.filter(r => r.forType === 'resource').map(r => {
                                    return (
                                      <p key={r.id} style={{ margin: '0px', flex: 1, minWidth: '200px' }}>{r.forName}{(r.quantity) && <> - {r.quantity}</>}</p>
                                    )
                                  }))}
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                      </div>

                    </div>
                  </CardBody>
                </Card>
              )}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

const EventCalendar = (props) => {
  return (
    <React.Suspense fallback={<Loading color='blue' />}>
      <EventCalendarComp {...props} />
    </React.Suspense>
  )
}

export default EventCalendar