import Loading from 'components/Loading/Loading'
import React, { useState } from 'react'
import { decryptData, encryptData } from 'assets/jss/EncriptDecrypt/EncryptDecrypt'
import { useRecoilValue } from 'recoil'
import { viewEvent } from 'state/events'
import GoBack from "components/GoBack/GoBack"
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardText from "components/Card/CardText";
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import Button from "components/CustomButtons/Button";

import {
  DateRange,
  Business,
  Error,
  Today,
  Edit,
  EventBusy,
  Visibility,
  FilterNone,
  Person,
  Event,
  NotificationsOff,
  NotificationsActive,
  NotificationImportant,
  Autorenew,
  LocalOffer,
  Info,
  FilterList,
  Close,
  Group,
  Create,
  Flag,
  Description,
  Done
} from '@material-ui/icons'
import ViewRoomGeneric from 'components/Events/ViewRoomGeneric'
import ViewResourceGeneric from 'components/Events/ViewResourceGeneric'
import ViewEventEntriesGeneric from 'components/Events/ViewEventEntriesGeneric'

const ViewEventComp = (props) => {
  const id = props.match.params.id
  const userType = props.match.params.userType
  const search = props.location.search?.split("?")[1] || null
  const searchData = search ? decryptData(search) : {}

  const event = useRecoilValue(viewEvent(id))
  const [loading, setLoading] = useState(false)

  const styles = {
    topbarButtons: { height: '25px', width: '25px' },
    infoItem: { display: 'flex', alignItems: 'center', marginBottom: '5px', flexWrap: 'wrap', fontWeight: 400 },
    clientMessage: { display: 'flex', alignItems: 'center', backgroundColor: '#feddbc', borderRadius: '6px 6px 0px 0px', justifyContent: 'space-between' },
    replyMessage: { display: 'flex', alignItems: 'center', backgroundColor: '#6babd5', borderRadius: '6px 6px 0px 0px', justifyContent: 'space-between' },
    internalMessage: { display: 'flex', alignItems: 'center', backgroundColor: '#c5e2f4', borderRadius: '6px 6px 0px 0px', justifyContent: 'space-between' },
    iconStyle: { margin: '0px', height: '20px', color: '#aaa' },
    eventItems: { display: 'flex', cursor: 'pointer', flex: 1, minWidth: '200px', fontWeight: 400 }
  }

  const buildings = event.buildingIDs.map((b, index) => {
    return {
      id: b,
      name: event.buildings[index],
      floors: [...(new Set(event.rooms.filter(r => r.buildingID === b).map(r => r.floor)))]
    }
  })
  const rooms = event.rooms.map(r => {
    let room = {
      ...r
    }
    if (r._id) {
      room.id = r._id
    }
    return room
  })

  const resources = event.resources.map(r => {
    let resource = {
      ...r,
    }
    if (r._id) {
      resource.id = r._id
    }
    let reservation = event.reservations.find(res => res.forType === 'resource' && res.forID === resource.id)
    if (reservation) {
      resource.reservation = reservation
    }
    return resource
  })
  return (
    <React.Fragment>
      <div style={{ maxWidth: '1000px' }}>
        {loading ? <Loading color='blue' />
          :
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <GoBack returnLocation={searchData.returnLocation} disableRound={true} />
              {(event) && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                  {(userType === 'admin' || userType === 'user') && (
                    <>
                      <Button
                        style={{ margin: '0px', height: '42px' }}
                        justIcon
                        color="primary"
                        title='Edit'
                        onClick={() => {
                          window.location.href = `/portal/events/user/edit/${event.eventID}?${search}`
                        }}
                      >
                        <Edit />
                      </Button>
                      {(userType === 'user') && (<>
                        <Button
                          style={{ margin: '0px', height: '42px' }}
                          justIcon
                          color="primary"
                          title='Duplicate'
                          onClick={() => {
                            let search = encryptData({
                              returnLocation: searchData.returnLocation,
                              duplicate: true
                            })
                            window.location.href = `/portal/events/user/edit/${event.eventID}?${search}`
                          }}
                        >
                          <FilterNone />
                        </Button>
                        {(event.needsRenewal) && (
                          <Button
                            style={{ margin: '0px', height: '42px' }}
                            justIcon
                            color="primary"
                            title='Renew Event'
                            onClick={() => {
                              let search = encryptData({
                                returnLocation: window.location.href,
                              })
                              window.location.href = `/portal/events/user/renew/${event.eventID}?${search}`
                            }}
                          >
                            <Autorenew />
                          </Button>
                        )}
                        {(event.numConflicts > 0) && (
                          <Button
                            style={{ margin: '0px', height: '42px' }}
                            justIcon
                            color="warning"
                            title='Resolve Conflicts'
                            onClick={() => {
                              let search = encryptData({
                                returnLocation: window.location.href,
                              })
                              window.location.href = `/portal/events/user/handleConflicts/${event.eventID}?${search}`
                            }}
                          >
                            <Error />
                          </Button>
                        )}
                        {(event.numUpcomingEventEntries > 0) && (
                          <Button
                            style={{ margin: '0px', height: '42px' }}
                            justIcon
                            color="danger"
                            title='Cancel Event'
                            onClick={async () => {
                              if (confirm(`Cancel upcoming events for: "${event.name}"?`)) {
                                setLoading(true)
                                await endEvent({ id: event.eventID })
                                location.reload()
                              }
                            }}
                          >
                            <EventBusy />
                          </Button>
                        )}
                      </>)}
                    </>
                  )}
                </div>
              )}
            </div>
            {event ?
              <>
                <Card style={{ margin: '10px 0px 0px' }}>
                  <CardBody>

                    <div
                      style={{
                        width: '100%',
                      }}>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <p style={{ margin: '0px 0px 10px 4px', fontWeight: 'bold', fontSize: '20px' }}>
                            {event.name}
                          </p>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <div
                            style={styles.eventItems}
                            title='Created By'
                          >
                            <Person style={styles.iconStyle} />
                            <span>{event.createdByName}</span>
                          </div>

                          <div
                            style={styles.eventItems}
                            title='Created'
                          >
                            <Create style={styles.iconStyle} />
                            <span>{event.createdReadable}</span>
                          </div>

                        </div>

                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <div
                            style={styles.eventItems}
                            title='Department'
                          >
                            <Group style={styles.iconStyle} />
                            <span>{event.createdByDepartmentName}</span>
                          </div>

                          <div
                            style={styles.eventItems}
                            title='Campus'
                          >
                            <Business style={styles.iconStyle} />
                            <span>{event.campus}</span>
                          </div>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >

                          <div
                            style={styles.eventItems}
                            title='Event Type'
                          >
                            <Info style={styles.iconStyle} />
                            <span>{event.bigEvent ? 'Special Event' : 'Regular Event'}</span>
                          </div>

                          <div
                            style={styles.eventItems}
                            title='Tag'
                          >
                            <LocalOffer style={styles.iconStyle} />
                            <span>{event.tag}</span>
                          </div>

                        </div>

                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <div
                            style={styles.eventItems}
                            title='When'

                          >
                            <Event style={styles.iconStyle} />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span>{event.when}</span>
                              <span>
                                Repeats: {event.recurringInfo}
                              </span>
                            </div>
                          </div>
                          <div
                            style={styles.eventItems}
                            title='Renewal Reminders'
                          >
                            <NotificationsActive style={styles.iconStyle} />
                            <span>{event.renewReminder ? event.needsRenewal ? 'Yes, awaiting renewal' : 'Yes' : 'No'}</span>
                          </div>
                        </div>

                        {(event.description) && (
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                            }}
                          >
                            <div
                              style={styles.eventItems}
                              title='Description'
                            >
                              <Description style={styles.iconStyle} />
                              <span>{event.description}</span>

                            </div>
                          </div>
                        )}

                      </div>

                    </div>
                  </CardBody>
                </Card>
                {(rooms.length > 0) && (
                  <ViewRoomGeneric
                    rooms={rooms}
                    buildings={buildings}
                    dropdownDefault={true}
                  />
                )}
                {(resources.length > 0) && (
                  <ViewResourceGeneric
                    resources={resources}
                    dropdownDefault={true}
                  />
                )}

                {(event.eventEntryPreview.length > 0) && (
                  <ViewEventEntriesGeneric
                    eventEntries={event.eventEntryPreview}
                    userType={userType}
                    dropdownDefault={true}
                  />
                )}
              </>
              :
              <p>You shouldn't be here</p>}
          </>}

      </div>
    </React.Fragment>
  )
}

const ViewEvent = (props) => {
  return (
    <React.Suspense fallback={<Loading />}>
      <ViewEventComp {...props} />
    </React.Suspense>
  )
}

export default ViewEvent