import React, { useState } from 'react'

import Button from "components/CustomButtons/Button";

import update from 'immutability-helper'
import {
  NavigateNext,
  ExpandMore,
  DateRange,
  Business,
  Error,
  Today,
  Edit,
  EventBusy,
  Visibility,
  FilterNone,
  Person,
  Event,
  NotificationsOff,
  NotificationsActive,
  NotificationImportant,
  Autorenew,
  LocalOffer,
  Info,
  FilterList,
  Close,
  Group,
  Done
} from '@material-ui/icons';

import { encryptData } from 'assets/jss/EncriptDecrypt/EncryptDecrypt'

const ConflictingEventEntriesDisplay = ({ eventEntries, conflictsRaw, userType, dropdownDefault = true }) => {

  const [eventEntriesToggle, setEventEntriesToggle] = useState(dropdownDefault)

  const conflicts = conflictsRaw.map(c => {
    let conflict = {
      ...c
    }
    if (c._id) {
      conflict.id = c._id
    }
    return conflict
  })

  const entries = eventEntries.map(e => {
    let entry = {
      ...e,
    }
    if (e._id) {
      entry.id = e._id
    }
    entry.conflictRes = conflicts.filter(c => c.eventEntryID === e.eventEntryID)
    return entry
  })

  return (
    <>
      <Button
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '5px 5px 5px 10px' }}
        color='info'
        onClick={() => setEventEntriesToggle(!eventEntriesToggle)}
      >
        <span style={{ marginRight: '10px', marginBottom: '-3px' }}>Conflicting Events</span>
        {eventEntriesToggle ? <ExpandMore style={{ margin: '0px' }} /> : <NavigateNext style={{ margin: '0px' }} />}
      </Button>
      <div style={{
        opacity: eventEntriesToggle ? 1 : 0,
        transition: 'all 2000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
      }}>
        {(eventEntriesToggle) && (
          <div
            style={{
              borderRadius: "4px",
              background: "#fff",
              padding: "5px 10px",
              border: "1px solid #c4c4c4",
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            {entries.map(e => {
              return (
                <EventEntryItem
                  key={e.id}
                  event={e}
                  userType={userType}
                />
              )
            })}
          </div>
        )}
      </div>
    </>
  )
}

const EventEntryItem = ({ event, userType }) => {
  return (
    <div
      style={{
        margin: '5px',
        padding: '5px 7px 5px 10px',
        backgroundColor: '#fff',
        border: '1px solid #3695d7',
        color: '#393939',
        textTransform: 'none',
        borderRadius: '6px',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 400,
        lineHeight: 1.42857143,
        // cursor: 'pointer',
        width: '100%'
      }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <p style={{ margin: '0px 0px 0px 4px', fontWeight: 'bold', fontSize: '20px' }}>
            {event.name}
          </p>
          {event.conflictRes.length > 0 && (
            <div
              style={{ display: 'flex', margin: '0px 2px', cursor: 'pointer' }}
              title='Conflicts'
            >
              <span style={{ marginTop: '1px' }}>{event.conflictRes.length}</span>
              <Error style={{ margin: '0px', height: '20px', color: '#ff9800' }} />
            </div>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{
              display: 'flex',
              cursor: 'pointer',
              flex: 1,
              minWidth: '200px'
            }}
            title='Created By'
          >
            <Person style={{ margin: '0px', height: '20px', color: '#aaa' }} />
            <span>{event.createdByName}</span>
          </div>

          <div
            style={{
              display: 'flex',
              cursor: 'pointer',
              flex: 1,
              minWidth: '200px'
            }}
            title='Campus'
          >
            <Business style={{ margin: '0px', height: '20px', color: '#aaa' }} />
            <span>{event.campus}</span>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{
              display: 'flex',
              cursor: 'pointer',
              flex: 1,
              minWidth: '200px'
            }}
            title='Department'
          >
            <Group style={{ margin: '0px', height: '20px', color: '#aaa' }} />
            <span>{event.createdByDepartmentName}</span>
          </div>
          <div
            style={{
              display: 'flex',
              cursor: 'pointer',
              flex: 1,
              minWidth: '200px'
            }}
            title='Tag'
          >
            <LocalOffer style={{ margin: '0px', height: '20px', color: '#aaa' }} />
            <span>{event.tag}</span>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{
              display: 'flex',
              cursor: 'pointer',
              flex: 1,
              minWidth: '200px'
            }}
            title='When'

          >
            <Event style={{ margin: '0px', height: '20px', color: '#aaa' }} />
            <span>{event.when}</span>
          </div>

          <div
            style={{
              display: 'flex',
              cursor: 'pointer',
              flex: 1,
              minWidth: '200px'
            }}
            title='Event Type'
          >
            <Info style={{ margin: '0px', height: '20px', color: '#aaa' }} />
            <span>{event.bigEvent ? 'Special Event' : 'Regular Event'}</span>
          </div>
        </div>

      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          maxWidth: '90px',
          justifyContent: 'right',
        }}
      >
        <Button
          style={{ margin: '2px' }}
          justIcon
          color="primary"
          title='View'
          onClick={() => {
            let search = encryptData({
              returnLocation: window.location.href,
            })
            window.location.href = `/portal/events/${userType}/viewEntry/${event.eventEntryID}?${search}`
          }}
        >
          <Visibility />
        </Button>
      </div>
    </div>
  )
}

export default ConflictingEventEntriesDisplay