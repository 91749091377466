import React, { useState } from 'react'

import { Checkbox, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "components/CustomButtons/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { makeStyles } from "@material-ui/core/styles";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import update from 'immutability-helper'
import {
  Info,
  NavigateNext,
  ExpandMore,
  AccessTime,
  Block,
  EventBusy,
  PriorityHigh,
  AssignmentLate
} from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  ...styles,
  ...dashboardStyles,
  formSection: {
    marginBottom: "1rem",
  },
}));

const RoomReservationSelection = ({ buildings, rooms, reservations, addRemoveRoom, dropdownDefault = true }) => {

  const classes = useStyles()

  const [buildingToggles, setBuildingToggles] = useState(Array(buildings.length).fill(false))
  const roomTags = [...(new Set(rooms.map(r => r.tags).flat(1)))]
  const emptyRoomFilter = {
    tags: [],
    occupancy: '',
    bigEventConflict: false,
    regularConflict: false,
    timeConflict: false,
    search: ''
  }
  const [toggleRoomFilters, setToggleRoomFilters] = useState(true)
  const [roomFilter, setRoomFilter] = useState(emptyRoomFilter)
  const [roomToggle, setRoomToggle] = useState(dropdownDefault)

  const findIndexOfReservation = (forType, forID) => {
    return reservations.findIndex(r => forType === r.forType && forID === r.forID)
  }

  const filteredRooms = rooms.filter(r => {
    let bool = true
    if (roomFilter.bigEventConflict) {
      bool = bool && !r.bigEventConflict
    }

    if (roomFilter.regularConflict) {
      bool = bool && r.reservationConflicts === 0
    }

    if (roomFilter.timeConflict) {
      bool = bool && !r.openHourConflict
    }

    if (roomFilter.occupancy !== '') {
      bool = bool && parseInt(roomFilter.occupancy) <= r.occupancy
    }

    if (roomFilter.tags.length > 0) {
      bool = bool && roomFilter.tags.every(t => r.tags.includes(t))
    }

    bool = bool && r.search.includes(roomFilter.search.toLowerCase())

    return bool
  })

  const buildingsForDisplay = buildings.map(b => {
    return {
      building: b,
      floors: Array.from({ length: b.floors }, (_, i) => (i + 1).toString()).map(floor => {
        return {
          floor: floor,
          rooms: filteredRooms.filter(r => r.buildingID === b.id && r.floor === floor)
        }
      }),
      rooms: filteredRooms.filter(r => r.buildingID === b.id),
      selected: rooms.filter(r => r.buildingID === b.id && findIndexOfReservation('room', r.id) !== -1).length
    }
  })

  const updateRoomFilter = (updateData) => {
    setRoomFilter(prev => {
      return update(prev, updateData)
    })
  }

  return (
    <>
      <Button
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '5px 5px 5px 10px' }}
        color='info'
        onClick={() => setRoomToggle(!roomToggle)}
      >
        <span style={{ marginRight: '10px', marginBottom: '-3px' }}>Room Reservations</span>
        {roomToggle ? <ExpandMore style={{ margin: '0px' }} /> : <NavigateNext style={{ margin: '0px' }} />}
      </Button>
      <div style={{
        minHeight: roomToggle ? `${32 * (buildings.length + 1)}px` : '0px',
        maxHeight: roomToggle ? `${(115 * (buildings.length + 1)) + 22 * (buildings.map(i => i.floors).reduce((accumulator, currentValue) => { return accumulator + currentValue }, 0)) + rooms.length * 90 + 500}px` : '0px',
        opacity: roomToggle ? 1 : 0,
        transition: 'all 2000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
      }}>
        {(roomToggle) && (
          <div
            style={{
              borderRadius: "4px",
              background: "#eee",
              padding: "5px 10px",
              border: "1px solid #c4c4c4",
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <div>
              <Button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  padding: '5px 5px 5px 10px',
                  color: '#393939',
                  backgroundColor: '#fff',
                  border: '1px solid #3695d7',
                  margin: '5px 0px'
                }}
                onClick={() => setToggleRoomFilters(!toggleRoomFilters)}
              >
                <span style={{ marginRight: '10px', marginBottom: '-3px', fontSize: '16px', fontWeight: 'bold' }}>Filters</span>
                {toggleRoomFilters ? <ExpandMore style={{ margin: '0px' }} /> : <NavigateNext style={{ margin: '0px' }} />}
              </Button>
              <div
                style={{
                  borderRadius: "4px",
                  background: "#fff",
                  padding: toggleRoomFilters ? "5px" : '0px',
                  border: toggleRoomFilters ? "1px solid #c4c4c4" : '',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  minHeight: toggleRoomFilters ? `164px` : '0px',
                  maxHeight: toggleRoomFilters ? `500px` : '0px',
                  opacity: toggleRoomFilters ? 1 : 0,
                  transition: 'all 1000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
                  width: '100%',
                  margin: '5px 0px'
                }}
              >
                {(toggleRoomFilters) && (<>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                      style={{ flex: 1, margin: '10px 5px', minWidth: '200px' }}
                      label='Search'
                      fullWidth
                      variant='outlined'
                      value={roomFilter.search}
                      onChange={(e) => updateRoomFilter({ search: { $set: e.target.value } })}
                    />
                    <div style={{ margin: '10px 5px', flex: 1, minWidth: '200px' }}>
                      <Autocomplete
                        disablePortal
                        multiple
                        options={roomTags}
                        value={roomFilter.tags}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tags"
                            variant="outlined"
                          />
                        )}
                        fullWidth
                        onChange={(e, v) => updateRoomFilter({ tags: { $set: v } })}
                        autoSelect={true}
                        autoComplete={true}
                        autoHighlight={true}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                      style={{ flex: 1, margin: '10px 5px', minWidth: '200px' }}
                      label='Minimum Occupancy'
                      fullWidth
                      type="number"
                      variant='outlined'
                      value={roomFilter.occupancy}
                      onChange={(e) => {
                        let val = e.target.value
                        if (val !== '') {
                          val = parseInt(val)
                        }
                        updateRoomFilter({ occupancy: { $set: val } })
                      }
                      }
                    />
                    <div style={{ margin: '5px 5px 10px 5px', flex: 2 }}>
                      <div style={{ display: 'flex', alignItems: 'center', paddingTop: '11px' }}>
                        <div style={{ marginTop: '-11px', flex: 1 }}>
                          <div style={{ margin: '-6px 0px 0px 9px' }}>
                            <span style={{
                              position: 'relative',
                              margin: '0px',
                              padding: '0px 5px',
                              color: '#757575',
                              backgroundColor: '#fff',
                              zIndex: 2,
                              fontSize: '12px'
                            }}>
                              Filter Out
                            </span>
                          </div>
                          <div
                            style={{
                              position: 'relative',
                              zIndex: 1,
                              display: 'flex',
                              flexWrap: 'wrap',
                              borderRadius: "4px",
                              background: "#fff",
                              padding: "1px 12px",
                              border: "1px solid #c4c4c4",
                              alignItems: 'center',
                              marginTop: '-11px',
                              flexWrap: 'wrap',
                              marginRight: '5px'
                            }}
                          >

                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={{ paddingRight: '0px' }}
                                  checked={roomFilter.bigEventConflict}
                                  name="bigEventConflict"
                                  value="yes"
                                  onChange={() => updateRoomFilter({ bigEventConflict: { $set: !roomFilter.bigEventConflict } })}
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                  }}
                                />
                              }
                              style={{ color: '#797979' }}
                              label={<span style={{ display: 'flex', alignItems: 'center' }}>
                                <AssignmentLate style={{ margin: '0px', height: '20px', color: '#ff9800' }} />
                                Big Events
                              </span>}
                            />

                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={{ paddingRight: '0px' }}
                                  checked={roomFilter.regularConflict}
                                  name="bigEvent"
                                  value="yes"
                                  onChange={() => updateRoomFilter({ regularConflict: { $set: !roomFilter.regularConflict } })}
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                  }}
                                />
                              }
                              style={{ color: '#797979' }}
                              label={<span style={{ display: 'flex', alignItems: 'center' }}>
                                <EventBusy style={{ margin: '0px', height: '20px', color: '#ff9800' }} />
                                Regular Events
                              </span>}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={{ paddingRight: '0px' }}
                                  checked={roomFilter.timeConflict}
                                  name="bigEvent"
                                  value="yes"
                                  onChange={() => updateRoomFilter({ timeConflict: { $set: !roomFilter.timeConflict } })}
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                  }}
                                />
                              }
                              color='primary'
                              style={{ color: '#797979' }}
                              label={<span style={{ display: 'flex', alignItems: 'center' }}>
                                <AccessTime style={{ margin: '0px', height: '20px', color: '#ff9800' }} />
                                Open Hours
                              </span>}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>)}
              </div>
            </div>
            {buildingsForDisplay.map((b, index) => {
              return (
                <div key={b.building.id}>
                  {b.rooms.length > 0 && (<>
                    <Button
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '5px 5px 5px 10px',
                        color: '#393939',
                        backgroundColor: '#fff',
                        border: '1px solid #3695d7',
                        margin: '5px 0px'
                      }}
                      onClick={() => setBuildingToggles(prev => {
                        return update(prev, {
                          [index]: { $set: !buildingToggles[index] }
                        })
                      })}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '-3px', marginRight: '10px' }}>
                        <span style={{ marginRight: '5px', fontSize: '16px', fontWeight: 'bold' }}>{b.building.name}</span>
                        <span style={{ textTransform: 'none' }}>{b.selected} Selected</span>
                      </div>
                      {buildingToggles[index] ? <ExpandMore style={{ margin: '0px' }} /> : <NavigateNext style={{ margin: '0px' }} />}
                    </Button>
                    <div
                      style={{
                        borderRadius: "4px",
                        background: "#fff",
                        padding: buildingToggles[index] ? "5px" : '0px',
                        border: buildingToggles[index] ? "1px solid #c4c4c4" : '',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        maxHeight: buildingToggles[index] ? `${115 + 22 * b.building.floors + b.rooms.length * 90}px` : '0px',
                        minHeight: buildingToggles[index] ? `${15 + 22 + 77}px` : '0px',
                        opacity: buildingToggles[index] ? 1 : 0,
                        transition: 'all 1000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
                        width: '100%',
                        margin: '5px 0px'
                      }}
                    >
                      {(buildingToggles[index]) && (
                        <>

                          {b.floors.map((floor, index) => {
                            return (
                              <div key={floor.floor}>
                                {floor.rooms.length > 0 && (
                                  <div style={{ margin: index !== 0 ? '10px 5px 0px 5px' : '0px 5px' }}>
                                    <p style={{ fontSize: '12px', fontWeight: 'bold', margin: '0px', color: '#757575' }}>Floor {floor.floor}</p>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0px -5px' }}>
                                      {floor.rooms.map(room => {
                                        return (
                                          <RoomSelectButton
                                            key={room.id}
                                            room={room}
                                            addRemoveRoom={addRemoveRoom}
                                            roomNotSelected={findIndexOfReservation('room', room.id) === -1}
                                          />
                                        )
                                      })}
                                    </div>
                                  </div>
                                )}</div>
                            )
                          })}
                        </>)}
                    </div>
                  </>)}
                </div>
              )
            })}
          </div>
        )}
      </div>
      {(!roomToggle && reservations.length !== 0) && (
        <div
          style={{
            borderRadius: "4px",
            background: "#eee",
            padding: "5px",
            border: "1px solid #c4c4c4",
            alignItems: 'center',
            width: '100%',
            margin: '5px 0px'
          }}
        >
          <div
            style={{ display: 'flex', flexWrap: 'wrap' }}
          >
            {rooms.filter(r => findIndexOfReservation('room', r.id) !== -1).map(room => {
              return (
                <RoomSelectButton
                  key={room.id}
                  room={room}
                  addRemoveRoom={addRemoveRoom}
                  roomSelected={true}
                />
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

export const RoomSelectButton = ({ room, addRemoveRoom, roomNotSelected }) => {
  return (
    <div style={{
      margin: '0px 5px',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <div
        style={{ height: '10px', marginTop: '-5px' }}
      >
        <span style={{
          margin: '0px 10px',
          fontSize: '10px'
        }}>
          {roomNotSelected ? '' : 'Selected'}
        </span>
      </div>
      <Button
        style={{
          margin: '6px 0px 2px 0px',
          padding: '5px 7px 5px 10px',
          backgroundColor: roomNotSelected ? '#ddd' : '#fff',
          border: roomNotSelected ? '1px solid #bbb' : '1px solid #3695d7',
          color: '#393939',
          textTransform: 'none',
          borderRadius: '6px',
          display: 'flex',
          flex: 1
        }}
        onClick={() => addRemoveRoom(room)}
      >
        <div style={{ display: 'flex', flex: 1 }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            flex: 1,
            marginRight: '10px'
          }}>
            <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{room.name}</span>
            <span style={{ fontSize: '14px' }}>Room {room.number} - Occupancy {room.occupancy}</span>
            <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '220px' }}>
              {room.tags.map((tag, index) => {
                return (
                  <span
                    key={index}
                    style={{ paddingRight: index < room.tags.length - 1 ? '3px' : '0px' }}
                  >{tag}
                    {index < room.tags.length - 1 ? ', ' : ''}
                  </span>
                )
              })}
            </div>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-Between',
            minHeight: '55px'
          }}>
            {(room.bigEventConflict) ? <AssignmentLate style={{ margin: '0px', height: '15px', color: '#ff9800' }} /> : <div style={{ margin: '0px', height: '15px', color: '#f44336' }} />}
            {(room.reservationConflicts > 0) ? <EventBusy style={{ margin: '0px', height: '15px', color: '#ff9800' }} /> : <div style={{ margin: '0px', height: '15px', color: '#f44336' }} />}
            {(room.openHourConflict) ? <AccessTime style={{ margin: '0px', height: '15px', color: '#ff9800' }} /> : <div style={{ margin: '0px', height: '15px', color: '#f44336' }} />}
          </div>
        </div>
      </Button>
    </div>
  )
}

export default RoomReservationSelection