import React, { useState } from 'react'

import { Checkbox, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "components/CustomButtons/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { makeStyles } from "@material-ui/core/styles";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import update from 'immutability-helper'
import {
  Info,
  NavigateNext,
  ExpandMore,
  AccessTime,
  Block,
  EventBusy,
  PriorityHigh,
  AssignmentLate,
  Warning,
  Error
} from '@material-ui/icons';

import ReactTooltip from 'react-tooltip';

const ResourceReservationDisplay = ({ resourcesRaw, myReservationsRaw, reservationsConflictsRaw, bigEvent, reservationsEventEntryPreviews, dropdownDefault = true }) => {

  const reservationsConflicts = reservationsConflictsRaw.map(r => {
    let res = {
      ...r,
    }
    if (r._id) {
      res.id = r._id
    }
    res.eventEntry = reservationsEventEntryPreviews.find(p => p.eventEntryID === res.eventEntryID)
    return res
  })

  const reservations = myReservationsRaw.map(r => {
    let res = {
      ...r,
    }
    if (r._id) {
      res.id = r._id
    }
    return res
  })

  const resources = resourcesRaw.map(r => {
    let res = {
      ...r,
      reservation: reservations.find(i => i.forID === r._id && i.forType === 'resource'),
      inUse: 0,
      conflictingReservations: reservationsConflicts.filter(c => c.forType === 'resource' && c.forID === r._id)
    }
    if (r._id) {
      res.id = r._id
    }
    if (res.type === 'general') {
      res.conflictingReservations.forEach(c => res.inUse += c.quantity)
    }

    return res
  })

  const resourceTypes = [...(new Set(resources.map(r => r.type).flat(1)))]
  const [resourceTypeToggles, setResourceTypeToggles] = useState(Array(resourceTypes.length).fill(true))
  const [resourceToggle, setResourceToggle] = useState(dropdownDefault)

  const resourceForDisplay = resourceTypes.map(rType => {
    return {
      type: rType,
      resources: resources.filter(r => r.type === rType),
    }
  })


  return (
    <>
      <Button
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '5px 5px 5px 10px' }}
        color='info'
        onClick={() => setResourceToggle(!resourceToggle)}
      >
        <span style={{ marginRight: '10px', marginBottom: '-3px' }}>Resource Reservations</span>
        {resourceToggle ? <ExpandMore style={{ margin: '0px' }} /> : <NavigateNext style={{ margin: '0px' }} />}
      </Button>
      <div style={{
        opacity: resourceToggle ? 1 : 0,
        transition: 'all 2000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
      }}>
        {(resourceToggle) && (
          <div
            style={{
              borderRadius: "4px",
              background: "#fff",
              padding: "5px 10px",
              border: "1px solid #c4c4c4",
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >

            {resourceForDisplay.map((type, index) => {
              return (
                <div
                  key={index}
                >
                  {type.type.length > 0 && (
                    <>
                      <Button
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                          padding: '5px 5px 5px 10px',
                          color: '#393939',
                          backgroundColor: '#fff',
                          border: '1px solid #3695d7',
                          margin: '5px 0px'
                        }}
                        onClick={() => setResourceTypeToggles(prev => {
                          return update(prev, {
                            [index]: { $set: !resourceTypeToggles[index] }
                          })
                        })}
                      >
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '-3px', marginRight: '10px', flexWrap: 'wrap' }}>
                          <span style={{ marginRight: '5px', fontSize: '16px', fontWeight: 'bold' }}>{type.type}</span>
                        </div>
                        {resourceTypeToggles[index] ? <ExpandMore style={{ margin: '0px' }} /> : <NavigateNext style={{ margin: '0px' }} />}
                      </Button>
                      <div
                        style={{
                          borderRadius: "4px",
                          background: "#fff",
                          padding: resourceTypeToggles[index] ? "5px" : '0px',
                          border: resourceTypeToggles[index] ? "1px solid #c4c4c4" : '',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          opacity: resourceTypeToggles[index] ? 1 : 0,
                          transition: 'opacity 2000ms cubic-bezier(0.3, 0.36, 0.26, 0.92)',
                          width: '100%',
                          margin: '5px 0px'
                        }}
                      >
                        {(resourceTypeToggles[index]) && (
                          <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0px 5px 8px 5px' }}>
                            {type.resources.map((r, index) => {
                              return (
                                <DisplayResource
                                  key={r.id}
                                  resource={r}
                                />
                              )
                            })}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </>
  )
}

const DisplayResource = ({ resource }) => {

  const resourceData = resource.reservation
  const [selected, setSelected] = useState(false)

  return (
    <>
      {resource.type === 'general' && (
        <div
          style={{
            margin: '6px 0px 2px 0px',
            padding: '5px 7px 5px 10px',
            backgroundColor: '#fff',
            border: '1px solid #3695d7',
            color: '#393939',
            textTransform: 'none',
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'column',
            fontWeight: 400,
            lineHeight: 1.42857143,
            cursor: 'pointer',
            width: '100%',
            cursor: resource.conflictingReservations.length > 0 ? 'pointer' : ''
          }}
          onClick={() => setSelected(!selected)}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <span>{resource.name}</span>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '5px',
                flexWrap: 'wrap'
              }}
            >
              {(resource.type === 'general') && (
                <>
                  <div style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
                    <span style={{ marginTop: '1px' }}>Available: {resource.quantity - resource.inUse} / {resource.quantity}</span>
                    {(resourceData.quantity > resource.quantity - resource.inUse) && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Error
                          style={{ margin: '0px', height: '20px', color: '#ff9800', cursor: 'pointer' }}
                          data-tip
                          data-for={`WarningInfo-${resource.id}`}
                        />
                        <ReactTooltip
                          delayHide={50}
                          delayShow={500}
                          effect="solid"
                          id={`WarningInfo-${resource.id}`}
                        >
                          <span>
                            Allowed but will take < br />
                            from other event(s)
                          </span>
                        </ReactTooltip>
                      </div>
                    )}
                  </div>
                  <TextField
                    style={{ minWidth: '200px', marginLeft: '5px', backgroundColor: '#fff', borderRadius: '4px' }}
                    label='Quantity'
                    type="number"
                    variant='outlined'
                    value={resourceData.quantity}

                  />
                </>
              )}
            </div>
          </div>
          {(selected && resource.conflictingReservations.length > 0) && (
            <div>
              <div style={{ display: 'flex', margin: '0px' }}>
                {(resourceData.quantity > resource.quantity - resource.inUse) &&
                  (
                    <Error
                      style={{ margin: '0px', height: '20px', color: '#ff9800' }}
                    />
                  )}
                <span style={{ display: 'flex', marginTop: '1px' }}>
                  Event(s):
                </span>
              </div>
              {resource.conflictingReservations.map(res => {
                return (
                  <div
                    key={res.id}
                    style={{
                      textAlign: 'left',
                      border: "1px solid #c4c4c4",
                      borderRadius: '4px',
                      padding: '5px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'

                    }}>
                    <span>{res.eventEntry.name} - {res.eventEntry.createdByDepartmentName}</span>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '5px',
                        flexWrap: 'wrap'
                      }}
                    >
                      {(resource.type === 'general') && (
                        <div style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
                          <span style={{ marginTop: '1px' }}>Using: {res.quantity}</span>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default ResourceReservationDisplay